import React, { Fragment, useCallback, useMemo, useState } from "react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { DeleteMappingDialog } from "~/components/program/dialogs/DeleteMappingDialog";
import MappingSheet from "~/components/program/mapping/MappingSheet";
import { Button } from "~/components/ui/button";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import { useStore, NodeTypes, IMappingListItem } from "~/models/AlignEditor";

type MappingTableProps = {
  nodeId: number;
  nodeType: NodeTypes;
};

const MappingTable = observer(({ nodeId, nodeType }: MappingTableProps) => {
  const columns = useMemo<ColumnDef<IMappingListItem>[]>(
    () => [
      {
        id: "item_name",
        header: "Item Name",
        cell: ({ row }) => <div>{row.original.listitem_display_name}</div>,
      },
      {
        id: "mappings_list_name",
        header: "Mappings List Name",
        cell: ({ row }) => <div>{row.original.list_name}</div>,
      },
      {
        id: "delete",
        header: "",
        cell: ({ row }) => (
          <DeleteMappingDialog
            mapping={row.original}
            onDelete={deleteMapping}
          />
        ),
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { getProgram, deleteCurriculumNodeMapping } = useStore();

  const program = getProgram();

  const getMappings = useCallback(() => {
    return program ? program.getCurriculumNodeMappings(nodeType, nodeId) : [];
  }, [nodeType, nodeId, program]);

  const [data, setData] = useState<IMappingListItem[]>(getMappings());

  const updateMappingsDisplay = useCallback(() => {
    setData(getMappings());
  }, [setData, getMappings]);

  const deleteMapping = (mappingId: number) => {
    deleteCurriculumNodeMapping(nodeId, nodeType, mappingId);
    updateMappingsDisplay();
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="bg-neutral-20 text-neutral-100 font-semibold flex gap-2 items-center w-full p-2 border border-b-0 border-neutral-40">
        <span>{`${nodeType} Mappings`}</span>
        <MappingSheet
          nodeId={nodeId}
          nodeType={nodeType}
          updateMappingsDisplay={updateMappingsDisplay}
        >
          <Button variant="grey" size="xs" testid="program-edit-mappings">
            Edit Mappings
          </Button>
        </MappingSheet>
      </div>
      {data.length > 0 && (
        <Table className="table-fixed border border-neutral-40">
          <colgroup>
            <col />
            <col />
            <col style={{ width: "40px" }} />
          </colgroup>
          <TableHeader className="bg-neutral-20">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-neutral-20">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className="text-neutral-100 font-semibold h-auto py-2"
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <TableRow
                    key={row.id + "_original"}
                    className={classNames(
                      "hover:bg-gray-200",
                      "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                      {
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      },
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="py-2 border-x border-[#00000014]"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
});

export default MappingTable;

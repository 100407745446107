import React, { useState } from "react";
import { Button } from "~/components/ui/button";

interface IExpanderPanelProps {
  title: string;
  defaultExpanded: boolean;
  children: React.ReactNode;
}

const ExpanderPanel: React.FC<IExpanderPanelProps> = ({
  title,
  defaultExpanded,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Button
        variant="ghost"
        className={`flex flex-row w-full cursor-pointer items-center justify-start rounded-none ${
          isExpanded ? "text-[#002e40]" : "text-gray-500"
        }`}
        onClick={toggleExpanded}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            toggleExpanded();
          }
        }}
        type="button"
        aria-controls="expandID"
        aria-expanded={isExpanded}
      >
        <div className="text-xl !w-[20px] material-icons" aria-hidden="true">
          {isExpanded ? "remove" : "add"}
        </div>
        <h5 className="m-2.5">{title}</h5>
      </Button>
      {isExpanded && (
        <div className="w-full pl-7.5 mt-2" id="expandID">
          {children}
        </div>
      )}
    </>
  );
};

export default ExpanderPanel;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Form, Formik, useFormikContext } from "formik";
import { AlertCircle } from "lucide-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import * as Yup from "yup";

import { API_PATHS } from "~/api/ApiPaths";
import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import Button from "~/components/design-system/Button";
import ForgotPassword from "~/components/login/ForgotPassword";
import Navbar from "~/components/Navbar";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import useAxios from "~/hooks/useAxios";
import alignHero from "~/images/Align-G.png";
import { Environment, useStore as useRootStore } from "~/models/Root";

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  div.overflow-handle {
    display: flex;
    flex: 1;
    overflow: auto;
  }
`;

const SetDevLoginCredentials: React.FC = () => {
  const { setValues } = useFormikContext();

  return (
    <div style={{ paddingTop: "1rem" }}>
      <div>For demo/dev only, get credentials to login as:</div>
      <div className="flex gap-4 flex-wrap sm:flex-nowrap">
        <Button
          label="iDesign Admin"
          onClick={() => {
            setValues({
              email: "iDesignStudioAdmin@idesignedu.org",
              password: "idesignpassword",
            });
          }}
          type="button"
        />
        <Button
          label="iDesign Demo User"
          onClick={() => {
            setValues({
              email: "iDesignDemoUser@idesignedu.org",
              password: "idesignpassword",
            });
          }}
          type="button"
        />
        <Button
          label="UCLA Demo"
          onClick={() => {
            setValues({
              email: "UCLADemoUser@idesignedu.org",
              password: "idesignpassword",
            });
          }}
          type="button"
        />
        <Button
          label="UMGC Demo"
          onClick={() => {
            setValues({
              email: "UMGCDemoUser@idesignedu.org",
              password: "idesignpassword",
            });
          }}
          type="button"
        />
      </div>
    </div>
  );
};

/**
 * @description Entry point component of the main application which uses a form requesting credentials for access, there will be SSO in the future.
 * for development purposes we have a SetDevLoginCredentials component to help with the tedious entering of credentials many times.
 */

const Login: React.FC = observer(() => {
  const {
    setAuthToken,
    setAppUser,
    setLoggedIn,
    getLoggedIn,
    resetAppUser,
    getEnvironment,
  } = useRootStore();
  const [isLoggedIn] = useState(getLoggedIn());
  const navigate = useNavigate();
  const { data, error, payload, requestUrl, fetch } = useAxios({
    method: "POST",
    initialValue: null,
  });
  const [loginError, setLoginError] = useState<string | undefined>();
  const doItOnce = useRef(false);
  const initialLoad = useRef(true);
  const environmentVariable = getEnvironment();
  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {
    //If user navigates to login page and is logged in, we need to log them out.
    if (initialLoad.current && isLoggedIn) {
      setAuthToken("");
      resetAppUser();
      setLoggedIn(false);
      initialLoad.current = false;
    }
  }, [isLoggedIn, setAuthToken, resetAppUser, setLoggedIn]);

  const login = (email: string, password: string) => {
    //we need to call the API here, get the token if successful and return errors if not.
    doItOnce.current = true;
    payload.current = {
      email: email,
      password: password,
    };
    requestUrl.current = `${API_PATHS.LOGIN}`;
    fetch();
  };

  useEffect(() => {
    if (data && doItOnce.current) {
      doItOnce.current = false;
      if (data.issuccess) {
        // we will redirect and do extra stuff here.
        setAuthToken(data.entity.authToken);
        setAppUser({
          authtoken: data.entity.authToken,
          first_name: data.entity.first_name,
          last_name: data.entity.last_name,
          name: data.entity.name,
          user_type: data.entity.user_type,
          agency_id: data.entity.agency_id,
          agency_name: data.entity.agency_name.name,
          partner_id: data.entity.partner_id,
          partner_name: data.entity.partner.name,
          basic_permissions: data.entity.basic_permissions,
          user_permissions: data.entity.user_permissions,
          id: `${data.entity.id}`,
          email: data.entity.email,
          title: data.entity.title,
          created_at: data.entity.created_at,
          last_login: data.entity.last_login,
          password_expires_at: data.entity.password_expires_at,
          updated_at: data.entity.updated_at,
        });
        setLoggedIn(true);
        navigate("/dashboard", { replace: true });
      } else {
        setLoginError(data.message);
      }
    }
    if (error) {
      setLoginError(error.message);
    }
  }, [data, error, navigate, setAuthToken, setAppUser, setLoggedIn]);

  return (
    <AppWrapper>
      <Navbar />
      <div className="grid h-full place-items-center bg-gradient-to-b from-white to-gradient-blue">
        {showLogin ? (
          <div className="p-4">
            <Card>
              <CardHeader>
                <div className="flex justify-between items-center flex-wrap p-8 pb-0">
                  <CardTitle>Log in</CardTitle>
                  <img
                    alt="iDesign 'Align' Word Logo"
                    src={alignHero}
                    className="self-center max-h-14"
                  />
                </div>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col p-8 pt-0">
                  {loginError ? (
                    <Alert variant="error">
                      <AlertCircle className="h-4 w-4" />
                      <AlertTitle>Error</AlertTitle>
                      <AlertDescription>{loginError}</AlertDescription>
                    </Alert>
                  ) : null}
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .email("Invalid email address")
                        .max(100, "Invalid email address")
                        .required("Email Required"),
                      password: Yup.string().required("Password Required"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      setAuthToken("");
                      login(values.email, values.password);
                      setSubmitting(false);
                    }}
                  >
                    <Form className="min-w-[17rem] sm:min-w-[29rem]">
                      <AlignFormikTextInput
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="example@example.com"
                        materialIconName="mail_outline"
                        iconAlignment="left"
                      />
                      <AlignFormikTextInput
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="Password"
                      />
                      <Button
                        label="Log in"
                        type="submit"
                        colorScheme="blue"
                        width="full"
                        size="medium"
                      />
                      <div className="pt-6 flex justify-end">
                        <Button
                          label="Forgot your password?"
                          type="button"
                          colorScheme="link"
                          size="medium"
                          onClick={() => {
                            setShowLogin(false);
                          }}
                        />
                      </div>
                      {environmentVariable === Environment.LOCAL && (
                        <SetDevLoginCredentials />
                      )}
                    </Form>
                  </Formik>
                </div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <ForgotPassword setShowLogin={setShowLogin} />
        )}
      </div>
    </AppWrapper>
  );
});

export default Login;

import React, { useEffect, useState } from "react";

import { Checkbox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import { CompModel } from "~/components/Reports/ToteCourseDetails";
import {
  CustomizedLabel,
  renderActiveShape,
  showAllLabel,
} from "~/components/Reports/ToteSummaryVisuals";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";

export const getColor = (label: string | undefined) => {
  const COLORS = [
    `${colors.formButtonBlue}`,
    `${colors.lightGrey}`,
    `${colors.activeTabBlue}`,
  ];
  switch (label) {
    case "Assignments/Assessments":
      return COLORS[0];
    case "Blended Learning":
      return COLORS[1];
    case "Learning Activities":
      return COLORS[2];
  }
};

export const renderLegend = (props: any) => {
  const { payload } = props;
  const assignment = payload.filter(
    (item: any) => item.payload.category === "Assignments/Assessments",
  );
  const blended = payload.filter(
    (item: any) => item.payload.category === "Blended Learning",
  );
  const learning = payload.filter(
    (item: any) => item.payload.category === "Learning Activities",
  );
  return (
    <ul className="legend-list padding-0">
      <li key={`item-0`} className="flex items-center">
        <svg className="h-2.5 w-2.5">
          <rect className="h-2.5 w-2.5" fill={colors.formButtonBlue} />
        </svg>
        <span className="pl-1.5">
          Assignment/Assessments -{" "}
          {assignment.length > 0 ? assignment[0].payload.tot_percent : 0}%
        </span>
      </li>
      <li key={`item-1`} className="flex items-center">
        <svg className="h-2.5 w-2.5">
          <rect className="h-2.5 w-2.5" fill={colors.lightGrey} />
        </svg>
        <span className="pl-1.5">
          Blended Learning -{" "}
          {blended.length > 0 ? blended[0].payload.tot_percent : 0}%
        </span>
      </li>
      <li key={`item-2`} className="flex items-center">
        <svg className="h-2.5 w-2.5">
          <rect className="h-2.5 w-2.5" fill={colors.activeTabBlue} />
        </svg>
        <span className="pl-1.5">
          Learning Activities -{" "}
          {learning.length > 0 ? learning[0].payload.tot_percent : 0}%
        </span>
      </li>
    </ul>
  );
};

const ToteSummaryDetailsVisuals: React.FC<{ data: CompModel | null }> =
  observer(({ data }) => {
    const [index, setIndex] = useState(0);
    const [activeCategory, setActiveCategory] = useState("");
    const [barDataReady, setBarDataReady] = useState(false);
    const [barData, setBarData] = useState<any>([]);
    const [previousCategory, setPreviousCategory] = useState("");
    const [previousHover, setPreviousHover] = useState();
    const [previousIndex, setPreviousIndex] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const { ActivityLabel } = useOverrides();
    const activityLabel = ActivityLabel();
    const [isCheckboxFocused, setIsCheckboxFocused] = useState(false);

    const pieHover = (data: any, index: any) => {
      setPreviousCategory(activeCategory);
      setPreviousHover(data);
      setActiveCategory(data.category);
      setIndex(index);
      setShowAll(false);
    };

    useEffect(() => {
      if (firstLoad) {
        setFirstLoad(false);
        setActiveCategory("Assignments/Assessments");
        setPreviousCategory("Assignments/Assessments");
        setIndex(0);
      }
    }, [data, firstLoad]);

    useEffect(() => {
      if (activeCategory !== "") {
        const barData = data?.activitySummaries
          .filter((item) =>
            activeCategory === "ALL" ? true : item.category === activeCategory,
          )
          .map((item: any) => {
            const color = getColor(item.category);
            return {
              name: item.type,
              tot_minutes: item.tot_minutes,
              tot_minutes_display: item.tot_minutes_display,
              tot_percent: item.tot_percent,
              tot_hours: item.tot_minutes / 60,
              color: color,
            };
          });
        setBarData(barData);
        setBarDataReady(true);
      }
    }, [activeCategory, data]);

    const [showAll, setShowAll] = React.useState(false);
    const checkboxChange = (event: any) => {
      if (event.value) {
        setShowAll(event.value);
        setActiveCategory("ALL");
        setPreviousIndex(index);
        setIndex(-1);
      } else if (event.value === false && previousHover !== undefined) {
        setShowAll(event.value);
        setActiveCategory(previousCategory);
        pieHover(previousHover, index);
        setIndex(previousIndex);
      } else {
        setShowAll(event.value);
        setActiveCategory(previousCategory);
        setIndex(previousIndex);
      }
    };

    return (
      <div className="pl-1 pr-1">
        <h6 className="m-0 absolute">Category Breakdown</h6>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Legend
              content={renderLegend}
              verticalAlign="top"
              align="center"
              wrapperStyle={{ top: 0 }}
            />
            {data && (
              <Pie
                label={showAll ? showAllLabel : undefined}
                tabIndex={0}
                focusable
                paddingAngle={
                  data?.categorySummaries.filter((item) => item.tot_minutes > 0)
                    .length > 1
                    ? 5
                    : 0
                }
                activeIndex={index}
                activeShape={renderActiveShape}
                data={data?.categorySummaries.filter(
                  (item) => item.tot_minutes !== 0,
                )}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill={colors.formButtonBlue}
                dataKey="tot_percent"
                onMouseEnter={showAll ? undefined : pieHover}
                onFocus={pieHover}
              >
                {data?.categorySummaries
                  .filter((item) => item.tot_minutes > 0)
                  .map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getColor(entry.category)}
                    />
                  ))}
              </Pie>
            )}
          </PieChart>
        </ResponsiveContainer>
        {barDataReady && (
          <>
            <h6>
              {activityLabel} Type for{" "}
              {showAll ? "All Categories" : activeCategory}
            </h6>
            <Checkbox
              id="showAll"
              tabIndex={0}
              style={{
                boxShadow: isCheckboxFocused
                  ? "0px 0px 5px 0px rgba(0,0,0,0.80)"
                  : "0px 0px 5px 0px rgba(0,0,0,0.25)",
              }}
              className="ml-6 mt-0"
              value={showAll}
              onChange={checkboxChange}
              onFocus={() => setIsCheckboxFocused(true)}
              onBlur={() => setIsCheckboxFocused(false)}
            >
              <label
                htmlFor="showAll"
                className="ml-2 mt-0 shadow-none inline-block font-normal cursor-pointer"
              >
                Show All Activity Types (Expand Below)
              </label>
            </Checkbox>
            <ResponsiveContainer width={"100%"} height={430}>
              <BarChart
                width={600}
                height={380}
                data={barData}
                layout="vertical"
                margin={{ bottom: 20, left: 50, top: 20 }}
              >
                <XAxis type="number">
                  <Label value="Hours" position={"bottom"} />
                </XAxis>
                <YAxis
                  type="category"
                  dataKey="name"
                  tickLine={false}
                  interval={0}
                  fontSize={showAll ? 14 : 16}
                />
                <Bar dataKey="tot_hours">
                  <LabelList
                    content={<CustomizedLabel showAll={showAll} />}
                    position="right"
                  />
                  {barData.map((entry: any, index: any) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    );
  });

export default ToteSummaryDetailsVisuals;

import React, { Fragment, useMemo } from "react";

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { EditIcon } from "lucide-react";

import { DeleteListItem } from "~/components/lists/ListEditor/ListItemTable/DeleteListItem";
import { DraggableRow } from "~/components/lists/ListEditor/ListItemTable/DraggableRow";
import ListItemDialog from "~/components/lists/ListEditor/ListItemTable/ListItemDialog";
import {
  CheckboxCell,
  InputCell,
  MappingRestrictionsCell,
  RowDragHandleCell,
} from "~/components/lists/ListEditor/ListItemTable/ListItemTableCells";
import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { useListEditorContext } from "~/context/ListEditorProvider";
import { cn } from "~/lib/utils";
import { getTmpId, useStore } from "~/models/AlignEditor";
import { IListManagerListItem, ListItemEditable } from "~/models/ListManager";

type ListItemTableProps = {
  isEditing: boolean;
};

const ListItemTable = ({ isEditing }: ListItemTableProps) => {
  const { listItems, setListItems, setDeletedListItems, setIsListReordered } =
    useListEditorContext();

  const columns = useMemo<ColumnDef<IListManagerListItem>[]>(
    () => [
      {
        id: "drag-handle",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      },
      {
        id: "edit",
        cell: ({ row }) => (
          <div className="flex">
            <ListItemDialog
              item={row.original}
              onSubmit={(data) => handleEditItem(data, row.original)}
            >
              <button title="Edit Activity" aria-label="Edit Activity">
                <EditIcon size="16px" />
              </button>
            </ListItemDialog>
          </div>
        ),
      },
      {
        id: "code",
        accessorKey: "code",
        header: "Code",
        cell:
          isEditing && 1 < 0
            ? InputCell
            : ({ row }) => (
                <div className={cn({ "font-bold": row.original.is_heading })}>
                  {row.original.code}
                </div>
              ),
      },
      {
        id: "name",
        accessorKey: "name",
        header: "Name",
        cell:
          isEditing && 1 < 0
            ? InputCell
            : ({ row }) => (
                <div className={cn({ "font-bold": row.original.is_heading })}>
                  {row.original.name}
                </div>
              ),
      },
      {
        id: "display_name",
        accessorKey: "display_name",
        header: "Display Name",
        cell:
          isEditing && 1 < 0
            ? InputCell
            : ({ row }) => (
                <div className={cn({ "font-bold": row.original.is_heading })}>
                  {row.original.display_name}
                </div>
              ),
      },
      {
        id: "mapping_restrictions",
        accessorKey: "mapping_restrictions",
        header: "Mapping Restrictions",
        cell:
          isEditing && 1 < 0
            ? MappingRestrictionsCell
            : ({ row }) => {
                return (
                  <div>
                    {row.original.mapping_restrictions?.join(", ") ||
                      "Unrestricted"}
                  </div>
                );
              },
      },
      {
        id: "is_heading",
        accessorKey: "is_heading",
        header: "List Header",
        cell:
          isEditing && 1 < 0
            ? CheckboxCell
            : ({ row }) => {
                return (
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={`${row.original.id}-list-header-checkbox`}
                      checked={row.original.is_heading}
                      aria-disabled
                      disabled
                    />
                    <label
                      htmlFor={`${row.original.id}-list-header-checkbox`}
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {row.original.is_heading ? "Yes" : "No"}
                    </label>
                  </div>
                );
              },
      },
      {
        id: "is_mappable",
        accessorKey: "is_mappable",
        header: "Mappable",
        cell:
          isEditing && 1 < 0
            ? CheckboxCell
            : ({ row }) => {
                return (
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id={`${row.original.id}-mappable-checkbox`}
                      checked={row.original.is_mappable}
                      aria-disabled
                      disabled
                    />
                    <label
                      htmlFor={`${row.original.id}-mappable-checkbox`}
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {row.original.is_mappable ? "Yes" : "No"}
                    </label>
                  </div>
                );
              },
      },
      {
        id: "delete",
        cell: ({ row }) => (
          <DeleteListItem listItem={row.original} onDelete={handleDeleteItem} />
        ),
      },
    ],
    [isEditing], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { listManagerInstance } = useStore();

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => listItems?.map(({ id }) => id),
    [listItems],
  );

  const columnVisibility = {
    "drag-handle": isEditing,
    edit: isEditing,
    delete: isEditing,
  };

  const table = useReactTable({
    data: listItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id as unknown as string, //required because row indexes will change
    state: {
      columnVisibility,
    },
  });

  const handleEditItem = (
    listItemUpdates: ListItemEditable,
    listItemOriginal: IListManagerListItem,
  ) => {
    const listItem: IListManagerListItem = {
      ...listItemOriginal,
      ...listItemUpdates,
      isChanged: true,
    };
    setListItems((prev) =>
      prev.map((item) => (item.id === listItem.id ? listItem : item)),
    );
  };

  const handleAddItem = (listItem: ListItemEditable) => {
    const newListItem: IListManagerListItem = {
      id: getTmpId(),
      created_at: +new Date(),
      list_id: listManagerInstance().getCurrentSnapshot()[0].id,
      code: listItem.code,
      name: listItem.name,
      display_name: listItem.display_name,
      parent_id: 0,
      is_heading: listItem.is_heading,
      is_mappable: listItem.is_mappable,
      mapping_restrictions: listItem.mapping_restrictions,
      delete: false,
      isNewItem: true,
    };
    setListItems((prev) => [...prev, newListItem]);
  };

  const handleDeleteItem = (item: IListManagerListItem) => {
    setListItems((data) => data.filter((i) => i.id !== item.id));
    if (!item.isNewItem)
      setDeletedListItems((data) => [...data, { ...item, delete: true }]);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setListItems((data) => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);
        if (oldIndex !== newIndex) setIsListReordered(true);

        return arrayMove(data, oldIndex, newIndex); //this is just a splice util
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <div className="overflow-auto">
      {isEditing ? (
        <>
          <div className="bg-neutral-20 text-neutral-100 font-semibold flex gap-2 items-center w-full p-2 border border-b-0 border-neutral-40">
            <span>List Items</span>
            <ListItemDialog onSubmit={handleAddItem}>
              <Button variant="grey" size="xs" testid="lists-add-item">
                Add List Item
              </Button>
            </ListItemDialog>
          </div>
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            // onDragStart={handleDragStart}
            sensors={sensors}
          >
            <Table className="table-fixed border border-neutral-40 overflow-y-auto">
              <colgroup>
                <col style={{ width: "36px" }} />
                <col style={{ width: "40px" }} />
                <col style={{ width: "100px" }} />
                <col style={{ width: "350px" }} />
                <col style={{ minWidth: "350px" }} />
                <col style={{ width: "230px" }} />
                <col style={{ width: "100px" }} />
                <col style={{ width: "88px" }} />
                <col style={{ width: "40px" }} />
              </colgroup>
              <TableHeader className="bg-neutral-20">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow
                    key={headerGroup.id}
                    className="hover:bg-neutral-20"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          className="text-neutral-100 font-semibold h-auto py-2"
                          key={header.id}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                <SortableContext
                  items={dataIds}
                  strategy={verticalListSortingStrategy}
                >
                  {table.getRowModel().rows.map((row) => (
                    <DraggableRow key={row.id} row={row} />
                  ))}
                </SortableContext>
              </TableBody>
            </Table>
          </DndContext>
        </>
      ) : (
        <Table className="table-fixed border border-neutral-40 overflow-y-auto">
          <colgroup>
            <col style={{ width: "100px" }} />
            <col style={{ width: "350px" }} />
            <col style={{ minWidth: "350px" }} />
            <col style={{ width: "230px" }} />
            <col style={{ width: "100px" }} />
            <col style={{ width: "88px" }} />
          </colgroup>
          <TableHeader className="bg-neutral-20">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-neutral-20">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className="text-neutral-100 font-semibold h-auto py-2"
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <TableRow
                    key={row.id + "_original"}
                    className={classNames(
                      "hover:bg-gray-200",
                      "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                      {
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      },
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="py-2 border-x border-[#00000014]"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default ListItemTable;

import React, { Fragment, useMemo, useState } from "react";

import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { EditIcon, Minus, Plus } from "lucide-react";
import { observer } from "mobx-react-lite";

import CourseDialog from "~/components/program/dialogs/CourseDialog";
import { DeleteCurriculumNode } from "~/components/program/dialogs/DeleteNodeDialog";
import MappingTable from "~/components/program/tables/MappingTable";
import ModuleTable from "~/components/program/tables/ModuleTable";
import { Button } from "~/components/ui/button";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import {
  useStore,
  ICourse,
  NodeTypes,
  CourseEditable,
} from "~/models/AlignEditor";

const CourseTable = observer(() => {
  const columns = useMemo<ColumnDef<ICourse>[]>(
    () => [
      {
        id: "expand",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <button
              {...{
                onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                style: { cursor: "pointer" },
                "data-testid": "course-expand",
              }}
            >
              {row.getIsExpanded() ? (
                <Minus size="16px" />
              ) : (
                <Plus size="16px" />
              )}
            </button>
          </div>
        ),
      },
      {
        id: "edit",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <CourseDialog
              course={row.original}
              onSubmit={(data) => handleEditCourse(data, row.original)}
            >
              <button
                data-testid="program-edit-course"
                title="Edit Course"
                aria-label="Edit Course"
              >
                <EditIcon size="16px" />
              </button>
            </CourseDialog>
          </div>
        ),
      },
      {
        id: "code",
        header: "Code",
        cell: ({ row }) => <div>{row.original.code}</div>,
      },
      {
        id: "name",
        header: "Name",
        cell: ({ row }) => <div>{row.original.name}</div>,
      },
      {
        id: "display_name",
        header: "Display Name",
        cell: ({ row }) => <div>{row.original.display_name}</div>,
      },
      {
        id: "level",
        header: () => <div className="text-center">Level</div>,
        cell: ({ row }) => (
          <div className="text-center">{row.original.level}</div>
        ),
      },
      {
        id: "credits",
        header: () => <div className="text-end">Credits</div>,
        cell: ({ row }) => (
          <div className="text-end">
            {row.original.credits ? row.original.credits : ""}
          </div>
        ),
      },
      {
        id: "delete",
        header: "",
        cell: ({ row }) => (
          <DeleteCurriculumNode
            id={row.original.id}
            type={NodeTypes.Course}
            display_name={
              row.original.display_name ??
              row.original.name ??
              row.original.code ??
              "Course"
            }
            onDelete={handleDeleteCourse}
          />
        ),
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {
    getProgram,
    getProgramCourses,
    saveCourseChanges,
    deleteCurriculumNode,
  } = useStore();

  const [data, setData] = useState<ICourse[]>(getProgramCourses());

  const handleAddCourse = (course: CourseEditable) => {
    getProgram()?.addCourse(course);
    setData(getProgramCourses());
  };

  const handleEditCourse = (
    courseUpdates: CourseEditable,
    courseOriginal: ICourse,
  ) => {
    const course = { ...courseOriginal, ...courseUpdates };
    setData(saveCourseChanges(course));
  };

  const handleDeleteCourse = (courseId: number) => {
    deleteCurriculumNode(courseId, NodeTypes.Course);
    setData(getProgramCourses());
  };

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="bg-neutral-20 text-neutral-100 font-semibold flex gap-2 items-center w-full p-2 border border-b-0 border-neutral-40">
        <span>Courses</span>
        <CourseDialog onSubmit={handleAddCourse}>
          <Button testid="program-add-course" variant="grey" size="xs">
            Add Course
          </Button>
        </CourseDialog>
      </div>
      {data.length > 0 && (
        <Table className="table-fixed border border-neutral-40">
          <colgroup>
            <col style={{ width: "40px" }} />
            <col style={{ width: "40px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "33%" }} />
            <col style={{ width: "66%" }} />
            <col style={{ width: "100px" }} />
            <col style={{ width: "100px" }} />
            <col style={{ width: "40px" }} />
          </colgroup>
          <TableHeader className="bg-neutral-20">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-neutral-20">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className="text-neutral-100 font-semibold h-auto py-2"
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <TableRow
                    key={row.id + "_original"}
                    className={classNames(
                      "hover:bg-gray-200",
                      "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                      {
                        "border-b-0": row.getIsExpanded,
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      },
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="py-2 border-x border-[#00000014]"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.getIsExpanded() ? (
                    <tr
                      key={row.id + "_expanded"}
                      className={classNames("border-b", {
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      })}
                    >
                      <TableCell
                        colSpan={columns.length}
                        className="pl-[40px] pr-0 pt-0"
                      >
                        <MappingTable
                          nodeId={row.original.id}
                          nodeType={NodeTypes.Course}
                        />
                        <ModuleTable courseId={row.original.id} />
                      </TableCell>
                    </tr>
                  ) : null}
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
});

export default CourseTable;

import React, { useEffect, useState } from "react";

import { ProgressBar } from "@progress/kendo-react-progressbars";
import { Dialog } from "@reach/dialog";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import * as ReportModels from "~/components/Reports/ReportModels";
import ToteCourseDetails from "~/components/Reports/ToteCourseDetails";
import * as ToteModels from "~/components/Reports/ToteModels";
import ToteSummaryVisuals from "~/components/Reports/ToteSummaryVisuals";
import { Button } from "~/components/ui/button";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";

interface ICourseTot {
  code: string;
  tot_percent: number;
  tot_minutes: number;
  tot_minutes_display: string;
  course: ReportModels.Course;
}

function getCourseTotals(programData: ReportModels.ProgramData): ICourseTot[] {
  const courseTotals: ICourseTot[] = [];

  if (programData === null) return courseTotals;

  for (let i = 0; i < (programData?.courses?.length | 0); i++) {
    const courseMinutes = ReportModels.getTotalTotMinutes(
      programData.courses[i],
    );
    const courseMinutesDisplay = ReportModels.formatMinutes(courseMinutes);
    const totPercent = Math.round((courseMinutes / 9000) * 100); //this target time will have to be passed in down the road

    const courseTot: ICourseTot = {
      code: programData.courses[i].code,
      tot_percent: totPercent,
      tot_minutes: courseMinutes,
      tot_minutes_display: courseMinutesDisplay,
      course: programData.courses[i],
    };

    courseTotals.push(courseTot);
  }

  return courseTotals;
}

const CompStyles = styled.div`
  .close-button {
    cursor: pointer;
    font-weight: normal;
    font-size: 2rem;
    color: ${colors.inactiveGrey};
  }

  .k-progressbar .k-selected {
    background-color: #002e40;
  }
`;

const TableStyles = styled.div`
  table tbody tr td:first-child,
  table tbody tr td:last-child {
    min-width: 80px;
    width: 20%;
  }
`;

const SplitStyles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const DialogStyle = styled(Dialog)`
  min-width: 500px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  .dialog-button-actions {
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export class TotModel {
  courseTots: ICourseTot[] = [];
  activityTypesSummary: ToteModels.ITotSummary[] = [];
  categorySummary: ToteModels.ITotSummary[] = [];
}

const ToteSummary: React.FC<ReportModels.IReportsProps> = observer(
  ({ programData }) => {
    const [Model, setTotModel] = React.useState<TotModel | null>(null);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [targetCourse, setTargetCourse] =
      React.useState<ReportModels.Course | null>(null);
    const { CourseLabel } = useOverrides();
    const courseLabel = CourseLabel();
    const [categoryFound, setCategoryFound] = useState(false);
    const [activityFound, setActivityFound] = useState(false);

    useEffect(() => {
      if (programData && Model === null) {
        const model = new TotModel();

        const activities =
          programData?.courses?.flatMap(
            (course) => course.modules?.flatMap((x) => x.activities),
          ) || [];

        model.courseTots = getCourseTotals(programData);
        model.activityTypesSummary =
          ToteModels.getActivityTypesSummary(activities);
        model.categorySummary = ToteModels.getCategorySummaries(
          model.activityTypesSummary,
        );

        setTotModel(model);
      }
    }, [programData, Model]);

    useEffect(() => {
      if (targetCourse !== null) setModalVisible(true);
      else setModalVisible(false);
    }, [targetCourse]);

    useEffect(() => {
      const categories = Model?.categorySummary;
      categories?.forEach((category) => {
        if (category.tot_minutes !== 0) {
          setCategoryFound(true);
        }
      });
    }, [Model?.categorySummary]);

    useEffect(() => {
      const activityByType = Model?.activityTypesSummary;
      activityByType?.forEach((activity) => {
        if (activity.tot_minutes !== 0) {
          setActivityFound(true);
        }
      });
    }, [Model?.activityTypesSummary]);

    return (
      <CompStyles>
        <div className="flex-row p-2">
          <div>
            <TableStyles>
              <table className="tbl">
                <thead>
                  <tr>
                    <th>{courseLabel}</th>
                    <th>Estimated Time on Task</th>
                    <th className="center">Hours/Minutes</th>
                  </tr>
                </thead>
                <tbody>
                  {Model?.courseTots?.map((course, idx) => (
                    <tr key={idx}>
                      <td>
                        <Button
                          className="text-align-blue justify-left align-left text-left hover:rounded-md"
                          variant="ghost"
                          onClick={() => setTargetCourse(course.course)}
                          style={{
                            lineHeight: 1,
                            height: "fit-content",
                          }}
                          aria-haspopup="dialog"
                        >
                          {course.code}
                        </Button>
                      </td>
                      <td>
                        <ProgressBar
                          tabIndex={-1}
                          value={course.tot_minutes}
                          max={Math.max(
                            ...Model.courseTots.map((o) => o.tot_minutes),
                          )}
                          labelVisible={false}
                        />
                      </td>
                      <td className="center">{course.tot_minutes_display}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableStyles>
          </div>
          {(categoryFound || activityFound) && (
            <ToteSummaryVisuals data={Model} />
          )}
        </div>

        {targetCourse && (
          <DialogStyle
            aria-label={`${courseLabel} Details`}
            title={targetCourse.display_name}
            isOpen={modalVisible}
            onDismiss={() => setTargetCourse(null)}
            style={{ minWidth: "85vw" }}
          >
            <div className="dialog-content-wrap">
              <h5 className="dialog-header">
                <SplitStyles>
                  <div>{targetCourse.display_name}</div>
                  <Button
                    variant="outline"
                    onClick={() => setTargetCourse(null)}
                  >
                    <span className="material-icons ">close</span>
                  </Button>
                </SplitStyles>
              </h5>
              <div className="dialog-content">
                <ToteCourseDetails
                  key={targetCourse.code}
                  course={targetCourse}
                />
              </div>
            </div>
          </DialogStyle>
        )}
      </CompStyles>
    );
  },
);

export default ToteSummary;

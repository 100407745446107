import React from "react";

import styled from "styled-components";

import * as ReportModels from "~/components/Reports/ReportModels";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";

type CourseActivityTypeDetailProps = {
  course: ReportModels.Course;
  list_id: number;
  activity_type?: string;
};

interface IActivityRow {
  id: number;
  display_name: string;
  is_assessment: boolean;
  lo_count: number;
  modality: string;
  grading_stakes: string;
  tot_minutes: number;
  tot_minutes_display: string;
}

class CompModel {
  constructor(
    course: ReportModels.Course,
    list_id: number,
    activityType?: string,
  ) {
    const activity_type = activityType ?? "Other";
    this.activity_type = activity_type;
    this.title = `ACTIVITY: ${activity_type}`.toUpperCase();
    const activityTypePlural =
      this.activity_type.toLowerCase() === "quiz"
        ? "Quizzes"
        : `${activity_type}s`.toUpperCase();
    this.activity_type_plural =
      activityTypePlural.charAt(0) + activityTypePlural.slice(1).toLowerCase();
    this.course_tot_minutes = ReportModels.getTotalTotMinutes(course);
    this.course_los = ReportModels.getTreeMappingsByListId(
      course,
      list_id,
    ).length;

    const targetActivities =
      course.modules?.flatMap(
        (module) =>
          module?.activities?.filter(
            (activity) => activity.activity_type === activity_type,
          ),
      ) || [];
    this.activity_type_tot_minutes = targetActivities.reduce(
      (acc, activity) => acc + activity.tot_minutes,
      0,
    );
    this.activity_type_tot_minutes_display = ReportModels.formatMinutes(
      this.activity_type_tot_minutes,
    );
    this.activity_type_tot_percent = `${Math.round(
      (this.activity_type_tot_minutes / this.course_tot_minutes) * 100,
    ).toFixed(1)}%`;
    this.activity_type_los_count = targetActivities.reduce(
      (acc, activity) =>
        acc + activity.mappings.filter((x) => x.list_id === list_id).length,
      0,
    );
    this.activity_type_lo_percent = `${Math.round(
      (this.activity_type_los_count / this.course_los) * 100,
    ).toFixed(1)}%`;
    this.average_activity_time = Math.round(
      targetActivities.reduce(
        (acc, activity) => acc + activity.tot_minutes,
        0,
      ) / targetActivities.length,
    );
    this.average_activity_time_display = ReportModels.formatMinutes(
      this.average_activity_time,
    );
    this.activity_count = targetActivities.length;

    this.activity_rows = targetActivities.map((activity) => {
      return {
        id: activity.id,
        display_name: activity.display_name,
        is_assessment: activity.is_assessment,
        lo_count:
          activity.mappings?.filter((x) => x.list_id === list_id)?.length || 0,
        modality: activity.modality,
        grading_stakes: activity.grading_stakes,
        tot_minutes: activity.tot_minutes,
        tot_minutes_display: ReportModels.formatMinutes(activity.tot_minutes),
      };
    });
  }

  activity_type = "";
  title = "ACTIVITY: N/A";
  activity_type_plural = "N/A";
  course_tot_minutes = 0;
  course_los = 0;
  activity_type_tot_minutes = 0;
  activity_type_tot_minutes_display = "N/A";
  activity_type_tot_percent = "0.0%";
  activity_type_los_count = 0;
  activity_type_lo_percent = "0.0%";
  average_activity_time = 0;
  average_activity_time_display = "0m";
  activity_count = 0;

  activity_rows: IActivityRow[] = [];
}

const CourseActivityTypeDetail = ({
  course,
  list_id,
  activity_type,
}: CourseActivityTypeDetailProps) => {
  const Model = new CompModel(course, list_id, activity_type);
  const { CourseLabel } = useOverrides();
  const courseLabel = CourseLabel();

  return (
    <CompStyles>
      <h5>{Model.title}</h5>
      <p>
        {Model.activity_type_plural} make up {Model.activity_type_tot_percent}{" "}
        of the {courseLabel?.toLowerCase()} by time and cover{" "}
        {Model.activity_type_los_count} of the LOs in the{" "}
        {courseLabel?.toLowerCase()}.
      </p>
      <p>
        There are {Model.activity_count} {Model.activity_type_plural} with an
        average time of {Model.average_activity_time_display} per{" "}
        {Model.activity_type}
      </p>
      <p>
        The total time allotted for {Model.activity_type_plural} is{" "}
        {Model.activity_type_tot_minutes_display}
      </p>
      <table className="tbl striped">
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Name</th>
            <th>Assessment</th>
            <th># of LOs</th>
            <th>Modality</th>
            <th>Grading Stakes</th>
            <th>Time allotted</th>
          </tr>
        </thead>
        <tbody>
          {Model.activity_rows.map((row, index) => (
            <tr key={index}>
              <td>{row.display_name}</td>
              <td>{row.is_assessment ? "Yes" : "No"}</td>
              <td>{row.lo_count}</td>
              <td>{row.modality}</td>
              <td>{row.grading_stakes}</td>
              <td>{row.tot_minutes_display}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </CompStyles>
  );
};

export default CourseActivityTypeDetail;

const CompStyles = styled.div`
  width: 100%;

  table {
    width: 100%;
    font-size: 14px;
  }

  table,
  table tr th,
  table tr td {
    border: 1px solid ${colors.loginGrey};
    padding: 5px;
  }

  table thead tr th:first-child,
  table tbody tr td:first-child {
    text-align: left;
  }
  table thead tr th:not(:first-child),
  table tbody tr td:not(:first-child) {
    text-align: center;
  }

  table td.center {
    text-align: center;
    vertical-align: middle;
  }

  table tr.category td {
    font-weight: bold;
  }

  table tr.category td:first-child {
    padding-left: 10px;
  }

  .k-progressbar .k-selected {
    background-color: ${colors.formButtonBlue};
  }

  h3 {
    font-size: 1.35rem;
    font-weight: normal;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .mt-3 {
    margin-top: 3rem;
  }

  p {
    margin-bottom: 0;
  }
`;

import React, { Fragment, useMemo, useState } from "react";

import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { EditIcon, Minus, Plus } from "lucide-react";
import { observer } from "mobx-react-lite";

import ProgramDialog from "~/components/program/dialogs/ProgramDialog";
import CourseTable from "~/components/program/tables/CourseTable";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import {
  useStore,
  IProgramTreeNode,
  ProgramEditable,
} from "~/models/AlignEditor";

const ProgramTable = observer(() => {
  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  const columns = useMemo<ColumnDef<IProgramTreeNode>[]>(
    () => [
      {
        id: "expand",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <button
              {...{
                onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                style: { cursor: "pointer" },
                "data-testid": "program-expand", // First program found will be tested upon regardless.
              }}
            >
              {row.getIsExpanded() ? (
                <Minus size="16px" />
              ) : (
                <Plus size="16px" />
              )}
            </button>
          </div>
        ),
      },
      {
        id: "edit",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <ProgramDialog
              program={row.original}
              onSubmit={(data) => handleEditProgram(data, row.original)}
            >
              <button title="Edit Program" aria-label="Edit Program">
                <EditIcon size="16px" />
              </button>
            </ProgramDialog>
          </div>
        ),
      },
      {
        id: "code",
        header: "Code",
        cell: ({ row }) => <div>{row.original.code}</div>,
      },
      {
        id: "name",
        header: "Name",
        cell: ({ row }) => <div>{row.original.name}</div>,
      },
      {
        id: "display_name",
        header: "Display Name",
        cell: ({ row }) => <div>{row.original.display_name}</div>,
      },
      {
        id: "created_at",
        header: "Created At",
        cell: ({ row }) => {
          const dateToFormat = new Date(row.original.created_at);
          return (
            <div>
              {dateToFormat.toLocaleDateString("en-US", dateOptions)}{" "}
              {dateToFormat.toLocaleTimeString("en-US")}
            </div>
          );
        },
      },
      {
        id: "updated_at",
        header: "Updated At",
        cell: ({ row }) => {
          const dateToFormat = new Date(row.original.updated_at);
          return (
            <div>
              {dateToFormat.toLocaleDateString("en-US", dateOptions)}{" "}
              {dateToFormat.toLocaleTimeString("en-US")}
            </div>
          );
        },
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { getCurrentProgram, saveProgram } = useStore();

  const [data, setData] = useState<IProgramTreeNode[]>(getCurrentProgram());

  const handleEditProgram = (
    programUpdates: ProgramEditable,
    programOriginal: IProgramTreeNode,
  ) => {
    const program = { ...programOriginal, ...programUpdates };
    const newProgramData = saveProgram(program);
    setData(newProgramData);
  };

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table className="table-fixed border rounded-[6px] border-neutral-40">
      <colgroup>
        <col style={{ width: "40px" }} />
        <col style={{ width: "40px" }} />
        <col style={{ width: "100px" }} />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <TableHeader className="bg-neutral-20">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} className="hover:bg-neutral-20">
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  className="text-neutral-100 font-bold"
                  key={header.id}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow
                key={row.id + "_original"}
                className={classNames(
                  "hover:bg-gray-200",
                  "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                  {
                    "border-b-0": row.getIsExpanded,
                    "bg-muted": row.index % 2 !== 0,
                    "bg-white": row.index % 2 === 0,
                  },
                )}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="py-2 border-x border-[#00000014]"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
              {row.getIsExpanded() ? (
                <tr
                  key={row.id + "_expanded"}
                  className={classNames("border-b", {
                    "bg-muted": row.index % 2 !== 0,
                    "bg-white": row.index % 2 === 0,
                  })}
                >
                  <TableCell
                    colSpan={columns.length}
                    className="pl-[40px] pr-0 pt-0"
                  >
                    <CourseTable />
                  </TableCell>
                </tr>
              ) : null}
            </Fragment>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
});

export default ProgramTable;

import React, { useEffect, useRef, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { API_PATHS, ApiUrls, IData } from "~/api/ApiPaths";
import AlignCurriculumDataGrid from "~/components/AlignCurriculumDataGrid";
import AlignLoadingIndicator from "~/components/AlignLoadingIndicator";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

const DataGridRouteStyles = styled.div`
  display: flex;
  flex: 1;
  .drawer-wrap {
    flex: 1;
  }
  .loading-wrap {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const DataGridEditorRoute: React.FC = observer((props) => {
  const queryClient = useQueryClient();

  const apiFetched = useRef(false);
  const {
    initProgramTreeNode,
    isStoreReady,
    resetDrawerLists,
    resetDrawerSelectedListItems,
    resetStoreIsReady,
  } = useStore();
  const { getAuthToken, selectedProgramId, selectedPartnerId } = useRootStore();
  const { isEditingMappings, setIsEditingMappings } = useProgramEditorContext();
  const selectedProgramIdRef = useRef(selectedProgramId);
  const { data, error, requestUrl, fetch, authToken, resetFetchedData } =
    useAxios({ method: "GET", initialValue: null });
  const [loading, setLoading] = useState(false);
  const { ProgramLabel, PartnerLabel } = useOverrides();
  const programLabel = ProgramLabel();
  const partnerLabel = PartnerLabel();
  const pageLoaded = useRef(false);

  //this reloads the page when you navigate back, which solves problem of partner/program being changed and not reloading the page
  //but this causes a bit of awkward stuttering when navigating back, phase between loading and old data being displayed
  useEffect(() => {
    if (!pageLoaded.current) {
      pageLoaded.current = true;
      resetFetchedData();
      resetStoreIsReady();
    }
  }, [resetFetchedData, resetStoreIsReady]);

  useEffect(() => {
    if (!apiFetched.current && selectedProgramId !== 0) {
      apiFetched.current = true;
      selectedProgramIdRef.current = selectedProgramId;
      //This path is hard coded for now, but will be dynamic in the future.
      requestUrl.current = `${API_PATHS.PROGRAM_DETAILS}/${selectedProgramId}`;
      authToken.current = getAuthToken();
      setLoading(true);
      fetch();
    }
  }, [
    requestUrl,
    authToken,
    fetch,
    getAuthToken,
    apiFetched,
    selectedProgramId,
    setLoading,
  ]);

  useEffect(() => {
    const Data = data as IData;
    if (data && data.issuccess && apiFetched.current === true) {
      setLoading(false);
      initProgramTreeNode([Data.entity]);
    }
  }, [data, initProgramTreeNode, setLoading]);

  useEffect(() => {
    if (selectedProgramId !== selectedProgramIdRef.current) {
      apiFetched.current = false;
      resetFetchedData();
      resetStoreIsReady();
      selectedProgramIdRef.current = selectedProgramId;
    }
  }, [selectedProgramId, resetFetchedData, resetStoreIsReady]);

  const onOpenChange = (open: boolean) => {
    console.log("open", open);
    console.log("isEditingMappings", isEditingMappings);
    if (!open) {
      setIsEditingMappings(false);
      resetDrawerLists();
      resetDrawerSelectedListItems();
    }
  };

  return (
    <DataGridRouteStyles>
      <title>Programs - Align App</title>
      {(selectedProgramId === 0 || selectedPartnerId === 0) && (
        <p style={{ margin: "1rem" }}>
          Please select a {partnerLabel} and {programLabel} to edit.
        </p>
      )}
      {isStoreReady && selectedProgramId !== 0 && selectedPartnerId !== 0 && (
        <AlignCurriculumDataGrid
          reload={() => {
            apiFetched.current = false;
            resetFetchedData();
            resetStoreIsReady();
            queryClient.invalidateQueries({
              queryKey: [ApiUrls.mapping.getAIListItemSuggestionsForActivity],
            });
          }}
        />
      )}
      {loading && !isStoreReady && error === "" && (
        <div className="loading-wrap">
          <AlignLoadingIndicator />
        </div>
      )}
      {error !== "" && <div>{String(error)}</div>}
    </DataGridRouteStyles>
  );
});

export default DataGridEditorRoute;

import React, { createContext, useContext, useState } from "react";

import { NodeTypes } from "~/models/AlignEditor";

type ProgramEditorContextType = {
  partnerId: number;
  setPartnerId: React.Dispatch<React.SetStateAction<number>>;
  nodeId: number;
  setNodeId: React.Dispatch<React.SetStateAction<number>>;
  nodeType?: NodeTypes | string;
  setNodeType?: React.Dispatch<React.SetStateAction<NodeTypes | string>>;
  isEditingMappings: boolean;
  setIsEditingMappings: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProgramEditorContext = createContext<ProgramEditorContextType>({
  partnerId: -1,
  setPartnerId: () => undefined,
  nodeId: -1,
  setNodeId: () => undefined,
  nodeType: "",
  setNodeType: () => undefined,
  isEditingMappings: false,
  setIsEditingMappings: () => undefined,
});

type ProgramEditorProviderProps = {
  children: React.ReactNode;
};

const ProgramEditorProvider = ({ children }: ProgramEditorProviderProps) => {
  const [partnerId, setPartnerId] = useState<number>(-1);
  const [nodeId, setNodeId] = useState<number>(-1);
  const [nodeType, setNodeType] = useState<NodeTypes | string>("");
  const [isEditingMappings, setIsEditingMappings] = useState<boolean>(false);

  return (
    <ProgramEditorContext.Provider
      value={{
        partnerId,
        setPartnerId,
        nodeId,
        setNodeId,
        nodeType,
        setNodeType,
        isEditingMappings,
        setIsEditingMappings,
      }}
    >
      {children}
    </ProgramEditorContext.Provider>
  );
};

export const useProgramEditorContext = () => {
  const context = useContext(ProgramEditorContext);

  if (!context) {
    throw new Error(
      "useProgramEditorContext must be used within a ProgramEditorProvider",
    );
  }

  return context;
};

export default ProgramEditorProvider;

import React from "react";

import styled from "styled-components";

import CourseActivityTypeDetail from "~/components/Reports/CourseActivityTypeDetail";
import * as ReportModels from "~/components/Reports/ReportModels";
import { Course, List } from "~/components/Reports/ReportModels";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";

const CompStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  table {
    width: 100%;
  }

  table.sticky-header thead {
    position: sticky;
    top: 0;
  }

  table.sticky-header tbody {
    overflow-y: auto;
    height: 200px;
  }

  table,
  table tr th,
  table tr td {
    border: 1px solid ${colors.loginGrey};
  }

  table th {
    vertical-align: bottom;
  }

  table th.course-heading {
    text-align: center;
    vertical-align: bottom;
    padding: 10px;
  }

  table tr.list-name td {
    background-color: ${colors.headerLighBlue};
    font-size: 1.15rem;
  }

  table td.indent-1x {
    padding-left: 15px;
    font-weight: bold;
  }

  table td.indent-2x {
    padding-left: 25px;
  }

  table td.data-point {
    text-align: center;
    vertical-align: middle;
    min-width: 50px;
    min-height: 50px;
    padding: 10px;
    font-size: 1.15rem;
  }

  table tr.hidden {
    display: none;
  }
`;

class ActivitySummary {
  activity_type = "";
  frequency = 0;
  avg_time_allotted = 0;
  avg_time_allotted_display = "";
  tot_minutes_allotted = 0;
  tot_minutes_allotted_display = "";
  modality = "";
  grading_stakes = "";
  mapping_count = 0;
  lo_percent = "";
}

const CourseActivityDetails: React.FC<{
  course: Course;
  list: List;
  expandable: boolean | true;
}> = ({ course, list, expandable }) => {
  const courseMinutes = ReportModels.getTotalTotMinutes(course);
  const courseMinutesDisplay = ReportModels.formatMinutes(courseMinutes);
  const modulesCount = course.modules?.length | 0;
  const activities = course.modules?.flatMap((x) => x.activities) || [];
  const mappings = activities.flatMap((a) =>
    a.mappings.filter((m) => m.list_id === list.id),
  );
  const { CourseLabel, ActivityLabel, ModulesLabel, ActivitiesLabel } =
    useOverrides();
  const courseLabel = CourseLabel();
  const activityLabel = ActivityLabel();
  const modulesLabel = ModulesLabel();
  const activitiesLabel = ActivitiesLabel();

  const distinct_activityTypes = Array.from(
    new Set(activities.map((a) => a.activity_type)),
  );
  const activitySummaries: ActivitySummary[] = distinct_activityTypes.map(
    (activity_type) => {
      const activitiesOfType = activities.filter(
        (a) => a.activity_type === activity_type,
      );
      const frequency = activitiesOfType.length;
      const tot_minutes = activitiesOfType.reduce(
        (acc, curr) => acc + curr.tot_minutes,
        0,
      );
      const avg_time_allotted = tot_minutes / frequency;
      const modality = activitiesOfType[0].modality; // assuming there is only one modality per activity_type
      const grading_stakes = activitiesOfType[0].grading_stakes; // assuming there is only one grading_stakes per activity_type
      const mapping_count = activitiesOfType.flatMap((a) =>
        a.mappings.filter((m) => m.list_id === list.id),
      ).length;
      const lo_percent =
        Math.round((mapping_count / mappings.length) * 100).toFixed(1) + "%";

      return {
        activity_type: activity_type,
        frequency: frequency,
        avg_time_allotted: avg_time_allotted,
        avg_time_allotted_display:
          ReportModels.formatMinutes(avg_time_allotted),
        tot_minutes_allotted: tot_minutes,
        tot_minutes_allotted_display: ReportModels.formatMinutes(tot_minutes),
        modality: modality,
        grading_stakes: grading_stakes,
        mapping_count: mapping_count,
        lo_percent: lo_percent,
      };
    },
  );

  return (
    <CompStyles>
      {course === null || list === null ? (
        <p>No {courseLabel?.toLowerCase()} data</p>
      ) : (
        <div>
          <h3>Summary of {activitiesLabel}</h3>

          <div>
            There are {mappings.length} learning objectives in this{" "}
            {courseLabel?.toLowerCase()}. The {courseLabel?.toLowerCase()} is
            designed as {modulesCount} {modulesLabel?.toLowerCase()}. The
            allotted time for a student to complete this{" "}
            {courseLabel?.toLowerCase()} is {courseMinutesDisplay}.
          </div>

          <table className="tbl striped">
            <thead>
              <tr>
                <th>{activityLabel} Type</th>
                <th className="center">Frequency</th>
                <th className="center">LOs Covered</th>
                <th className="center">Avg Time Allotted</th>
                <th className="center">Total Time Allotted</th>
                <th className="center">Modality</th>
                <th className="center">Grading Stakes</th>
              </tr>
            </thead>
            <tbody>
              {activitySummaries.map((item) => (
                <tr key={item.activity_type}>
                  <td>{item.activity_type}</td>
                  <td className="center">{item.frequency}</td>
                  <td className="center">{item.lo_percent}</td>
                  <td className="center">{item.avg_time_allotted_display}</td>
                  <td className="center">
                    {item.tot_minutes_allotted_display}
                  </td>
                  <td className="center">{item.modality}</td>
                  <td className="center">{item.grading_stakes}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>TOTAL</td>
                <td className="center">{activities.length}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td className="center">{courseMinutesDisplay}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tfoot>
          </table>

          <div className="mt-8">
            <h3 className="mb-2">
              List of {courseLabel} {activitiesLabel}
            </h3>

            {distinct_activityTypes.map((activity_type) => (
              <div key={activity_type} className="mb-8">
                <CourseActivityTypeDetail
                  course={course}
                  list_id={list.id}
                  activity_type={activity_type}
                  key={activity_type}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </CompStyles>
  );
};

export default CourseActivityDetails;

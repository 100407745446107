import React from "react";

import { Trash2Icon } from "lucide-react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/components/ui/alert-dialog";
import { IListManagerListItem } from "~/models/ListManager";

type DeleteListItemDialogProps = {
  listItem: IListManagerListItem;
  onDelete: (item: IListManagerListItem) => void;
};

const DeleteListItem = ({ listItem, onDelete }: DeleteListItemDialogProps) => {
  return (
    <AlertDialog>
      <div className="flex">
        <AlertDialogTrigger asChild>
          <button
            data-testid="delete-list-item"
            title="Delete List Item"
            aria-label="Delete List Item"
          >
            <Trash2Icon size="16px" className="text-destructive" />
          </button>
        </AlertDialogTrigger>
      </div>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete List Item</AlertDialogTitle>
          <AlertDialogDescription>
            {listItem.display_name} will be permanently deleted. Are you sure?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => onDelete(listItem)}>
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export { DeleteListItem };

import React from "react";

import classNames from "classnames";

type ButtonProps = {
  colorScheme?: ButtonColorScheme;
  leftIcon?: string;
  rightIcon?: string;
  label?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  size?: ButtonSize;
  width?: Width;
};

const Button = ({
  colorScheme = "grey",
  leftIcon,
  rightIcon,
  label,
  onClick,
  type,
  disabled,
  size = "small",
  width = "fit",
}: ButtonProps) => {
  const { textSize, iconSize } = ButtonSizes[size];

  const buttonClassnames = classNames(
    "flex min-w-[5rem] max-w-full items-center px-2 py-2 rounded-3xl",
    "transition-colors duration-200 ease-in-out",
    ButtonColorSchemes[colorScheme],
    WidthMap[width],
  );

  return (
    <button
      onClick={(event) => {
        onClick && !disabled && onClick(event);
      }}
      className={buttonClassnames}
      type={type}
    >
      <div
        className={classNames("w-full flex justify-center items-center", {
          "opacity-50 cursor-not-allowed": disabled,
        })}
      >
        {leftIcon && (
          <div
            className={`material-icons pr-1.5 ${iconSize}`}
            aria-hidden="true"
          >
            {leftIcon}
          </div>
        )}
        {label && <div className={`font-medium ${textSize}`}>{label}</div>}
        {rightIcon && (
          <div
            className={`material-icons pl-1.5 ${iconSize}`}
            aria-hidden="true"
          >
            {rightIcon}
          </div>
        )}
      </div>
    </button>
  );
};

type ButtonSize = "small" | "medium";
type ButtonSizesType = {
  [key in ButtonSize]: {
    textSize: string;
    iconSize: string;
  };
};
const ButtonSizes: ButtonSizesType = {
  small: {
    textSize: "text-[14px]",
    iconSize: "text-[20px]",
  },
  medium: {
    textSize: "text-[16px]",
    iconSize: "text-[20px]",
  },
} as const;

type ButtonColorScheme = "grey" | "blue" | "link";
const ButtonColorSchemes: Record<ButtonColorScheme, string> = {
  grey: "bg-button-grey hover:bg-[#ccd5d9] text-[#002e40] outline outline-1 outline-[#99abb3]",
  blue: "bg-button-blue hover:bg-[#0197cf] text-[#ffffff] outline outline-1 outline-[#99abb3]",
  link: "text-[#017FAE] hover:text-[#002e40] hover:underline hover:decoration-2 hover:underline-offset-4",
} as const;

type Width = "fit" | "full";
const WidthMap: Record<Width, string> = {
  fit: "w-fit",
  full: "w-full",
} as const;

export default Button;

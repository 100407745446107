import React, { useEffect, useState } from "react";

import { FilterIcon } from "lucide-react";

import { Button } from "~/components/ui/button";
import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectRoot,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import { Skeleton } from "~/components/ui/skeleton";
import { useMappingSheetContext } from "~/context/MappingSheetProvider";

export const MappingAssistantParentFilter = () => {
  const {
    parentItemId,
    setParentItemId,
    // listDetailsTree,
    parentItemOptions,
  } = useMappingSheetContext();

  const [key, setKey] = useState(+new Date());
  useEffect(() => {
    if (parentItemId === undefined) setKey(+new Date());
  }, [
    parentItemId,
    parentItemOptions,
    // listDetailsTree
  ]);

  // if (!listDetailsTree) return null;
  if (!parentItemOptions)
    return (
      <div className="flex items-center gap-2">
        <FilterIcon className="stroke-idesign-navy-70" />
        <Skeleton className="w-60 h-10 rounded-[18px]" />
      </div>
    );

  const handleChange = (value?: string) => {
    const newValue = value ? parseInt(value) : undefined;
    setParentItemId(newValue);
  };

  // const options = getParentItemOptions(listDetailsTree.items);
  const options = parentItemOptions ?? [];

  return (
    <div className="flex items-center gap-2">
      <FilterIcon className="stroke-idesign-navy-70" />
      <div className="w-60">
        <SelectRoot
          key={key}
          value={parentItemId?.toString()}
          onValueChange={handleChange}
          disabled={options.length === 0}
        >
          <SelectTrigger>
            <SelectValue placeholder="List Parent" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((option, index) => (
                <SelectItem value={option.value} key={index}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectGroup>
            <SelectSeparator />
            <Button
              className="w-full px-2"
              variant="secondary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                setParentItemId(undefined);
                setKey(+new Date());
                handleChange(undefined);
              }}
            >
              Clear
            </Button>
          </SelectContent>
        </SelectRoot>
      </div>
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";

import { isAxiosError } from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ApiUrls, IData } from "~/api/ApiPaths";
import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import { DialogFooter } from "~/components/ui/dialog";
import { Select, SelectOption } from "~/components/ui/select";
import { Textarea } from "~/components/ui/textarea";
import useAxios from "~/hooks/useAxios";
import { useStore } from "~/models/Root";
import { PERMISSION } from "~/models/UserManager";

type CreateListFormProps = {
  handleSuccess: (listId?: number) => void;
  handleOpenChange: (open: boolean) => void;
};

const CreateListForm = ({
  handleSuccess,
  handleOpenChange,
}: CreateListFormProps) => {
  const { appUser } = useStore();
  const {
    data: list_types,
    error: dataError,
    requestUrl,
    fetch,
    authToken,
  } = useAxios({ method: "GET", initialValue: null });
  const {
    data: createListData,
    error: createListError,
    fetch: createList,
    requestUrl: createListUrl,
    authToken: createListAuthToken,
    payload: createListPayload,
  } = useAxios({ method: "POST", initialValue: null });
  const [availableListTypes, setAvailableListTypes] = useState<
    SelectOption[] | null
  >(null);
  const fetchListTypes = useRef(true);
  const createdList = useRef(false);
  const [isGlobalList, setIsGlobalList] = useState(false);
  const [description, setDescription] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (fetchListTypes.current) {
      fetchListTypes.current = false;
      requestUrl.current = `${ApiUrls.lists.getListTypes}`;
      authToken.current = appUser.authtoken;
      fetch();
    }
  }, [fetch, requestUrl, authToken, appUser.authtoken]);

  useEffect(() => {
    //list_types drop down menu items
    if (!fetchListTypes.current && list_types !== null && list_types.length) {
      const tempListTypes: SelectOption[] = [];
      list_types.forEach((type: string) => {
        tempListTypes.push({ label: type, value: type });
      });
      setAvailableListTypes(tempListTypes);
    }
  }, [list_types]);

  useEffect(() => {
    //console.log("createListData", createListData, createdList.current)
    if (createListData && !createdList.current) {
      createdList.current = true;
      const Data = createListData as IData;
      if (Data && Data.issuccess) {
        handleSuccess(Data.entity.id);
      }
    }
  }, [createListData, handleSuccess]);

  return (
    <Formik
      initialValues={{
        name: "",
        list_type: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .min(5, "List name must be at least 5 characters long")
          .required("Name Required"),
        list_type: Yup.string().required("List Type Required"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        createListUrl.current = `${ApiUrls.lists.createList}`;
        createListAuthToken.current = appUser.authtoken;
        createListPayload.current = {
          name: values.name,
          list_type: values.list_type,
          is_global_list: isGlobalList,
          is_active: false,
          agency_id: appUser.agency_id,
          partner_id: appUser.selected_partner_id,
          program_id: 0,
          course_id: 0,
          description: description,
        };
        createList();
      }}
    >
      {({ isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form className="add-form">
            <div className="m-0 mt-2">
              <AlignFormikTextInput
                label={`List Name`}
                name="name"
                placeholder="Enter list name"
              />
            </div>
            <label className="input-title ">List type:</label>

            <Select
              fieldName="list_type"
              handleChange={(fieldName: string, newValue: string) =>
                setFieldValue(fieldName, newValue)
              }
              options={availableListTypes || []}
              fieldError={
                touched.list_type && errors.list_type
                  ? errors.list_type
                  : undefined
              }
              displayError={true}
              disabled={!availableListTypes}
              placeholder={availableListTypes ? "Select..." : "Loading..."}
              testid="list-type"
            />
            <div className="grid w-full gap-1.5 mt-0 mb-6">
              <label
                htmlFor="message"
                className="input-title mt-0 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                List Description
              </label>
              <Textarea
                placeholder="Description"
                id="message"
                className="resize-none h-40 w-full"
                onChange={(e) => setDescription(e.target.value)}
                data-testid="list-description"
              />
            </div>
            {appUser.basic_permissions.includes(
              PERMISSION.MANAGE_GLOBAL_LISTS,
            ) && (
              <div className="flex flex-row items-center space-x-2 mb-6">
                <Checkbox
                  name="is_global_list"
                  id={"is_global_list"}
                  checked={isGlobalList} //Because this form is built using formik i'll keep track of the state here and manually submit values
                  onCheckedChange={() => setIsGlobalList(!isGlobalList)}
                />
                <label
                  htmlFor="is_global_list"
                  className="input-title m-0 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Global List
                </label>
              </div>
            )}
            {dataError !== "" && (
              <div className="error-content-wrap">Error loading menu data.</div>
            )}
            {createListError !== "" && (
              <div className="error-content-wrap">
                <h5 className="error-header">
                  There was an error creating the new List:
                </h5>
                <div
                  className="error body-text m"
                  style={{ overflowWrap: "break-word" }}
                >
                  {isAxiosError(createListError) && createListError.response
                    ? createListError.response.data.message
                    : createListError.message}
                </div>
              </div>
            )}
            <DialogFooter>
              <Button
                type="button"
                variant="grey"
                onClick={() => handleOpenChange(false)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="blue" disabled={isSubmitting}>
                Create
              </Button>
            </DialogFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateListForm;

import React from "react";

import { BookText, Calculator, Clock } from "lucide-react";
import { observer } from "mobx-react-lite";

import { NodeCoverage } from "~/components/explorer/hooks/useNodeCoverage";
import CoverageDetails from "~/components/explorer/node/hover-details/CoverageDetails";
import { formatMinutes } from "~/components/Reports/ReportModels";
import { IActivity, NodeTypes } from "~/models/AlignEditor";

type NodeActivityDetailsProps = {
  activity: IActivity;
  coverage: NodeCoverage;
};

const NodeActivityDetails = observer(
  ({ activity, coverage }: NodeActivityDetailsProps) => {
    // const { mutation } = useEditCurriculumItem();
    // const { getProgram, saveActivityChanges } = useStore();

    // const handleEditActivity = (
    //   activityUpdates: ActivityEditable,
    //   activityOriginal: IActivity,
    // ) => {
    //   const activity = {
    //     ...activityOriginal,
    //     ...activityUpdates,
    //     engagement_types: activityUpdates.engagement_types?.join(", "),
    //     blooms: activityUpdates.blooms?.join(", "),
    //     grading_stakes: activityUpdates.grading_stakes ?? undefined,
    //   };
    //   saveActivityChanges(activity, activity.course_id, activity.module_id);
    //   mutation.mutate(getProgram()?.getCurriculumNodesForDatabasePost());
    // };

    const mappings = [...(activity.mappings ?? [])];

    return (
      <div className="flex justify-between space-x-4 w-full">
        <div className="space-y-1 w-full">
          <div className="flex justify-between w-full">
            <h4 className="self-center font-semibold pe-2">
              {activity.display_name}
            </h4>

            {/* <ActivityDialog
              activity={activity}
              onSubmit={(data) => handleEditActivity(data, activity)}
            >
              <Button size="xs">
                <div className="flex gap-1 items-center">
                  <EditIcon size="15" />
                  Edit
                </div>
              </Button>
            </ActivityDialog> */}
          </div>

          <p className="text-sm">{activity.description}</p>

          <div className="flex items-center pt-1">
            <BookText className="mr-2 h-4 w-4 opacity-70" />{" "}
            <span className="text-sm text-muted-foreground">
              {activity.activity_type}
            </span>
          </div>

          <div className="flex items-center pt-1">
            <Calculator className="mr-2 h-4 w-4 opacity-70" />{" "}
            <span className="text-sm text-muted-foreground">
              {activity.grading_points} points
            </span>
          </div>

          <div className="flex items-center pt-1">
            <Clock className="mr-2 h-4 w-4 opacity-70" />{" "}
            <span className="text-sm text-muted-foreground">
              {formatMinutes(activity.tot_minutes || 0)}
            </span>
          </div>

          <CoverageDetails
            id={activity.id}
            coverage={coverage}
            mappings={mappings}
            type={NodeTypes.Activity}
          />
        </div>
      </div>
    );
  },
);

export { NodeActivityDetails };

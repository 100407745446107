import React, { useState } from "react";

import { Reveal } from "@progress/kendo-react-animation";
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import CourseActivity from "~/components/Reports/CourseActivity";
import * as ReportModels from "~/components/Reports/ReportModels";
import { Select } from "~/components/ui/select";
import { useStore } from "~/models/Root";

const CompStyles = styled.div`
  padding: 0.5rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CourseActivities: React.FC<{
  programData: ReportModels.ProgramData | null;
  forPrint?: boolean | false;
}> = observer(({ programData, forPrint }) => {
  const [expandedCourse, setExpanded] = useState("");

  const { getDropdownListReports, setTargetListReports, getTargetListReports } =
    useStore();

  const listItems = getDropdownListReports();
  const targetListVar = getTargetListReports();

  const handleListChange = (listItemName: string, listId: string) => {
    const list =
      programData?.mapped_lists.find(
        (item: ReportModels.List) => item.id === parseInt(listId),
      ) || null;
    if (list) {
      setTargetListReports(list);
    }
  };

  return (
    <CompStyles>
      {!forPrint && (
        <div
          className="align-ddl"
          style={{
            position: "fixed",
            zIndex: "200",
            width: "fit-content",
            minWidth: "300px",
          }}
        >
          {!targetListVar && (
            <div style={{ marginBottom: "10px" }}>
              Select a list to view this data.
            </div>
          )}
          <Select
            fieldName="list-loader"
            handleChange={handleListChange}
            options={listItems}
            placeholder="Select list..."
            value={targetListVar?.id.toString() || undefined}
            variant="shadow"
          />
        </div>
      )}
      <FlexColumn>
        {targetListVar && targetListVar.id > 0 && (
          <div style={{ marginTop: forPrint ? "15px" : "60px" }}>
            {programData?.courses.map((course) => (
              <>
                {forPrint === true ? (
                  <div key={course.id} style={{ margin: "10px 0px" }}>
                    <CourseActivity
                      course={course}
                      list={targetListVar}
                      expandable={false}
                    />
                  </div>
                ) : (
                  <ExpansionPanel
                    key={course.id}
                    title={course.display_name}
                    expanded={expandedCourse === course.code}
                    tabIndex={0}
                    onAction={(event: ExpansionPanelActionEvent) => {
                      setExpanded(event.expanded ? "" : course.code);
                    }}
                  >
                    <Reveal>
                      {expandedCourse === course.code && (
                        <ExpansionPanelContent>
                          <div key={course.code} className="content">
                            <CourseActivity
                              key={course.code}
                              course={course}
                              list={targetListVar}
                              expandable={true}
                            />
                          </div>
                        </ExpansionPanelContent>
                      )}
                    </Reveal>
                  </ExpansionPanel>
                )}
              </>
            ))}
          </div>
        )}
      </FlexColumn>
    </CompStyles>
  );
});

export default CourseActivities;

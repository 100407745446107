import React, { Fragment, useMemo, useState } from "react";

import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { EditIcon, Minus, Plus } from "lucide-react";
import { observer } from "mobx-react-lite";

import ActivityDialog from "~/components/program/dialogs/ActivityDialog";
import { DeleteCurriculumNode } from "~/components/program/dialogs/DeleteNodeDialog";
import MappingTable from "~/components/program/tables/MappingTable";
import { Button } from "~/components/ui/button";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import {
  useStore,
  IActivity,
  NodeTypes,
  ActivityEditable,
} from "~/models/AlignEditor";

type ActivityTableProps = {
  courseId: number;
  moduleId: number;
};

const ActivityTable = observer(({ courseId, moduleId }: ActivityTableProps) => {
  const columns = useMemo<ColumnDef<IActivity>[]>(
    () => [
      {
        id: "expand",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <button
              {...{
                onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                style: { cursor: "pointer" },
              }}
              data-testid="activity-expand"
            >
              {row.getIsExpanded() ? (
                <Minus size="16px" />
              ) : (
                <Plus size="16px" />
              )}
            </button>
          </div>
        ),
      },
      {
        id: "edit",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <ActivityDialog
              activity={row.original}
              onSubmit={(data) => handleEditActivity(data, row.original)}
            >
              <button
                data-testid="program-edit-activity"
                title="Edit Activity"
                aria-label="Edit Activity"
              >
                <EditIcon size="16px" />
              </button>
            </ActivityDialog>
          </div>
        ),
      },
      {
        id: "code",
        header: "Code",
        cell: ({ row }) => <div>{row.original.code}</div>,
      },
      {
        id: "name",
        header: "Name",
        cell: ({ row }) => <div>{row.original.name}</div>,
      },
      {
        id: "display_name",
        header: "Display Name",
        cell: ({ row }) => <div>{row.original.display_name}</div>,
      },
      {
        id: "tot_minutes",
        header: () => <div className="text-end">Total Minutes</div>,
        cell: ({ row }) => (
          <div className="text-end">{row.original.tot_minutes}</div>
        ),
      },
      {
        id: "activity_type",
        header: "Type",
        cell: ({ row }) => <div>{row.original.activity_type}</div>,
      },
      {
        id: "is_assessment",
        header: "Is Assessment?",
        cell: ({ row }) => (
          <div>{row.original.is_assessment ? "Yes" : "No"}</div>
        ),
      },
      {
        id: "modality",
        header: "Modality",
        cell: ({ row }) => <div>{row.original.modality}</div>,
      },
      {
        id: "collaboration",
        header: "Collaboration",
        cell: ({ row }) => <div>{row.original.collaboration}</div>,
      },
      {
        id: "experience_type",
        header: "Experience Type",
        cell: ({ row }) => <div>{row.original.experience_type}</div>,
      },
      {
        id: "engagement_types",
        header: "Engagement Types",
        cell: ({ row }) => <div>{row.original.engagement_types}</div>,
      },
      {
        id: "blooms",
        header: "Blooms",
        cell: ({ row }) => <div>{row.original.blooms}</div>,
      },
      {
        id: "grading_points",
        header: () => <div className="text-end">Points</div>,
        cell: ({ row }) => (
          <div className="text-end">{row.original.grading_points}</div>
        ),
      },
      {
        id: "grading_stakes",
        header: "Stakes",
        cell: ({ row }) => <div>{row.original.grading_stakes}</div>,
      },
      {
        id: "delete",
        header: "",
        cell: ({ row }) => (
          <DeleteCurriculumNode
            id={row.original.id}
            type={NodeTypes.Activity}
            display_name={row.original.display_name}
            onDelete={deleteActivity}
          />
        ),
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {
    getProgram,
    getModuleActivities,
    saveActivityChanges,
    deleteCurriculumNode,
  } = useStore();

  const [data, setData] = useState<IActivity[]>(
    getModuleActivities(courseId, moduleId),
  );

  const handleAddActivity = (activity: ActivityEditable) => {
    getProgram()?.addActivity(activity, courseId, moduleId);
    setData(getModuleActivities(courseId, moduleId));
  };

  const handleEditActivity = (
    activityUpdates: ActivityEditable,
    activityOriginal: IActivity,
  ) => {
    const activity = {
      ...activityOriginal,
      ...activityUpdates,
      engagement_types: activityUpdates.engagement_types?.join(", "),
      blooms: activityUpdates.blooms?.join(", "),
      grading_stakes: activityUpdates.grading_stakes ?? undefined,
    };
    setData(saveActivityChanges(activity, courseId, moduleId));
  };

  const deleteActivity = (activityId: number) => {
    deleteCurriculumNode(activityId, NodeTypes.Activity);
    setData(getModuleActivities(courseId, moduleId));
  };

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="bg-neutral-20 text-neutral-100 font-semibold flex gap-2 items-center w-full p-2 border border-b-0 border-neutral-40">
        <span>Activities</span>
        <ActivityDialog onSubmit={handleAddActivity}>
          <Button variant="grey" size="xs" testid="program-add-activity">
            Add Activity
          </Button>
        </ActivityDialog>
      </div>
      {data.length > 0 && (
        <div className="w-full inline-block">
          <Table
            className={classNames(
              "max-w-fit overflow-x-auto table-fixed",
              "border border-neutral-40",
            )}
          >
            <colgroup>
              <col style={{ width: "40px" }} />
              <col style={{ width: "40px" }} />
              <col style={{ width: "120px" }} />
              <col style={{ width: "33%", minWidth: "200px" }} />
              <col style={{ width: "66%", minWidth: "200px" }} />
              <col style={{ width: "120px" }} />
              <col style={{ width: "136px" }} />
              <col style={{ width: "130px" }} />
              <col style={{ width: "188px" }} />
              <col style={{ width: "120px" }} />
              <col style={{ width: "200px" }} />
              <col style={{ width: "200px" }} />
              <col style={{ width: "120px" }} />
              <col style={{ width: "70px" }} />
              <col style={{ width: "144px" }} />
              <col style={{ width: "40px" }} />
            </colgroup>
            <TableHeader className="bg-neutral-20">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="hover:bg-neutral-20">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        className="font-semibold text-neutral-100 h-auto py-2"
                        key={header.id}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow
                      key={row.id + "_original"}
                      className={classNames(
                        "hover:bg-gray-200",
                        "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                        {
                          "border-b-0": row.getIsExpanded,
                          "bg-muted": row.index % 2 !== 0,
                          "bg-white": row.index % 2 === 0,
                        },
                      )}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className="py-2 border-x border-[#00000014]"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.getIsExpanded() ? (
                      <tr
                        key={row.id + "_expanded"}
                        className={classNames("border-b", {
                          "bg-muted": row.index % 2 !== 0,
                          "bg-white": row.index % 2 === 0,
                        })}
                      >
                        <TableCell
                          colSpan={columns.length}
                          className="pl-[40px] pr-0 pt-0"
                        >
                          <MappingTable
                            nodeId={row.original.id}
                            nodeType={NodeTypes.Activity}
                          />
                        </TableCell>
                      </tr>
                    ) : null}
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
});

export default ActivityTable;

import React, { useEffect, useState } from "react";

import AlignmentMappingDetails from "~/components/Reports/AlignmentMappingDetails";
import * as ReportModels from "~/components/Reports/ReportModels";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import { colors } from "~/css/shared-styles";

function getMappingsCountSumByListId(
  course: ReportModels.Course,
  list_id: number,
): string {
  const count = ReportModels.getTreeMappingsByListId(course, list_id)?.length;
  return count > 0 ? count.toString() : "";
}
function getMappingsCountSumByListItemId(
  course: ReportModels.Course,
  list_id: number,
  listitem_id: number,
): string {
  const count = ReportModels.getTreeMappingsByListItemId(
    course,
    list_id,
    listitem_id,
  ).length;
  return count > 0 ? count.toString() : "";
}
function getMappingsByListItemId(
  course: ReportModels.Course,
  list_id: number,
  listitem_id: number,
): ReportModels.MappingItem[] {
  return ReportModels.getTreeMappingsByListItemId(course, list_id, listitem_id);
}

class ModalDetails {
  course: ReportModels.Course = {} as ReportModels.Course;
  mappings: ReportModels.MappingItem[] = [];
  list: ReportModels.List = {} as ReportModels.List;
}

const AlignmentGridSection: React.FC<{
  programData: ReportModels.ProgramData;
  list: ReportModels.List;
  forPrint: boolean | false;
  showNumbers: boolean;
}> = ({ programData, list, forPrint, showNumbers }) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalDetails, setModalDetails] = React.useState<ModalDetails | null>(
    null,
  );
  const [isExpanded, setIsExpanded] = React.useState(forPrint);
  const [previousFocus, setPreviousFocus] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (modalDetails !== null) setModalVisible(true);
    else setModalVisible(false);
  }, [modalDetails]);

  useEffect(() => {
    if (modalVisible === false) {
      setModalDetails(null);
      previousFocus?.focus();
      // console.log("refocus");
    }
  }, [modalVisible, previousFocus]);

  const table = [...document.querySelectorAll("tr")].map((tr) => [
    ...tr.children,
  ]);

  const handleKeyDown = (
    event: React.KeyboardEvent,
    course: ReportModels.Course,
    mappings: ReportModels.MappingItem[],
    list: ReportModels.List,
  ) => {
    // Get the current cell's row and column indices
    const currentRow = table.findIndex((row) =>
      row.includes(event.currentTarget),
    );
    const currentCol = table[currentRow].indexOf(event.currentTarget);

    if (event.key === "Tab") return;
    if (event.key === "Enter" && mappings.length > 0) {
      setModalDetails({
        course: course,
        mappings: mappings,
        list: list,
      });
      const newCell = table[currentRow][currentCol] as HTMLElement;
      setPreviousFocus(newCell);
      return;
    }
    event.preventDefault(); //prevent arrow keys from moving page up and down

    let newRow = currentRow;
    let newCol = currentCol;

    switch (event.key) {
      case "ArrowUp":
        newRow = Math.max(0, currentRow - 1);
        break;
      case "ArrowDown":
        newRow = Math.min(table.length - 1, currentRow + 1);
        break;
      case "ArrowLeft":
        newCol = Math.max(0, currentCol - 1);
        break;
      case "ArrowRight":
        newCol = Math.min(table[0].length - 1, currentCol + 1);
        break;
      default:
        break;
    }

    // Get the new cell and focus it
    const newCell = table[newRow][newCol] as HTMLElement;
    newCell.focus();
  };

  return (
    <>
      <tbody style={{ height: "auto" }}>
        <tr
          key={list.id}
          className="list-name"
          style={{
            border: `${colors.lightGrey} solid 2px`,
            cursor: "pointer",
          }}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          role="row"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") setIsExpanded(!isExpanded);
          }}
        >
          <td className="!p-2.5 max-w-[50%]" role="gridcell">
            <div style={{ display: "flex" }}>
              <div className="material-icons" style={{ width: "auto" }}>
                {isExpanded ? "arrow_drop_down" : "arrow_right"}
              </div>
              {list.name}
            </div>
          </td>
          {programData?.courses.map((course, idx) => (
            <td key={idx} className="data-point" role="gridcell">
              {getMappingsCountSumByListId(course, list.id)}
            </td>
          ))}
        </tr>
        {list.items.map((item, itemIndex) => (
          <tr
            key={itemIndex}
            className="!p-2.5 max-w-[50%]"
            style={{
              display: isExpanded ? "table-row" : "none",
            }}
            role="row"
          >
            <td
              className={item.is_heading ? "indent-1x" : "indent-2x"}
              role="gridcell"
            >
              <div>{item.display_name}</div>
            </td>
            {programData?.courses.map((course, courseIndex) => {
              const count = getMappingsCountSumByListItemId(
                course,
                list.id,
                item.id,
              );
              const mappings = getMappingsByListItemId(
                course,
                list.id,
                item.id,
              );
              const label = showNumbers ? count : "";
              const iconName = showNumbers ? "" : "check";
              return (
                <td
                  key={courseIndex}
                  className="data-point"
                  role="gridcell"
                  tabIndex={count !== "" ? 0 : -1}
                  onKeyDown={(e) => {
                    handleKeyDown(e, course, mappings, list);
                  }}
                >
                  {count !== "" && (
                    <div
                      tabIndex={-1}
                      className="c-t-primary-60 cursor-pointer"
                      onClick={() =>
                        setModalDetails({
                          course: course,
                          mappings: mappings,
                          list: list,
                        })
                      }
                      aria-haspopup="dialog"
                    >
                      <span className="material-icons" aria-hidden="true">
                        {iconName}
                      </span>
                      {label}
                    </div>
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>

      {modalDetails && (
        <Dialog open={modalVisible} onOpenChange={setModalVisible}>
          <DialogContent className="max-w-[80vw] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>
                <h1 className="text-lg font-semibold mb-0">
                  {modalDetails.course.display_name}
                </h1>
              </DialogTitle>
            </DialogHeader>
            <AlignmentMappingDetails
              key={modalDetails.course.id}
              course={modalDetails.course}
              mappings={modalDetails.mappings}
              list={modalDetails.list}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AlignmentGridSection;

import React, { Suspense, useEffect, useRef, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { useLocation, useNavigate } from "react-router-dom";

import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import * as Yup from "yup";

import { ApiUrls } from "~/api/ApiPaths";
import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import Button from "~/components/design-system/Button";
import { AppWrapper } from "~/components/login/Login";
import Navbar from "~/components/Navbar";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import { useToast } from "~/components/ui/use-toast";
import useAxios from "~/hooks/useAxios";
import { useStore as useRootStore } from "~/models/Root";

/**
 * @description component for resetting password
 */
const ResetPassword: React.FC = observer(() => {
  const resetToken = useRef(useLocation().search.split("=")[1] || "");
  const [displayReady, setDisplayReady] = useState(false);
  const { data, error, requestUrl, fetch, payload } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const navigate = useNavigate();
  const { toast } = useToast();
  const [userResetEmail, setUserResetEmail] = useState("");
  const [updatedPasswordCall, setUpdatedPasswordCall] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const fetchOnce = useRef(true);
  const [resetRecordId, setResetRecordId] = useState(0);
  const { setAuthToken, resetAppUser } = useRootStore();

  useEffect(() => {
    if (fetchOnce.current) {
      setAuthToken("");
      resetAppUser();
      fetchOnce.current = false;
      requestUrl.current = `${ApiUrls.account.validateResetPassword(
        resetToken.current,
      )}`;
      fetch();
    }
  }, [fetchOnce, requestUrl, fetch, setAuthToken, resetAppUser]);

  useEffect(() => {
    if (data) {
      if (data.issuccess && !displayReady && !fetchOnce.current) {
        setDisplayReady(true);
        setUserResetEmail(data.entity.email);
        setResetRecordId(data.entity.reset_id);
      }
    } else if (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong",
        description:
          "Your reset password link is invalid or expired. Please request a new one.",
      });
      navigate("/login", { replace: true });
    }
  }, [
    data,
    error,
    displayReady,
    fetchOnce,
    toast,
    navigate,
    setDisplayReady,
    setUserResetEmail,
  ]);

  const updatePassword = (password: string, email: string) => {
    setUpdatedPasswordCall(true);
    requestUrl.current = `${ApiUrls.account.updatePassword}`;
    payload.current = {
      password: password,
      email: email,
      token: resetToken.current,
    };
    fetch("POST");
  };

  useEffect(() => {
    if (data && updatedPasswordCall) {
      if (data.issuccess) {
        toast({
          variant: "success",
          title: "Password Updated",
          description:
            "Check your email for confirmation of your updated password or contact support.",
        });
        setUpdateSuccessful(true);
      } else if (!data.issuccess) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong",
          description:
            "Your password could not be updated. Please try again or contact support.",
        });
      }
    } else if (error && updatedPasswordCall) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong",
        description:
          "Your password could not be updated. Please try again or contact support.",
      });
    }
  }, [data, error, updatedPasswordCall, toast, setUpdateSuccessful]);

  return (
    <AppWrapper>
      <Navbar />
      <div className="grid h-full place-items-center bg-gradient-to-b from-white to-gradient-blue">
        {!updateSuccessful ? (
          <div>
            <Card>
              <CardHeader>
                <div className="p-8 pb-0">
                  <CardTitle>Reset Password</CardTitle>
                </div>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col p-8 pt-0">
                  <Formik
                    initialValues={{
                      password: "",
                      confirmPassword: "",
                    }}
                    validationSchema={Yup.object({
                      password: Yup.string()
                        .min(8, "Too Short!")
                        .max(50, "Too Long!")
                        .required("Password Required")
                        .matches(/[0-9]/, "Password requires a number")
                        .matches(
                          /[a-z]/,
                          "Password requires a lowercase letter",
                        )
                        .matches(
                          /[A-Z]/,
                          "Password requires an uppercase letter",
                        )
                        .matches(/[^\w]/, "Password requires a symbol"),
                      confirmPassword: Yup.string()
                        .oneOf(
                          [Yup.ref("password"), null],
                          "Both passwords must match",
                        )
                        .required("Confirmed Password Required"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      updatePassword(values.password, userResetEmail);
                    }}
                  >
                    {({ errors, touched, values }) => (
                      <Form className="min-w-[17rem] sm:min-w-[29rem]">
                        <p className="pb-1">
                          Resetting password for <b>{userResetEmail}.</b>
                        </p>
                        <p className="pb-6">Do not reuse previous passwords.</p>
                        <AlignFormikTextInput
                          label="New Password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          materialIconName="lock_outline"
                          iconAlignment="left"
                        />
                        <div
                          className={
                            errors.password && touched.password
                              ? "absolute min-w-[17rem] sm:min-w-[29rem] -mt-10"
                              : "absolute min-w-[17rem] sm:min-w-[29rem] -mt-3"
                          }
                        >
                          <Suspense
                            fallback={
                              <div>Loading password strength meter...</div>
                            }
                          >
                            <PasswordStrengthBar password={values.password} />
                          </Suspense>
                        </div>
                        <div className="pl-6 pb-6">
                          <ul className="list-disc flex flex-wrap">
                            <li className="text-sm w-1/2">
                              &#x2265; 8 characters
                            </li>
                            <li className="text-sm w-1/2">Number</li>
                            <li className="text-sm w-1/2">Uppercase letter</li>
                            <li className="text-sm w-1/2">Special character</li>
                            <li className="text-sm w-1/2">Lowercase letter</li>
                          </ul>
                        </div>
                        <AlignFormikTextInput
                          label="Confirm Password"
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm Password"
                          materialIconName="lock_outline"
                          iconAlignment="left"
                        />
                        <div className="pl-6 pb-6">
                          <ul className="list-disc flex flex-wrap">
                            <li className="text-sm w-1/2">
                              Both passwords must match
                            </li>
                          </ul>
                        </div>
                        <Button
                          label="Reset Password"
                          type="submit"
                          colorScheme="blue"
                          width="full"
                          size="medium"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </CardContent>
            </Card>
            {/* <div className="pt-6 flex center">
              <Button
                label="Cancel"
                type="button"
                colorScheme="link"
                size="medium"
                onClick={() => {
                }}
              />
            </div> */}
          </div>
        ) : (
          <div>
            <Card>
              <CardHeader>
                <div className="p-8 pb-0">
                  <CardTitle>Password Reset!</CardTitle>
                </div>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col p-8 pt-0">
                  <p className="pb-6">
                    You can now log in with your new password.
                  </p>
                  <Button
                    label="Return to Login"
                    type="button"
                    colorScheme="blue"
                    width="full"
                    size="medium"
                    onClick={() => {
                      navigate("/login", { replace: true });
                    }}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </AppWrapper>
  );
});

export default ResetPassword;

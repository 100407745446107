import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui/dialog";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { IModule, ModuleEditable } from "~/models/AlignEditor";

type ModuleDialogProps = {
  module?: IModule;
  children: ReactNode;
  onSubmit: (data: ModuleEditable) => void;
};

const ModuleDialog = ({ module, children, onSubmit }: ModuleDialogProps) => {
  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      code: module?.code,
      name: module?.name,
      display_name: module?.display_name,
      week_number: module?.week_number ?? 0,
    },
  });

  const handleOpenChange = (open: boolean) => {
    if (open) {
      reset({
        code: module?.code,
        name: module?.name,
        display_name: module?.display_name,
        week_number: module?.week_number ?? 0,
      });
    }
  };

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <DialogHeader>
            <DialogTitle>Edit Module</DialogTitle>
            <DialogDescription>
              Make changes to the module here. Click Save Changes when you're
              done.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="code" className="text-right mb-0">
                Code
              </Label>
              <Input
                id="code"
                className="col-span-3"
                {...register("code", { required: "Code is required" })}
                aria-describedby={
                  errors.code?.message ? "code-error" : undefined
                }
              />
              {errors.code?.message && (
                <span
                  id="code-error"
                  className="col-start-2 col-span-3 text-sm font-medium text-error-50"
                >
                  {errors.code.message}
                </span>
              )}
            </div>

            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right mb-0">
                Name
              </Label>
              <Input
                id="name"
                className="col-span-3"
                {...register("name", { required: "Name is required" })}
                aria-describedby={
                  errors.name?.message ? "name-error" : undefined
                }
              />
              {errors.name?.message && (
                <span
                  id="name-error"
                  className="col-start-2 col-span-3 text-sm font-medium text-error-50"
                >
                  {errors.name.message}
                </span>
              )}
            </div>

            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="display_name" className="text-right mb-0">
                Display Name
              </Label>
              <Input
                id="display_name"
                className="col-span-3"
                {...register("display_name", {
                  required: "Display Name is required",
                })}
                aria-describedby={
                  errors.display_name?.message
                    ? "display_name-error"
                    : undefined
                }
              />
              {errors.display_name?.message && (
                <span
                  id="display_name-error"
                  className="col-start-2 col-span-3 text-sm font-medium text-error-50"
                >
                  {errors.display_name.message}
                </span>
              )}
            </div>

            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="week_number" className="text-right mb-0">
                Week
              </Label>
              <Input
                id="week_number"
                className="col-span-3"
                type="number"
                min="0"
                {...register("week_number", {
                  required: "Week is required",
                  min: 0,
                  valueAsNumber: true,
                })}
                aria-describedby={
                  errors.week_number?.message ? "week_number-error" : undefined
                }
              />
              {errors.week_number?.message && (
                <span
                  id="week_number-error"
                  className="col-start-2 col-span-3 text-sm font-medium text-error-50"
                >
                  {errors.week_number.message}
                </span>
              )}
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                type="submit"
                disabled={!isDirty || !isValid}
                variant="blue"
              >
                Save Changes
              </Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ModuleDialog;

import React, { useContext, useEffect, useMemo, useState } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ListFilterIcon } from "lucide-react";
import { observer } from "mobx-react-lite";

import { ApiUrls } from "~/api/ApiPaths";
import { AxiosContext } from "~/api/AxiosInstance";
import { useExplorerListDetailsQuery } from "~/components/explorer/hooks/useExplorerListDetailsQuery";
import { Button } from "~/components/ui/button";
import { Label } from "~/components/ui/label";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "~/components/ui/popover";
import { Select } from "~/components/ui/select";
import { SelectMultiple } from "~/components/ui/select-multiple";
import { useExplorerContext, ListResponse } from "~/context/ExplorerProvider";
import { useStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

const FilterPopover = observer(() => {
  const { appUser, getAuthToken, selectedProgramId, selectedPartnerId } =
    useRootStore();
  const { getCurrentProgramAvailableListIds } = useStore();
  const [lists, setLists] = useState<ListResponse[]>([]);

  const {
    selectedList,
    setSelectedList,
    listDetails,
    selectedListItemParent,
    setSelectedListItemParent,
    selectedListItems,
    setSelectedListItems,
  } = useExplorerContext();
  const axiosInstance = useContext(AxiosContext) ?? axios;

  const queryFn = (): Promise<any> =>
    axiosInstance
      .request({
        method: "GET",
        url: ApiUrls.lists.getListsList(appUser.agency_id, appUser.partner_id),
        headers: { Authorization: getAuthToken() },
      })
      .then((response) => response.data);

  const { data, isSuccess } = useQuery({
    queryKey: [
      "explorerFilterLists",
      ApiUrls.lists.getListsList(appUser.agency_id, appUser.partner_id),
    ],
    enabled: selectedProgramId !== 0,
    queryFn,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["explorerFilterLists"] });
  }, [selectedProgramId, queryClient]);

  useEffect(() => {
    if (isSuccess) {
      const listData = (data.entity as ListResponse[]).filter(
        (list) =>
          (getCurrentProgramAvailableListIds().includes(list.id) &&
            list.partner_id === selectedPartnerId &&
            list.is_active === true) ||
          (list.is_global_list === true && list.is_active === true),
      );
      setLists(listData);
    }
  }, [data, isSuccess, getCurrentProgramAvailableListIds, selectedPartnerId]);

  useExplorerListDetailsQuery();

  const [listSelectKey, setListSelectKey] = useState(+new Date());
  useEffect(() => {
    if (selectedList === undefined) setListSelectKey(+new Date());
  }, [selectedList]);

  const [headerSelectKey, setHeaderSelectKey] = useState(+new Date());
  useEffect(() => {
    if (selectedListItemParent === undefined) setHeaderSelectKey(+new Date());
  }, [selectedListItemParent]);

  const listOptions = useMemo(
    () =>
      lists.map((list) => ({
        value: list.id.toString(),
        label: list.name,
      })),
    [lists],
  );

  const listHeaderOptions = useMemo(() => {
    const parentIds = new Set<number>();
    listDetails?.items?.forEach((item) => {
      if (item.parent_id) parentIds.add(item.parent_id);
    });

    return (
      listDetails?.items
        ?.filter((item) => parentIds.has(item.id))
        .map((item) => ({
          value: item.id.toString(),
          label: item.display_name,
        })) ?? []
    );
  }, [listDetails]);

  const listDetailsOptions = useMemo(
    () =>
      listDetails?.items
        ?.filter((item) => item.is_mappable)
        .filter(
          (item) =>
            selectedListItemParent === undefined ||
            item.parent_id === parseInt(selectedListItemParent),
        )
        .map((item) => ({
          value: item.id.toString(),
          label: item.display_name,
        })) ?? [],
    [listDetails, selectedListItemParent],
  );

  const handleSelectList = (_fieldName: string, newValue: string) => {
    setSelectedList(newValue);
    setSelectedListItemParent(undefined);
    setSelectedListItems([]);
  };

  const handleSelectListItemParent = (_fieldName: string, newValue: string) => {
    setSelectedListItemParent(newValue);
    setSelectedListItems([]);
  };

  const handleResetClick = () => {
    setSelectedList(undefined);
    setSelectedListItemParent(undefined);
    setSelectedListItems([]);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size="lg" className="px-4">
          <div className="flex gap-2 items-center">
            <ListFilterIcon size="20" />
            Filters
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent side="bottom" className="ml-4 w-[600px]">
        <div className="grid gap-4">
          <div className="flex justify-between">
            <h2 className="font-bold text-lg mb-0">Edit Filters</h2>
            {selectedList && (
              <Button size="xs" onClick={handleResetClick}>
                Reset
              </Button>
            )}
          </div>
          <div className="grid grid-cols-5 items-center gap-4">
            <Label htmlFor="list" className="text-right mb-0">
              List
            </Label>
            <div className="col-span-4">
              <Select
                key={listSelectKey}
                options={listOptions}
                value={selectedList}
                handleChange={handleSelectList}
                fieldName={"list"}
                placeholder="Select a list to filter by"
              />
            </div>
          </div>

          <div className="grid grid-cols-5 items-center gap-4">
            <Label htmlFor="list-item-parent" className="text-right mb-0">
              List Item Parent
            </Label>
            <div className="col-span-4">
              <Select
                key={headerSelectKey}
                disabled={
                  selectedList === undefined || listHeaderOptions.length === 0
                }
                options={listHeaderOptions}
                value={selectedListItemParent}
                handleChange={handleSelectListItemParent}
                fieldName={"list-item-parent"}
                placeholder="Select a list item parent to filter by"
              />
            </div>
          </div>

          <div className="grid grid-cols-5 items-center gap-4">
            <Label htmlFor="list-item" className="text-right mb-0">
              List Item
            </Label>
            <div className="col-span-4">
              <SelectMultiple
                disabled={
                  selectedList === undefined || listDetailsOptions.length === 0
                }
                options={listDetailsOptions}
                values={selectedListItems}
                onValuesChange={(newValue) => setSelectedListItems(newValue)}
                selectPlaceholder="Select list items to filter by..."
                searchPlaceholder="Search for list items..."
                maxHeight="100px"
                clearable={true}
              />
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
});

export default FilterPopover;

import React from "react";

import styled from "styled-components";

//This component renders a Button styled like a link.

type AlignLinkProps = {
  leftIcon?: string;
  rightIcon?: string;
  label?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
};

const LinkWrap = styled.button<AlignLinkProps>`
  display: flex;
  width: fit-content;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  div.material-icons {
    padding-left: 0;
    font-size: 1.25rem;
  }
`;

const AlignLink = ({
  leftIcon,
  rightIcon,
  label,
  onClick,
  style,
}: AlignLinkProps) => {
  return (
    <LinkWrap
      onClick={() => {
        onClick && onClick();
      }}
      className="link-with-icon w-inline-block"
      style={style}
    >
      <div className="text-icon-wrapper c-t-primary-60">
        {leftIcon && (
          <div className="material-icons" aria-hidden="true">
            {leftIcon}
          </div>
        )}
        {label && <div className="button-text">{label}</div>}
        {rightIcon && (
          <div className="material-icons" aria-hidden="true">
            {rightIcon}
          </div>
        )}
      </div>
    </LinkWrap>
  );
};

export default AlignLink;

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { API_PATHS, isProduction } from "~/api/ApiPaths";
import AlignButton from "~/components/AlignButton";
import { DialogContext } from "~/components/AlignDialog";
import ProfileSheet from "~/components/ProfileSheet";
import { Select, SelectOption } from "~/components/ui/select";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import logo from "~/images/Align-G.png";
import logo2 from "~/images/IdesignLogo.svg";
import { useStore as useAlignEditorStore } from "~/models/AlignEditor";
import { IOverrides, IOverridesItem, useStore } from "~/models/Root";
import { IPartnerItem, IProgramItem } from "~/models/UserManager";

const PartnerProgramSelection = styled.div`
  .header-text {
    size: 0.75rem;
    margin: 0;
    margin-right: 0.625rem;
  }
  .header-right {
    margin-left: 0.625rem;
  }
`;

interface INavbar {
  style?: React.CSSProperties;
}

interface ITabItem {
  display_name: string;
  url: string;
}

/**
 * @description A component to display a navigation bar at the top of the application, it uses react-router-dom links for navigation
 * @style it accepts a styling object to customize its display
 */
const Navbar: React.FC<INavbar> = observer(() => {
  const { data, requestUrl, authToken, error, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const { PartnerLabel, ProgramLabel } = useOverrides();
  const [userTabs, setUserTabs] = useState<ITabItem[]>([]);
  const {
    getAuthToken,
    setSelectedPartnerId,
    setSelectedProgramId,
    setOverrides,
    getAppUser,
    getLoggedIn,
    resetTargetListReports,
  } = useStore();
  const [isLoggedIn] = useState(getLoggedIn());
  const { isProgramChanged, resetProgramTreeNode } = useAlignEditorStore();
  const finishedNavbarNodeFetch = useRef(false);
  const [partnerItems, setPartnerItems] = useState<SelectOption[]>([]);
  const [currentPartnerId, setCurrentPartnerId] = useState("default");
  const [currentProgramId, setCurrentProgramId] = useState("default");
  const [programItems, setProgramItems] = useState<SelectOption[]>([]);
  const [allPrograms, setAllPrograms] = useState([]);
  const fetchPartners = useRef(true);
  const partnersSet = useRef(false);
  const fetchPrograms = useRef(true);
  const [programsFound, setProgramsFound] = useState(false);
  const [partnerFound, setPartnerFound] = useState(false);
  const onlyOnePartner = useRef(false);
  const onlyOneProgram = useRef(false);
  const [appUser] = React.useState(getAppUser());
  const initialLoad = useRef(true);
  const [currentLocation, setCurrentLocation] = useState("");
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(location.pathname);
  const fetchingOverrides = useRef(false);
  const isCurrentPath = (path: string) => {
    return path === location.pathname ? "w--current" : "";
  };
  const [programDropdownKey, setProgramDropdownKey] = useState(+new Date());
  const { openDialog, closeDialog } = useContext(DialogContext);
  const navigate = useNavigate();
  const [forceRefresh, setForceRefresh] = useState(false);

  useEffect(() => {
    if (previousLocation === "/admin" && location.pathname !== "/admin") {
      fetchPartners.current = true;
      partnersSet.current = false;
      setPartnerFound(false);
      setPartnerItems([]);
      fetchPrograms.current = true;
      setProgramsFound(false);
      setForceRefresh(true);
    }
    setPreviousLocation(location.pathname);
    setCurrentLocation(location.pathname);
  }, [location.pathname, previousLocation]);

  //Logic for fetching and applying overrides
  const fetchOverrides = useCallback(
    (partnerId = 0, programId = 0) => {
      const agencyId = getAppUser().agency_id;
      requestUrl.current = `${API_PATHS.GET_LABELS}?agency_id=${agencyId}&partner_id=${partnerId}&program_id=${programId}`;
      authToken.current = getAuthToken();
      fetchingOverrides.current = true;
      fetch();
    },
    [authToken, getAuthToken, requestUrl, fetch, getAppUser],
  );

  useEffect(() => {
    if (
      data?.message === "Successfully fetched labels" &&
      fetchingOverrides.current
    ) {
      fetchingOverrides.current = false;
      const overrides: IOverrides[] = data.entity.map(
        (item: IOverridesItem) => ({
          id: item.id,
          key: item.key,
          value: item.value,
          type: item.type,
          is_override: item.is_override,
        }),
      );
      setOverrides(overrides);
    }
  }, [data, fetchingOverrides, setOverrides]);

  useEffect(() => {
    if (data?.entity && !userTabs.length) {
      const tabs: ITabItem[] = data.entity;
      if (!isProduction())
        tabs.push({ display_name: "Coverage Map", url: "/explorer" });
      setUserTabs(tabs);
      finishedNavbarNodeFetch.current = true;
    } else if (error) {
      console.log(error);
    } else if (!userTabs.length && isLoggedIn) {
      requestUrl.current = `${API_PATHS.GET_NAV_NODES}`;
      authToken.current = getAuthToken();
      fetch();
    }
  }, [
    authToken,
    data,
    error,
    fetch,
    getAuthToken,
    requestUrl,
    isLoggedIn,
    userTabs,
  ]);

  useEffect(() => {
    if (finishedNavbarNodeFetch.current) {
      if (data && !fetchPartners.current) {
        if (
          data?.issuccess &&
          data?.message === "Successfully fetched partner list" &&
          partnerItems.length === 0 &&
          !partnersSet.current
        ) {
          const partnerListItems: SelectOption[] = data.entity
            .filter((item: IPartnerItem) => item.id !== 0)
            .map((item: IPartnerItem) => ({
              value: `${item.id}`,
              label: item.short_name,
            }));

          if (partnerListItems.length > 0) {
            setPartnerFound(true);

            if (partnerListItems.length === 1) {
              onlyOnePartner.current = true;
              setCurrentPartnerId(partnerListItems[0].value);
              fetchOverrides(+partnerListItems[0].value);
              setSelectedPartnerId(+partnerListItems[0].value);
            }
          }

          partnersSet.current = true;
          setPartnerItems(partnerListItems);
          requestUrl.current = `${API_PATHS.GET_PROGRAMS_BY_PERMISSION}?permission=ListPrograms`;
          fetch();
        } else if (
          data?.message === "Successfully fetched program list" &&
          fetchPrograms.current
        ) {
          setAllPrograms(data.entity);
          fetchPrograms.current = false;

          if (onlyOnePartner.current) {
            const programListItems: SelectOption[] = data.entity
              .filter(
                (item: IProgramItem) =>
                  `${item.partner_id}` === currentPartnerId,
              )
              .map((item: IProgramItem) => ({
                value: `${item.id}`,
                label: item.display_name,
              }));

            setProgramItems(programListItems);

            if (programListItems.length > 0) {
              setProgramsFound(true);
              onlyOneProgram.current = false;
              if (programListItems.length === 1) {
                onlyOneProgram.current = true;
                setCurrentProgramId(programListItems[0].value);
                setSelectedProgramId(+programListItems[0].value);
                fetchOverrides(+currentPartnerId, +programListItems[0].value);
              }
            }
          } else if (forceRefresh && currentPartnerId !== "default") {
            setForceRefresh(false);
            const programListItems: SelectOption[] = data.entity
              .filter(
                (item: IProgramItem) =>
                  String(item.partner_id) === currentPartnerId,
              )
              .map((item: IProgramItem) => ({
                value: String(item.id),
                label: item.display_name,
              }));
            setProgramItems(programListItems);
            // Update the programs found state
            setProgramsFound(
              programListItems?.some(
                (item: SelectOption) => item.value !== "default",
              ),
            );
          }
        }
      } else if (fetchPartners.current) {
        fetchPartners.current = false;
        requestUrl.current = `${API_PATHS.GET_PARTNER_LIST}?permission=ListPartners`;
        fetch();
      } else if (error) {
        console.log(error);
      }
    }
  }, [
    data,
    error,
    authToken,
    requestUrl,
    fetch,
    getAuthToken,
    partnerItems,
    finishedNavbarNodeFetch,
    currentPartnerId,
    fetchOverrides,
    setSelectedPartnerId,
    setSelectedProgramId,
    fetchPartners,
    forceRefresh,
  ]);

  const handlePartnerChange = useCallback(
    (itemName: string, id: string, onLoad = false) => {
      if (id !== currentPartnerId) {
        setProgramDropdownKey(+new Date());
        setCurrentPartnerId(id);

        //filter the programs based off the new partner selected
        const programListItems: SelectOption[] = allPrograms
          .filter((item: IProgramItem) => String(item.partner_id) === id)
          .map((item: IProgramItem) => ({
            value: String(item.id),
            label: item.display_name,
          }));

        setProgramItems(programListItems);
        // Update the programs found state
        setProgramsFound(
          programListItems?.some(
            (item: SelectOption) => item.value !== "default",
          ),
        );
        // Update the user model
        setSelectedPartnerId(+id);
        if (programListItems.length === 1) {
          onlyOneProgram.current = true;
          setCurrentProgramId(programListItems[0].value);
          setSelectedProgramId(+programListItems[0].value);
          fetchOverrides(+id, +programListItems[0].value);
        } else {
          onlyOneProgram.current = false;
          setSelectedProgramId(0);
          fetchOverrides(+id);
        }
      }
    },
    [
      allPrograms,
      currentPartnerId,
      fetchOverrides,
      setSelectedPartnerId,
      setSelectedProgramId,
      setProgramItems,
      setProgramsFound,
    ],
  );

  //if the user refreshes the page the status of the dropdown is lost, this useeffect restores
  //the selected partner/program if the user has already selected one previously
  //[TODO] couldnt get this working for program yet but it works for partner
  useEffect(() => {
    if (
      initialLoad.current &&
      appUser.selected_partner_id !== 0 &&
      allPrograms.length > 0
    ) {
      initialLoad.current = false;
      handlePartnerChange(
        "partner_list",
        `${appUser.selected_partner_id}`,
        true,
      );
    }
  }, [appUser.selected_partner_id, handlePartnerChange, allPrograms]);

  const handleProgramChange = (_itemName: string, id: string) => {
    if (id === currentProgramId) {
      return;
    }
    setCurrentProgramId(id);
    // Update the user model
    resetTargetListReports();
    setSelectedProgramId(+id);
    fetchOverrides(+currentPartnerId, +id);
  };

  interface IWarnUnsavedChanges {
    linkTo?: string;
    partnerId?: string;
    programId?: string;
    fieldName?: string;
  }

  const warnUnsavedChanges = (props: IWarnUnsavedChanges) => {
    const {
      linkTo = "",
      partnerId = "",
      programId = "",
      fieldName = "",
    } = props;
    openDialog(
      <div className="dialog-content-wrap">
        <h5 className="dialog-header">Unsaved program changes</h5>
        <p className="body-text m">
          Your changes will be lost if you leave this view, continue anyway?
        </p>
        <div className="dialog-button-actions">
          <AlignButton
            label="Leave without saving"
            onClick={() => {
              resetProgramTreeNode();
              closeDialog();
              if (linkTo !== "") {
                navigate(linkTo);
              }
              if (partnerId !== "") {
                handlePartnerChange(fieldName, partnerId);
              }
              if (programId !== "") {
                handleProgramChange(fieldName, programId);
              }
            }}
          />
          <AlignButton
            label="Cancel"
            onClick={() => {
              closeDialog();
            }}
          />
        </div>
      </div>,
    );
  };

  return (
    <>
      <AppBar themeColor="inherit">
        <AppBarSection>
          <div className="brand-logo w-nav-brand">
            <Link to="/dashboard">
              {getLoggedIn() ? (
                <img
                  src={logo}
                  alt="iDesign 'Align' Word Logo"
                  className="brand-logo-image"
                />
              ) : (
                <img
                  src={logo2}
                  alt="iDesign Main Logo"
                  className="brand-logo-image"
                />
              )}
            </Link>
          </div>
        </AppBarSection>
        <AppBarSpacer style={{ width: "1rem" }} />
        <div className="nav-tabs">
          <div className="nav-tabs-container w-container">
            {getLoggedIn() && (
              <AppBarSection className="nav-menu w-nav-menu">
                {userTabs.map((tab: ITabItem, index: number) => (
                  <Link
                    key={index}
                    to={`${tab.url}`}
                    className={`nav-tab w-nav-link ${isCurrentPath(
                      `${tab.url}`,
                    )}`}
                    onClick={(event) => {
                      if (isProgramChanged) {
                        event.preventDefault();
                        if (document.activeElement instanceof HTMLElement) {
                          document.activeElement.blur();
                        }
                        warnUnsavedChanges({
                          linkTo: event.currentTarget.pathname,
                        });
                        return false;
                      }
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>
                      {tab.display_name.toUpperCase()}
                    </span>
                  </Link>
                ))}
              </AppBarSection>
            )}
          </div>
        </div>
        {isLoggedIn && (
          <AppBarSection
            style={{ flex: 1, flexDirection: "column", alignItems: "flex-end" }}
          >
            <div
              className="nav-tabs-container w-container"
              style={{ margin: "auto 0" }}
            >
              <ProfileSheet />
            </div>
          </AppBarSection>
        )}
      </AppBar>
      {getLoggedIn() && (
        <AppBar
          style={{
            display: currentLocation !== "/admin" ? "block" : "none",
            backgroundColor: "#e6eaec",
          }}
        >
          <PartnerProgramSelection className="partner-program-headline">
            <AppBarSection
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p className="header-text"> {PartnerLabel()} Selection:</p>
              <Select
                fieldName="partner_list"
                handleChange={(fieldName: string, newValue: string) => {
                  if (isProgramChanged) {
                    warnUnsavedChanges({
                      partnerId: newValue,
                      fieldName: fieldName,
                    });
                  } else {
                    handlePartnerChange(fieldName, newValue);
                  }
                }}
                options={partnerItems}
                value={
                  appUser.selected_partner_id !== 0
                    ? `${appUser.selected_partner_id}`
                    : onlyOnePartner.current
                    ? currentPartnerId
                    : undefined
                }
                placeholder={
                  partnerFound ? PartnerLabel() : `No ${PartnerLabel()} Found`
                }
                disabled={
                  partnerFound && onlyOnePartner.current === false
                    ? false
                    : true
                }
                variant="small"
                testid="partner-select"
              />
              <div
                style={{
                  display:
                    currentLocation !== "/lists" &&
                    currentLocation !== "/dashboard"
                      ? "flex"
                      : "none",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p className="header-text header-right flex-shrink-0">
                  {ProgramLabel()} Selection:
                </p>
                <Select
                  fieldName="programs_list"
                  handleChange={(fieldName: string, newValue: string) => {
                    if (isProgramChanged) {
                      warnUnsavedChanges({
                        programId: newValue,
                        fieldName: fieldName,
                      });
                    } else {
                      handleProgramChange(fieldName, newValue);
                    }
                  }}
                  options={programItems}
                  value={
                    appUser.selected_program_id !== 0
                      ? `${appUser.selected_program_id}`
                      : onlyOneProgram.current
                      ? currentProgramId
                      : undefined
                  }
                  placeholder={
                    currentPartnerId === "default"
                      ? `No ${PartnerLabel()} Selected`
                      : programsFound
                      ? `Choose a ${ProgramLabel()}`
                      : `No ${ProgramLabel()} Available`
                  }
                  disabled={!programsFound || onlyOneProgram.current === true}
                  key={programDropdownKey}
                  variant="small"
                  testid="program-select"
                />
              </div>
            </AppBarSection>
          </PartnerProgramSelection>
        </AppBar>
      )}
    </>
  );
});

export default Navbar;

import React from "react";

import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import * as Yup from "yup";

import { ApiUrls } from "~/api/ApiPaths";
import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import Button from "~/components/design-system/Button";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import useAxios from "~/hooks/useAxios";

const ForgotPassword: React.FC<{
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
}> = observer(({ setShowLogin }) => {
  const [emailSent, setEmailSent] = React.useState(false);
  const { data, payload, requestUrl, fetch } = useAxios({
    method: "POST",
    initialValue: null,
  });

  const sendEmail = (email: string) => {
    payload.current = {
      email: email,
    };
    requestUrl.current = `${ApiUrls.account.resetPasswordRequest}`;
    fetch();
  };

  React.useEffect(() => {
    if (data) {
      setEmailSent(true);
    }
  }, [data]);

  return (
    <div>
      {!emailSent ? (
        <Card>
          <CardHeader>
            <div className="p-8 pb-0">
              <CardTitle>Forgot Your Password?</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col p-8 pt-0">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email address")
                    .max(100, "Invalid email address")
                    .required("Email Required"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                  sendEmail(values.email);
                }}
              >
                <Form className="min-w-[17rem] sm:min-w-[29rem]">
                  <p className="pb-6">
                    Enter your email address to receive a reset link.
                  </p>
                  <AlignFormikTextInput
                    label="Email Address"
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    materialIconName="mail_outline"
                    iconAlignment="left"
                  />
                  <Button
                    label="Send Link"
                    type="submit"
                    colorScheme="blue"
                    width="full"
                    size="medium"
                  />
                </Form>
              </Formik>
              <div className="pt-6 flex justify-end">
                <Button
                  label="Return to Login"
                  type="button"
                  colorScheme="link"
                  size="medium"
                  onClick={() => {
                    setShowLogin(true);
                  }}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      ) : (
        <div>
          <Card>
            <CardHeader>
              <div className="p-8 pb-0">
                <CardTitle>Check Your Email</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col p-8 pt-0">
                <p className="pb-6">
                  If your email is in our system, a reset link will be sent to
                  your inbox.
                </p>
                <Button
                  label="Return to Login"
                  type="button"
                  colorScheme="blue"
                  width="full"
                  size="medium"
                  onClick={() => {
                    setShowLogin(true);
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      <div className="flex flex-col justify-center items-center pt-6">
        {!emailSent && (
          <div className="flex justify-center items-center">
            <p className="font-semibold">Don't have an account?</p>
            <Button
              label="Learn More"
              type="button"
              colorScheme="link"
              size="medium"
            />
          </div>
        )}
        <Button
          label="Contact Support"
          type="button"
          colorScheme="link"
          size="medium"
        />
      </div>
    </div>
  );
});

export default ForgotPassword;

import React, { useEffect, useState } from "react";

import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import Dialog from "@reach/dialog";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import AlignLink from "~/components/AlignLink";
import CourseActivity from "~/components/Reports/CourseActivity";
import CourseModule from "~/components/Reports/CourseModule";
import * as ReportModels from "~/components/Reports/ReportModels";
import { List } from "~/components/Reports/ReportModels";
import { Select } from "~/components/ui/select";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";

const CompStyles = styled.div`
  padding: 0.5rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DialogStyle = styled(Dialog)`
  min-width: 500px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  .dialog-button-actions {
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const SplitStyles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

// const defaultActivityTypes = [
//     "Assignment",
//     "Class Meeting",
//     "Discussion",
//     "Exam",
//     "Multimedia",
//     "Project",
//     "Quiz",
//     "Reading",
//     "Other"
// ];

function toPercent(num: number, places = 1): string {
  return (num * 100).toFixed(places) + "%";
}

function getActivityTypeDisplay(type: string): string {
  if (!type) return "Other";
  switch (type.toLowerCase()) {
    case "project":
      return "Project";

    case "discussion":
    case "discussion with research":
    case "discussion without research":
      return "Discussion";

    case "quiz":
      return "Quiz";

    case "assignment":
    case "graded assignment":
      return "Assignment";

    case "exam":
      return "Exam";

    case "class meeting":
    case "synchronous session":
    case "face to face session":
    case "face-to-face session":
      return "Class Meeting";

    case "reading":
      return "Reading";

    case "multimedia":
      return "Multimedia";

    case "other":
    default:
      return "Other";
  }
}

function getGradingStakesDisplay(grading: string): string {
  if (!grading) return "Other";
  switch (grading.toLowerCase()) {
    case "high":
    case "high_stakes":
    case "high stakes":
      return "High";

    case "medium":
    case "medium_stakes":
    case "medium stakes":
      return "Medium";

    case "low":
    case "low_stakes":
    case "low stakes":
      return "Low";

    case "other":
    default:
      return "Other";
  }
}

class ActivitySummaryRow {
  constructor(course: ReportModels.Course, list_id: number) {
    const activities = course.modules?.flatMap((m) => m.activities) || [];
    const totalMinutes = activities.reduce(
      (acc, curr) => acc + curr.tot_minutes,
      0,
    );
    const treeMappings = ReportModels.getTreeMappingsByListId(course, list_id);
    const hoursPerCredit = totalMinutes / 60 / course.credits;

    this.course_id = course.id;
    this.course_code = course.code;
    this.course_name = course.name;
    this.credits = course.credits;
    this.losCount = treeMappings.length;
    this.losPerCredit = (treeMappings.length / course.credits).toFixed(1);
    this.activityCount = activities.length;
    this.totalCourseMinutes = totalMinutes;
    this.totalCourseMinutesDisplay = ReportModels.formatMinutes(totalMinutes);
    this.hoursPerCredit = hoursPerCredit;
    this.hoursPerCreditDisplay = ReportModels.formatMinutes(hoursPerCredit);
  }

  course_id = 0;
  course_code = "";
  course_name = "";
  credits = 0;
  losCount = 0;
  losPerCredit = "";
  activityCount = 0;
  totalCourseMinutes = 0;
  totalCourseMinutesDisplay = "";
  hoursPerCredit = 0;
  hoursPerCreditDisplay = "";
}

class ActivityTypeRow {
  constructor(course: ReportModels.Course) {
    const totalMinutes =
      course.modules
        ?.flatMap((m) => m.activities)
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) || 0;
    const activities = course.modules?.flatMap((m) => m.activities) || [];

    this.course_id = course.id;
    this.course_code = course.code;
    this.course_name = course.name;
    this.assignmentPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Assignment")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.classMeetingPercent =
      activities
        .filter(
          (a) => getActivityTypeDisplay(a.activity_type) === "Class Meeting",
        )
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.discussionsPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Discussion")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.examPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Exam")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.multimediaPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Multimedia")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.projectPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Project")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.quizPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Quiz")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.readingPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Reading")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.otherPercent =
      1.0 -
      (this.assignmentPercent +
        this.classMeetingPercent +
        this.discussionsPercent +
        this.examPercent +
        this.multimediaPercent +
        this.projectPercent +
        this.quizPercent +
        this.readingPercent);
  }

  course_id = 0;
  course_code = "";
  course_name = "";
  assignmentPercent = 0;
  classMeetingPercent = 0;
  discussionsPercent = 0;
  examPercent = 0;
  multimediaPercent = 0;
  projectPercent = 0;
  quizPercent = 0;
  readingPercent = 0;
  otherPercent = 0;
}

class ActivityGradingStakesRow {
  constructor(course: ReportModels.Course) {
    const totalMinutes =
      course.modules
        ?.flatMap((m) => m.activities)
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) || 0;
    const activities = course.modules?.flatMap((m) => m.activities) || [];

    this.course_id = course.id;
    this.course_code = course.code;
    this.course_name = course.name;
    this.highPercent =
      activities
        .filter((a) => getGradingStakesDisplay(a.grading_stakes) === "High")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.mediumPercent =
      activities
        .filter((a) => getGradingStakesDisplay(a.grading_stakes) === "Medium")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.lowPercent =
      activities
        .filter((a) => getGradingStakesDisplay(a.grading_stakes) === "Low")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
    this.otherPercent =
      activities
        .filter((a) => getGradingStakesDisplay(a.grading_stakes) === "Other")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes;
  }

  course_id = 0;
  course_code = "";
  course_name = "";
  highPercent = 0;
  mediumPercent = 0;
  lowPercent = 0;
  otherPercent = 0;
}

class CompModel {
  constructor(data: ReportModels.ProgramData | null, list_id: number | null) {
    if (data === null || list_id === null) return;

    data.courses.forEach((course: ReportModels.Course) => {
      this.activitySummaryRows.push(new ActivitySummaryRow(course, list_id));
      this.activityTypeRows.push(new ActivityTypeRow(course));
      this.activityGradingStakeRows.push(new ActivityGradingStakesRow(course));
    });
  }

  activitySummaryRows: ActivitySummaryRow[] = [];
  activityTypeRows: ActivityTypeRow[] = [];
  activityGradingStakeRows: ActivityGradingStakesRow[] = [];
}

class ModalDetails {
  course: ReportModels.Course = {} as ReportModels.Course;
  list: ReportModels.List = {} as ReportModels.List;
}

const ProgramActivities: React.FC<{
  programData: ReportModels.ProgramData | null;
  forPrint?: boolean | false;
}> = observer(({ programData, forPrint }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDetails, setModalDetails] = useState<ModalDetails | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };
  const {
    ProgramLabel,
    CourseLabel,
    CoursesLabel,
    ActivityLabel,
    ActivitiesLabel,
  } = useOverrides();
  const programLabel = ProgramLabel();
  const courseLabel = CourseLabel();
  const coursesLabel = CoursesLabel();
  const activityLabel = ActivityLabel();
  const activitiesLabel = ActivitiesLabel();

  function getCourse(id: number): ReportModels.Course {
    return (
      programData?.courses.find((x) => x.id === id) || new ReportModels.Course()
    );
  }

  const initialSort: Array<SortDescriptor> = [
    { field: "assignmentPercent", dir: "asc" },
  ];

  const [sort, setSort] = React.useState(initialSort);

  useEffect(() => {
    if (modalDetails !== null) setModalVisible(true);
    else setModalVisible(false);
  }, [modalDetails]);

  const { getDropdownListReports, setTargetListReports, getTargetListReports } =
    useStore();
  const listItems = getDropdownListReports();
  const targetListVar = getTargetListReports();

  const handleListChange = (listItemName: string, listId: string) => {
    const list =
      programData?.mapped_lists.find(
        (item: List) => item.id === parseInt(listId),
      ) || null;
    if (list) {
      setTargetListReports(list);
    }
  };

  const Model = new CompModel(programData || null, targetListVar?.id || null);

  return (
    <CompStyles>
      {!forPrint && (
        <div
          className="align-ddl"
          style={{
            position: "fixed",
            zIndex: "15",
            width: "fit-content",
            minWidth: "300px",
          }}
        >
          {!targetListVar && (
            <div style={{ marginBottom: "10px" }}>
              Select a list to view this data.
            </div>
          )}
          <Select
            fieldName="list-loader"
            handleChange={handleListChange}
            options={listItems}
            // labelAlign={"left"}
            placeholder="Select a list"
            value={targetListVar?.id.toString() || undefined}
            variant="shadow"
          />
        </div>
      )}
      <FlexColumn>
        {targetListVar && targetListVar.id > 0 && (
          <>
            <div style={{ marginTop: forPrint ? "30px" : "60px" }}>
              <div>
                <b>{programLabel} Name:</b> {programData?.display_name}
              </div>
              <div>
                <b>Number of {coursesLabel}:</b>{" "}
                {programData?.courses?.length || 0}
              </div>
              <div>
                <b>Number of Credits:</b>{" "}
                {programData?.courses?.reduce(
                  (acc, curr) => acc + curr.credits,
                  0,
                ) || 0}
              </div>
            </div>

            <div className="mt-6">
              <h3>Types by Time</h3>
              <Grid
                data={orderBy(Model.activityTypeRows, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                  setSort(e.sort);
                }}
                style={{
                  width: "100%",
                }}
                id="grid1"
              >
                <Column
                  title="Code"
                  field="course_code"
                  headerClassName="column-header"
                  width={"120px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td style={{ padding: 0 }}>
                        <AlignLink
                          label={data.dataItem.course_code}
                          style={{
                            padding: "12px 10px",
                          }}
                          onClick={() =>
                            setModalDetails({
                              course: getCourse(data.dataItem.course_id),
                              list: targetListVar,
                            })
                          }
                          aria-haspopup="dialog"
                        />
                      </td>
                    );
                  }}
                />
                <Column
                  sortable={true}
                  field="course_name"
                  title="Course"
                  headerClassName="column-header"
                />
                <Column
                  title="Assignment"
                  sortable={true}
                  field="assignmentPercent"
                  headerClassName="column-header center"
                  width={"120px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td className="data-cell center">
                        {toPercent(data.dataItem.assignmentPercent)}
                      </td>
                    );
                  }}
                />
                <Column
                  title="Class Meeting"
                  sortable={true}
                  field="classMeetingPercent"
                  headerClassName="column-header center"
                  width={"120px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td className="data-cell center">
                        {toPercent(data.dataItem.classMeetingPercent)}
                      </td>
                    );
                  }}
                />
                <Column
                  title="Discussion"
                  sortable={true}
                  field="discussionsPercent"
                  headerClassName="column-header center"
                  width={"120px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td className="data-cell center">
                        {toPercent(data.dataItem.discussionsPercent)}
                      </td>
                    );
                  }}
                />
                <Column
                  title="Exam"
                  sortable={true}
                  field="examPercent"
                  headerClassName="column-header center"
                  width={"90px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td className="data-cell center">
                        {toPercent(data.dataItem.examPercent)}
                      </td>
                    );
                  }}
                />
                <Column
                  title="Multimedia"
                  sortable={true}
                  field="multimediaPercent"
                  headerClassName="column-header center"
                  width={"120px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td className="data-cell center">
                        {toPercent(data.dataItem.multimediaPercent)}
                      </td>
                    );
                  }}
                />
                <Column
                  title="Project"
                  sortable={true}
                  field="projectPercent"
                  headerClassName="column-header center"
                  width={"90px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td className="data-cell center">
                        {toPercent(data.dataItem.projectPercent)}
                      </td>
                    );
                  }}
                />
                <Column
                  title="Quiz"
                  sortable={true}
                  field="quizPercent"
                  headerClassName="column-header center"
                  width={"90px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td className="data-cell center">
                        {toPercent(data.dataItem.quizPercent)}
                      </td>
                    );
                  }}
                />
                <Column
                  title="Reading"
                  sortable={true}
                  field="readingPercent"
                  headerClassName="column-header center"
                  width={"90px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td className="data-cell center">
                        {toPercent(data.dataItem.readingPercent)}
                      </td>
                    );
                  }}
                />
                <Column
                  title="Other"
                  sortable={true}
                  field="otherPercent"
                  headerClassName="column-header center"
                  width={"90px"}
                  cell={(data: GridCellProps) => {
                    return (
                      <td className="data-cell center">
                        {toPercent(data.dataItem.otherPercent)}
                      </td>
                    );
                  }}
                />
              </Grid>
            </div>

            <div className="mt-6">
              <h3>{activityLabel} Stakes</h3>
              <table className="tbl striped">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>{courseLabel}</th>
                    <th className="center">High Stakes</th>
                    <th className="center">Medium Stakes</th>
                    <th className="center">Low Stakes</th>
                    <th className="center">Other</th>
                  </tr>
                </thead>
                <tbody>
                  {Model?.activityGradingStakeRows.map((row) => (
                    <tr key={row.course_id}>
                      <td>
                        <AlignLink
                          label={row.course_code}
                          onClick={() =>
                            setModalDetails({
                              course: getCourse(row.course_id),
                              list: targetListVar,
                            })
                          }
                          aria-haspopup="dialog"
                        />
                      </td>
                      <td>{row.course_name}</td>
                      <td className="center">{toPercent(row.highPercent)}</td>
                      <td className="center">{toPercent(row.mediumPercent)}</td>
                      <td className="center">{toPercent(row.lowPercent)}</td>
                      <td className="center">{toPercent(row.otherPercent)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-6">
              <h3>
                {programLabel} {activitiesLabel}
              </h3>
              <table className="tbl striped">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>{courseLabel}</th>
                    <th className="center">Number of Credits</th>
                    <th className="center">Number of LOs</th>
                    {/* <th className="center">LOs per Credit</th> */}
                    <th className="center">Number of {activitiesLabel}</th>
                    <th className="center">Total Time Allotted</th>
                    <th className="center">Hours per Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {Model?.activitySummaryRows.map((row) => (
                    <tr key={row.course_id}>
                      <td>
                        <AlignLink
                          label={row.course_code}
                          onClick={() =>
                            setModalDetails({
                              course: getCourse(row.course_id),
                              list: targetListVar,
                            })
                          }
                          aria-haspopup="dialog"
                        />
                      </td>
                      <td>{row.course_name}</td>
                      <td className="center">{row.credits}</td>
                      <td className="center">{row.losCount}</td>
                      {/* <td className="center">{row.losPerCredit}</td> */}
                      <td className="center">{row.activityCount}</td>
                      <td className="center">
                        {row.totalCourseMinutesDisplay}
                      </td>
                      <td className="center">
                        {row.hoursPerCreditDisplay.split("m")[0]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </FlexColumn>

      {modalDetails && (
        <DialogStyle
          aria-label="Course Mapping Details"
          title={modalDetails.course.display_name}
          isOpen={modalVisible}
          onDismiss={() => setModalDetails(null)}
          style={{ minWidth: "80vw" }}
        >
          <div className="dialog-content-wrap">
            <h5 className="dialog-header">
              <SplitStyles>
                <div>{modalDetails.course.display_name}</div>
                <div className="close-button">
                  <span onClick={() => setModalDetails(null)}>&times;</span>
                </div>
              </SplitStyles>
            </h5>
            <div className="dialog-content">
              <TabStrip selected={selectedTab} onSelect={handleSelect}>
                <TabStripTab title={activitiesLabel}>
                  <CourseActivity
                    course={modalDetails.course}
                    list={modalDetails.list}
                    expandable={true}
                  />
                </TabStripTab>
                <TabStripTab title="Modules">
                  <CourseModule
                    course={modalDetails.course}
                    list={modalDetails.list}
                    expandable={true}
                  />
                </TabStripTab>
              </TabStrip>
            </div>
          </div>
        </DialogStyle>
      )}
    </CompStyles>
  );
});

export default ProgramActivities;

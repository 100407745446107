import React, { useMemo, useState } from "react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  RowSelectionState,
  useReactTable,
} from "@tanstack/react-table";
import { BookText, LibrarySquare, Sparkles } from "lucide-react";
import { observer } from "mobx-react-lite";

import { useAIListItemsSuggestionsQuery } from "~/components/program/mapping/hooks/useAIListItemSuggestionsQuery";
import { MappingSuggestion } from "~/components/program/mapping/types";
import { flattenSuggestions } from "~/components/program/mapping/utils/flattenSuggestions";
import { Label } from "~/components/ui/label";
import { ScrollArea } from "~/components/ui/scroll-area";
import { Button } from "~/components/ui-rework/button";
import { Checkbox } from "~/components/ui-rework/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui-rework/table";
import { useMappingSheetContext } from "~/context/MappingSheetProvider";
import { cn } from "~/lib/utils";
import { getTmpId, useStore } from "~/models/AlignEditor";

export const MappingAssistantRecommendations = observer(() => {
  const { data } = useAIListItemsSuggestionsQuery();

  return data ? <MappingAssistantTable data={data} /> : null;
});

type MappingAssistantTableProps = {
  data: MappingSuggestion[];
};

const MappingAssistantTable = ({ data }: MappingAssistantTableProps) => {
  const { selectedListId, nodeId, nodeType, setLastSaved } =
    useMappingSheetContext();
  const {
    getNodeMappedSelectionsList,
    deleteCurriculumNodeMapping,
    getProgram,
  } = useStore();

  const columns = useMemo<ColumnDef<MappingSuggestion, any>[]>(
    () => [
      {
        header: () => {
          return <span className="pl-3">List Item</span>;
        },
        accessorKey: "display_name",
        cell: ({ row }) => (
          <div
            style={{
              paddingLeft: `${row.depth * 1.5}rem`,
            }}
          >
            <div className="flex items-center gap-2 leading-[17px] text-idesign-navy-100">
              {!row.original.parent_id &&
              (row.original.items?.length ?? 0) > 0 ? (
                <LibrarySquare className="stroke-idesign-navy-70" size="18" />
              ) : (
                <BookText className="stroke-idesign-navy-70" size="18" />
              )}
              <Label
                className={cn("m-0", {
                  "font-bold": row.original.is_heading,
                })}
              >
                {row.getValue("display_name")}
              </Label>
            </div>
          </div>
        ),
      },
      {
        header: "Reasoning",
        accessorKey: "reason",
        cell: ({ row }) => (
          <div className="flex items-center gap-2 leading-[17px] text-idesign-navy-100">
            {row.getValue("reason")}
          </div>
        ),
      },
      {
        header: () => {
          return (
            <span className="flex items-center justify-center text-center">
              Confirm Mapping
            </span>
          );
        },
        id: "select",
        cell: ({ row }) => (
          <div className="flex flex-col justify-center items-center light">
            {row.original.is_mappable && row.original.reason ? (
              <Checkbox
                id={`mapping-checkbox-${row.original.id}`}
                checked={row.getIsSelected()}
                onCheckedChange={(value) => {
                  row.toggleSelected(!!value);
                  updateNodeMapping(row.original, !value);
                  setLastSaved(Date.now());
                }}
                aria-label="Select row"
              />
            ) : null}
          </div>
        ),
        enableSorting: false,
        enableHiding: false,
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getRowId = (originalRow: MappingSuggestion, _index: number) =>
    originalRow.id.toString();

  const getInitialRowSelections = () => {
    const mappingIds = getNodeMappedSelectionsList(nodeId, nodeType).map(
      (item) => item.listitem_id,
    );
    const selections: RowSelectionState = {};
    flattenSuggestions(data)
      .filter((row) => row.reason && mappingIds.includes(row.id))
      .forEach((row) => (selections[row.id] = true));
    return selections;
  };
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    getInitialRowSelections(),
  );

  const program = getProgram();
  const list = program?.available_lists?.find((l) => l.id === selectedListId);
  const updateNodeMapping = (
    listItem: MappingSuggestion,
    isDelete: boolean,
  ) => {
    const program_id = program?.id;
    if (!nodeId || !program_id) return;

    if (!isDelete) {
      program?.addCurriculumNodeMapping(nodeId, nodeType, {
        id: getTmpId(),
        created_at: null,
        program_id: program_id,
        c_item_type: nodeType,
        c_item_id: nodeId,
        list_id: listItem.list_id,
        listitem_id: listItem.id,
        list_name: list?.name ?? "",
        listitem_display_name: listItem.display_name ?? listItem.name,
        is_ai_suggested_mapping: true,
        isNewItem: true,
      });
    } else {
      const nodeMappings = getNodeMappedSelectionsList(nodeId, nodeType);
      const mapping = nodeMappings
        .filter((m) => m.list_id === selectedListId)
        .find((m) => m.listitem_id === listItem.id);

      if (mapping?.id)
        deleteCurriculumNodeMapping(nodeId, nodeType, mapping.id);
    }
  };

  const table = useReactTable({
    data,
    columns,
    getSubRows: (row) => row.items,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableRowSelection: isSelectableRow,
    onRowSelectionChange: setRowSelection,
    enableSubRowSelection: false,
    filterFromLeafRows: true,
    state: {
      expanded: true,
      rowSelection,
    },
  });

  const toggleAllRowsSelected = (value: boolean) => {
    table.toggleAllRowsSelected(value);
    table
      .getFilteredRowModel()
      .flatRows.filter(isSelectableRow)
      .filter((row) => rowDoesNotMatchSelectionState(row, value))
      .forEach((row) => updateNodeMapping(row.original, !value));
    setLastSaved(Date.now());
  };

  const suggestionCount = table
    .getFilteredRowModel()
    .flatRows.filter((row) => row.original.reason !== undefined).length;

  return (
    <>
      <div className="flex flex-col flex-1 overflow-auto light">
        <div
          className="h-full min-h-0 grid gap-4"
          style={{ gridTemplateRows: "1fr auto" }}
        >
          <div
            className="grid overflow-auto"
            style={{ gridTemplateRows: "auto 1fr" }}
          >
            <div className="">
              <div className="rounded-t-md bg-idesign-navy-90 flex items-center gap-2 px-6 py-3">
                <Sparkles className="stroke-white" size={24} />
                <span className="text-base text-white">{`Recommendations (${suggestionCount})`}</span>
              </div>
              <div className="bg-idesign-navy-10 flex items-center justify-between px-6 py-1 border-x border-t border-idesign-navy-30">
                <span className="text-sm text-foreground">
                  Mappings suggested by Mapping Assistant
                </span>
                <div className="flex gap-1.5 items-center">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-idesign-navy-100 font-normal px-2.5 py-1"
                    onClick={() => toggleAllRowsSelected(false)}
                  >
                    Reject all
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-idesign-navy-100 font-normal px-2.5 py-1"
                    onClick={() => toggleAllRowsSelected(true)}
                  >
                    Accept all
                  </Button>
                </div>
              </div>
            </div>

            <ScrollArea className="w-full min-h-0 rounded-b-md border border-idesign-navy-30 border-t-0 scrollarea-viewport-first-child-h-full">
              <Table className="rounded-t-none table-fixed h-full">
                <colgroup>
                  <col />
                  <col />
                  <col width="120px" />
                </colgroup>
                <TableHeader className="text-xs sticky top-0 bg-white">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id} className="">
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead
                            className="h-8 text-idesign-navy-100 border-x"
                            key={header.id}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    <>
                      {table.getRowModel().rows.map((row) => (
                        <TableRow
                          key={row.id}
                          data-state={row.getIsSelected() && "selected"}
                          className="h-10 last:h-full"
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell
                              key={cell.id}
                              className={cn("border-x", {
                                "w-8 !pr-3": cell.column.id === "select",
                                "pl-6": cell.column.id === "display_name",
                              })}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                      <tr>
                        <td className="border-x"></td>
                        <td className="border-x"></td>
                        <td className="border-x"></td>
                      </tr>
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </ScrollArea>
          </div>

          <div className="flex items-center justify-end space-x-2">
            <div className="flex-1 text-sm text-muted-foreground">
              {table.getFilteredSelectedRowModel().flatRows.length} of{" "}
              {suggestionCount} row(s) selected.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const isSelectableRow = (row: Row<MappingSuggestion>) => {
  return !!(row.original.is_mappable && row.original.reason);
};

const rowDoesNotMatchSelectionState = (
  row: Row<MappingSuggestion>,
  selected: boolean,
) => {
  return row.getIsSelected() !== selected;
};

import React, { useContext, useEffect, useState } from "react";

import "@progress/kendo-theme-default/dist/all.css";
import { Laptop, Server } from "lucide-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import AlignButton from "~/components/AlignButton";
import { DialogContext } from "~/components/AlignDialog";
import ProgramTable from "~/components/program/tables/ProgramTable";
import { AlertDialog, AlertDialogContent } from "~/components/ui/alert-dialog";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { colors } from "~/css/shared-styles";
import useProgramEditorAPI from "~/hooks/useProgramEditorAPI";
import { useStore, IProgramTreeNode } from "~/models/AlignEditor";
//TODO: add this back in when we have the PDF export working

const CurriculumStylesWrap = styled.div`
  display: flex;
  flex-direction: column;
  div.k-widget.k-grid {
    overflow-y: scroll;
    overflow-x: hidden;
    div.k-grid-container {
      overflow: initial;
    }
  }

  &.program-top-level-view.k-pdf-export {
    .hidden,
    button.hidden {
      display: none;
    }
  }
`;

const ChangesBar = styled.div`
  display: flex;
  flex: 1;
  max-height: 3rem;
  min-height: 3rem;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: ${colors.secondaryGrey};
  align-items: center;
  box-shadow: 0 0.25rem 0.25rem -0.125rem #b2b2b2;
  .program-has-edits {
    display: flex;
    padding-left: 1rem;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }
`;

interface IProgramEdits {
  cancelChanges: () => void;
  saveChanges: () => void;
}

const ProgramEdits: React.FC<IProgramEdits> = observer((props) => {
  const { cancelChanges, saveChanges } = props;
  const { isProgramChanged, getProgram } = useStore();
  const programInstance = getProgram();
  const isNotValid = programInstance && programInstance.areValidationErrors;

  return (
    <>
      {isProgramChanged ? (
        <div className="program-has-edits">
          <span>Current Edits:</span>
          <AlignButton
            label="Cancel"
            leftIcon="not_interested"
            alignRight={true}
            onClick={() => cancelChanges()}
            style={{
              height: "fit-content",
              marginLeft: ".5rem",
            }}
          />
          <AlignButton
            label="Save"
            testid="program-save"
            leftIcon={isNotValid ? "warning" : "check"}
            onClick={() => saveChanges()}
            style={{
              height: "fit-content",
              marginLeft: ".5rem",
            }}
            disable={isNotValid}
          />
        </div>
      ) : null}
    </>
  );
});

//component designed to limit the "observer" hoc to not include the entire parent grid.
interface IExpanderProgram {
  setIsProgramExpanded: (isExpanded: boolean) => void;
}
const ObserveExpandedProgram = observer((props: IExpanderProgram) => {
  const { setIsProgramExpanded } = props;
  const { isProgramExpanded } = useStore();
  useEffect(() => {
    setIsProgramExpanded(isProgramExpanded);
  }, [isProgramExpanded, setIsProgramExpanded]);
  return null;
});

type AlignCurriculumDataGridProps = {
  reload?: () => void;
};

const AlignCurriculumDataGrid: React.FC<AlignCurriculumDataGridProps> = (
  props,
) => {
  const { reload = () => undefined } = props;
  const { getCurrentProgram, resetAll, setIsProgramChanges } = useStore();
  const { setPartnerId } = useProgramEditorContext();
  const { openDialog, closeDialog } = useContext(DialogContext);
  const [data, setData] = useState<IProgramTreeNode[]>(getCurrentProgram());
  const [isProgramExpanded, setIsProgramExpanded] = useState(false);

  const {
    isPersisted,
    setIsPersisted,
    postProgramUpdates: postCurriculumNodes,
    pending,
  } = useProgramEditorAPI();

  //we can probably get this info via the component that will be passed to the drawer.
  useEffect(() => {
    if (data[0].partner_id !== null) {
      setPartnerId && setPartnerId(data[0].partner_id);
    }
  }, [data, setPartnerId]);

  useEffect(() => undefined, [isProgramExpanded]);

  const cancelChanges = () => {
    openDialog(
      <div className="dialog-content-wrap">
        <h5 className="dialog-header">Cancel edits</h5>
        <p className="body-text m">
          {`This will reset all edits, are you sure?`}
        </p>

        <div className="dialog-button-actions">
          <AlignButton
            label="Cancel"
            onClick={() => {
              closeDialog();
            }}
          />
          <AlignButton
            label="Reset edits"
            onClick={() => {
              resetAll();
              setData(getCurrentProgram());
              closeDialog();
              reload();
            }}
          />
        </div>
      </div>,
    );
  };

  const postAllChanges = () => {
    postCurriculumNodes();
  };

  useEffect(() => {
    if (isPersisted === true) {
      setIsPersisted(false);
      setIsProgramChanges(false);
      reload();
    }
  }, [isPersisted, setIsPersisted, setIsProgramChanges, reload]);

  //TODO: add this back in when we have the PDF export working
  /*const exportPDFWithMethod = () => {
        let element: any = document.querySelector(`.program-top-level-view`) || document.body;
        savePDF(element, { paperSize: 'Letter', margin: '.5cm', scale: 0.4, fileName:`${data[0].code}-Program-PDF-Export` });
    }*/

  //TODO: add this back in when we have the PDF export working
  /*const getExportToPDFButton = () => {
        return (<AlignButton
            onClick={()=>{
                exportPDFWithMethod()
            }}
            style={{
                height:'fit-content',
                marginLeft:'.5rem'
            }}
            label='Save view to PDF' 
        />)
    }*/

  return (
    <CurriculumStylesWrap className="curriculum-grid-style program-top-level-view">
      <ChangesBar className="hidden font-bold">
        <span>Program</span>
        <ProgramEdits
          cancelChanges={cancelChanges}
          saveChanges={postAllChanges}
        />
        <AlertDialog open={pending}>
          <AlertDialogContent>
            <ProgramEditPending />
          </AlertDialogContent>
        </AlertDialog>
        <ObserveExpandedProgram
          setIsProgramExpanded={(isExpanded: boolean) => {
            setIsProgramExpanded(isExpanded);
          }}
        />
      </ChangesBar>
      <ProgramTable />
    </CurriculumStylesWrap>
  );
};

const ProgramEditPending = () => {
  return (
    <div className="flex flex-col gap-8 items-center justify-center light">
      <span className="text-idesign-navy-100 text-lg font-bold">
        Publishing changes...
      </span>

      <div className="flex justify-center items-center gap-6">
        <div className="flex justify-center items-center rounded-full h-[72px] w-[72px] bg-ocean-100">
          <Laptop size={38} className="stroke-white" />
        </div>
        <span
          style={{ animationDelay: "0ms" }}
          className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
        />
        <span
          style={{ animationDelay: "150ms" }}
          className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
        />
        <span
          style={{ animationDelay: "300ms" }}
          className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
        />
        <span
          style={{ animationDelay: "450ms" }}
          className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
        />
        <div className="flex justify-center items-center rounded-full h-[72px] w-[72px] bg-ocean-30">
          <Server size={38} className="stroke-white" />
        </div>
      </div>

      <span className="text-idesign-navy-100 text-center max-w-72">
        Your program edits are being published. Please wait.
      </span>
    </div>
  );
};

export default AlignCurriculumDataGrid;

import React, { useEffect, useState } from "react";

import { Checkbox } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  XAxis,
  YAxis,
} from "recharts";

import { formatHours } from "~/components/Reports/ReportModels";
import { TotModel } from "~/components/Reports/ToteSummary";
import {
  getColor,
  renderLegend,
} from "~/components/Reports/ToteSummaryDetailsVisuals";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";

export const renderPieLabel = (props: {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: any;
  outerRadius: any;
  startAngle: any;
  endAngle: any;
  fill: any;
  payload: any;
}) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {`${payload.category}`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${payload.tot_minutes_display}`}
      </text>
    </>
  );
};

export const renderActiveShape = (props: {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: any;
  outerRadius: any;
  startAngle: any;
  endAngle: any;
  fill: any;
  payload: any;
}) => {
  const { cx, cy, payload } = props;
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333">
        {payload.tot_percent}%
      </text>
      {renderPieLabel(props)}
    </g>
  );
};

export const showAllLabel = (props: any) => {
  return <g>{renderPieLabel(props)}</g>;
};

export const CustomizedLabel = (props: any, showAll: boolean) => {
  const { x, y, width, viewBox, value } = props;
  const middleY = y + viewBox.height / 2;
  if (value > 0) {
    return (
      <g>
        <text
          x={x + width + 5}
          y={middleY}
          fill="#666"
          textAnchor="start"
          dominantBaseline="middle"
          fontSize={15}
        >
          {formatHours(value)}
        </text>
      </g>
    );
  }
  return null;
};

const ToteSummaryVisuals: React.FC<{ data: TotModel | null }> = observer(
  ({ data }) => {
    const [index, setIndex] = useState(0);
    const [activeCategory, setActiveCategory] = useState("");
    const [barDataReady, setBarDataReady] = useState(false);
    const [barData, setBarData] = useState<any>([]);
    const [previousCategory, setPreviousCategory] = useState("");
    const [previousHover, setPreviousHover] = useState();
    const [previousIndex, setPreviousIndex] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const { ActivityLabel } = useOverrides();
    const activityLabel = ActivityLabel();
    const [isCheckboxFocused, setIsCheckboxFocused] = useState(false);

    const pieHover = (data: any, index: any) => {
      setPreviousCategory(activeCategory);
      setPreviousHover(data);
      setActiveCategory(data.category);
      setIndex(index);
      setShowAll(false);
    };

    useEffect(() => {
      if (firstLoad) {
        setFirstLoad(false);
        setActiveCategory("Assignments/Assessments");
        setPreviousCategory("Assignments/Assessments");
        setIndex(0);
      }
    }, [data, firstLoad]);

    useEffect(() => {
      if (activeCategory !== "") {
        const barData = data?.activityTypesSummary
          .filter((item) =>
            activeCategory === "ALL" ? true : item.category === activeCategory,
          )
          .map((item: any) => {
            const color = getColor(item.category);
            return {
              name: item.type,
              tot_minutes: item.tot_minutes,
              tot_minutes_display: item.tot_minutes_display,
              tot_percent: item.tot_percent,
              tot_hours: item.tot_minutes / 60,
              color: color,
            };
          });
        setBarData(barData);
        setBarDataReady(true);
      }
    }, [activeCategory, data]);

    const [showAll, setShowAll] = React.useState(false);
    const checkboxChange = (event: any) => {
      if (event.value) {
        setShowAll(event.value);
        setActiveCategory("ALL");
        setPreviousIndex(index);
        setIndex(-1);
      } else if (event.value === false && previousHover !== undefined) {
        setShowAll(event.value);
        setActiveCategory(previousCategory);
        pieHover(previousHover, index);
        setIndex(previousIndex);
      } else {
        setShowAll(event.value);
        setActiveCategory(previousCategory);
        setIndex(previousIndex);
      }
    };

    return (
      <div className="pl-1 pr-1">
        <h6 className="mt-0 ml-7">Aggregate Category Breakdown</h6>
        <ResponsiveContainer width="100%" height={280}>
          <PieChart
            role="img"
            aria-label="Pie graph that describes the the program broken down into each category. The categories include: Assignments or Assessments, Blended Learning, and Learning Activities."
          >
            <Legend
              content={renderLegend}
              verticalAlign="top"
              align="right"
              height={36}
            />
            {data && (
              <Pie
                label={showAll ? showAllLabel : undefined}
                tabIndex={0}
                focusable
                paddingAngle={
                  data?.categorySummary.filter((item) => item.tot_minutes > 0)
                    .length > 1
                    ? 5
                    : 0
                }
                activeIndex={index}
                activeShape={renderActiveShape}
                data={data?.categorySummary.filter(
                  (item) => item.tot_minutes !== 0,
                )}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill={colors.formButtonBlue}
                dataKey="tot_percent"
                onMouseEnter={showAll ? undefined : pieHover}
                onFocus={showAll ? undefined : pieHover}
              >
                {data?.categorySummary
                  .filter((item) => item.tot_minutes > 0)
                  .map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getColor(entry.category)}
                    />
                  ))}
              </Pie>
            )}
          </PieChart>
        </ResponsiveContainer>
        {barDataReady && (
          <>
            <h6 className="mt-0 ml-7">
              Aggregate {activityLabel} Type for{" "}
              {showAll ? "All Categories" : activeCategory}
            </h6>
            <Checkbox
              id="showAll"
              tabIndex={0}
              style={{
                boxShadow: isCheckboxFocused
                  ? "0px 0px 5px 0px rgba(0,0,0,0.80)"
                  : "0px 0px 5px 0px rgba(0,0,0,0.25)",
              }}
              className="ml-14 mt-0"
              value={showAll}
              onChange={checkboxChange}
              onFocus={() => setIsCheckboxFocused(true)}
              onBlur={() => setIsCheckboxFocused(false)}
            >
              <label
                htmlFor="showAll"
                className="ml-2 mt-0.5 shadow-none inline-block font-normal cursor-pointer"
              >
                Show All Activity Types (Expand Below)
              </label>
            </Checkbox>
            <ResponsiveContainer width={"100%"} height={415}>
              <BarChart
                width={600}
                height={380}
                data={barData}
                layout="vertical"
                margin={{ bottom: 20, left: 50, top: 20 }}
              >
                <XAxis type="number">
                  <Label value="Hours" position={"bottom"} />
                </XAxis>
                <YAxis
                  type="category"
                  dataKey="name"
                  tickLine={false}
                  interval={0}
                  fontSize={showAll ? 13 : 16}
                />
                <Bar dataKey="tot_hours">
                  <LabelList
                    content={<CustomizedLabel showAll={showAll} />}
                    position="right"
                  />
                  {barData.map((entry: any, index: any) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    );
  },
);

export default ToteSummaryVisuals;

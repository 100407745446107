import React from "react";

import { Trash2Icon } from "lucide-react";
import { observer } from "mobx-react-lite";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/components/ui/alert-dialog";
import { NodeTypes } from "~/models/AlignEditor";

type DeleteNodeDialogProps = {
  id: number;
  type: NodeTypes;
  display_name: string;
  onDelete: (id: number) => void;
};

const DeleteNodeDialog = observer(
  ({ id, type, display_name, onDelete }: DeleteNodeDialogProps) => {
    return (
      <AlertDialog>
        <div className="flex">
          <AlertDialogTrigger asChild>
            <button
              aria-label={`Delete ${type}`}
              data-testid={`delete-${type.toLowerCase()}`}
              title={`Edit ${type}`}
            >
              <Trash2Icon size="16px" className="text-destructive" />
            </button>
          </AlertDialogTrigger>
        </div>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete {type}</AlertDialogTitle>
            <AlertDialogDescription>
              {display_name} will be permanently deleted. Are you sure?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => onDelete(id)}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  },
);

export { DeleteNodeDialog as DeleteCurriculumNode };

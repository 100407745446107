import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { API_PATHS } from "~/api/ApiPaths";
import useAxios from "~/hooks/useAxios";
import dashboard from "~/images/dashboard-graphic.png";
import idesign from "~/images/iDesign_avatar.png";
import { useStore } from "~/models/Root";
import { PERMISSION } from "~/models/UserManager";

const DashboardStyling = styled.div`
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  width: 100%;
  height: 100%;
  background-color: #f3f5f5;
  display: flex;
  align-items: flex-start;

  .left-column,
  .right-column {
    width: 50%;
    height: 100%;
    display: flex;
  }
  .left-column {
    flex-direction: column;
    justify-content: flex-start;
  }
  .right-column {
    justify-content: flex-end;
    img {
      height: 100%;
    }
  }
  .dashboard-module-header {
    margin-right: 5rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    .partner-logo {
      background-color: #fff;
      border-radius: 20px;
      margin-right: 2rem;
      padding: 0.8rem;

      .partner-img {
        height: 6rem;
      }
    }
  }
  .module-header {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .module-container {
    padding: 1rem;
    border-radius: 20px;
    background-color: #e6eaec;
    width: 70%;
  }
  .main-body {
    margin-bottom: 1.5rem;
    display: flex;
    .quick-link {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
    .link-title {
      margin-right: 3rem;
      display: flex;
      flex-direction: row;
      .dashboard-quick-link {
        margin-right: 0.5rem;
      }
    }
  }
  .bottom-section {
  }
`;

const AlignDashboard: React.FC = observer(() => {
  const { getAuthToken, getAppUser } = useStore();
  const [appUser] = React.useState(getAppUser());
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const weekday = dayNames[new Date().getDay()];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[new Date().getMonth()];
  const date = new Date().getDate();
  const { data, requestUrl, authToken, error, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const fetchLogo = useRef(true);
  const [logoData, setLogoData] = useState<string>("");
  const [logoSuccessful, setLogoSuccessful] = useState(false);

  useEffect(() => {
    if (appUser.selected_partner_id !== 0) {
      fetchLogo.current = true;
    }
  }, [appUser.selected_partner_id]);

  useEffect(() => {
    if (data?.entity && !fetchLogo.current) {
      if (data.entity.includes("data:image/png;base64,")) {
        setLogoData(data.entity);
        setLogoSuccessful(true);
      } else {
        setLogoSuccessful(false);
      }
    } else if (fetchLogo.current && appUser.selected_partner_id !== 0) {
      fetchLogo.current = false;
      requestUrl.current = `${API_PATHS.GET_PARTNER_LOGO}/${appUser.selected_partner_id}`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    authToken,
    data,
    error,
    fetch,
    getAuthToken,
    requestUrl,
    fetchLogo,
    appUser.selected_partner_id,
  ]);

  return (
    <DashboardStyling>
      <title>Dashboard - Align App</title>
      <div className="left-column">
        <div className="dashboard-module-header">
          <div style={{ display: "flex" }}>
            {logoSuccessful ? (
              <div className="partner-logo">
                <img
                  src={logoData}
                  className="brand-logo-image partner-img"
                  alt=""
                />
              </div>
            ) : (
              <div className="partner-logo">
                <img
                  src={idesign}
                  className="brand-logo-image partner-img"
                  alt=""
                />
              </div>
            )}
            <div className="main-greeting">
              <h6 style={{ marginBottom: "0.5rem" }}>
                {weekday}, {month} {date}
              </h6>
              <h4 style={{ marginTop: 0 }}>Welcome {appUser.name}!</h4>
            </div>
          </div>
        </div>
        <div className="main-body">
          <div className="module-container">
            <h5 className="module-header">Quick Links</h5>
            <div className="quick-link">
              <div className="link-title">
                <span
                  className="material-icons icon-right dashboard-quick-link"
                  aria-hidden="true"
                >
                  done_outline
                </span>
                Want to view reports for a program?
              </div>
              <div>
                <Link to="/reports">Go to reports</Link>
              </div>
            </div>
            {appUser.basic_permissions.includes(
              PERMISSION.VIEW_PROGRAM_DETAILS,
            ) && (
              <div className="quick-link">
                <div className="link-title">
                  <span
                    className="material-icons icon-right dashboard-quick-link"
                    aria-hidden="true"
                  >
                    grid_view
                  </span>
                  Want to view programs?
                </div>
                <div>
                  <Link to="/programs">Go to programs</Link>
                </div>
              </div>
            )}
            {(appUser.basic_permissions.includes(
              PERMISSION.MANAGE_GLOBAL_LISTS,
            ) ||
              appUser.basic_permissions.includes(
                PERMISSION.VIEW_GLOBAL_LISTS,
              )) && (
              <div className="quick-link">
                <div className="link-title">
                  <span
                    className="material-icons icon-right dashboard-quick-link"
                    aria-hidden="true"
                  >
                    list
                  </span>
                  Want to view lists?
                </div>
                <div>
                  <Link to="/lists">Go to lists</Link>
                </div>
              </div>
            )}
            {appUser.basic_permissions.includes(PERMISSION.MANAGE_USERS) && (
              <div className="quick-link">
                <div className="link-title">
                  <span
                    className="material-icons icon-right dashboard-quick-link"
                    aria-hidden="true"
                  >
                    person_add_alt
                  </span>
                  Need to invite a user to Align?
                </div>
                <Link to="/admin" state={{ addUser: true }}>
                  Add User
                </Link>
              </div>
            )}
            {appUser.basic_permissions.includes(PERMISSION.MANAGE_PARTNERS) && (
              <div className="quick-link">
                <div className="link-title">
                  <span
                    className="material-icons icon-right dashboard-quick-link"
                    aria-hidden="true"
                  >
                    add_circle_outline
                  </span>
                  Need to add a partner to Align?
                </div>
                <Link to="/admin" state={{ addPartner: true }}>
                  Add Partner
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="right-column">
        <img style={{ objectFit: "contain" }} src={dashboard} alt="" />
      </div>
    </DashboardStyling>
  );
});

export default AlignDashboard;

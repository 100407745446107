import React, { useEffect, useState } from "react";

import {
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";

import * as ReportModels from "~/components/Reports/ReportModels";
import { formatHours } from "~/components/Reports/ReportModels";
import * as ToteModels from "~/components/Reports/ToteModels";
import ToteSummaryDetailsVisuals from "~/components/Reports/ToteSummaryDetailsVisuals";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";

const ModuleBarTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "10px",
          border: "1px solid #ccc",
          justifyContent: "center",
          alignContent: "center",
          height: "50px",
        }}
      >
        <p>
          <b>{payload[0].payload.name}:</b>{" "}
          {payload[0].payload.tot_minutes_display},{" "}
          {payload[0].payload.tot_percent}%
        </p>
      </div>
    );
  }
  return null;
};

const CompStyles = styled.div`
  width: 100%;

  table thead tr th:first-child,
  table tbody tr td:first-child {
    text-align: left;
  }
  table thead tr th:not(:first-child),
  table tbody tr td:not(:first-child) {
    text-align: center;
  }

  table tr.category td {
    font-weight: bold;
    background-color: #fafafa;
  }

  table tr.category td:first-child {
    padding-left: 10px;
  }

  .k-progressbar .k-selected {
    background-color: ${colors.formButtonBlue};
  }
`;

interface IModuleSummary {
  module_code: string;
  tot_minutes: number;
  tot_minutes_display: string;
  tot_percent: number;
  label: string;
}

function getModuleSummaries(
  course: ReportModels.Course,
  totalMinutes: number,
): IModuleSummary[] {
  const summaries: IModuleSummary[] = [];
  course.modules.forEach((module) => {
    const tot_minutes = module.activities.reduce(
      (a: number, b: ReportModels.Activity) => a + (b.tot_minutes || 0),
      0,
    );
    const percent = Math.round((tot_minutes / totalMinutes) * 100);
    summaries.push({
      module_code: module.code,
      tot_minutes: tot_minutes,
      tot_minutes_display: ReportModels.formatMinutes(tot_minutes),
      tot_percent: percent,
      label: `${module.code}`,
    });
  });

  return summaries;
}

function getUnitsByExpTypes(course: ReportModels.Course): IUnitByExpTypes[] {
  const result: IUnitByExpTypes[] = new Array<IUnitByExpTypes>();

  course.modules.forEach((module) => {
    result.push({
      unit: module.code,
      exp_types: activityExperienceTypes.map((expType) => {
        const tot_minutes = module.activities
          ?.filter((x) => x.experience_type === expType)
          .reduce(
            (a: number, b: ReportModels.Activity) => a + (b.tot_minutes || 0),
            0,
          );
        const tot_hours = tot_minutes / 60;
        if (tot_hours % 1 === 0) {
          return tot_hours.toString() + "h";
        }
        return tot_hours.toFixed(2) + "h";
      }),
    });
  });

  return result;
}

const activityExperienceTypes: string[] = [
  "Assimilative",
  "Finding and Handling Info",
  "Communication",
  "Productive",
  "Experiential",
  "Interactive/Adaptive",
];
interface IUnitByExpTypes {
  unit: string;
  exp_types: string[];
}

export class CompModel {
  constructor(course: ReportModels.Course) {
    const courseActivities = course?.modules?.flatMap((x) => x.activities);
    const activitySummaries =
      ToteModels.getActivityTypesSummary(courseActivities);
    const categorySummaries =
      ToteModels.getCategorySummaries(activitySummaries);
    const totalMinutes =
      courseActivities?.reduce(
        (a: number, b: ReportModels.Activity) => a + (b.tot_minutes || 0),
        0,
      ) || 0;
    const moduleSummary = getModuleSummaries(course, totalMinutes);

    this.totalMinutes = totalMinutes;
    this.totalMinutesDisplay = ReportModels.formatMinutes(totalMinutes);
    this.activitySummaries = activitySummaries;
    this.categorySummaries = categorySummaries;
    this.moduleSummaries = moduleSummary;
    this.unitHoursByExpType = getUnitsByExpTypes(course);
  }

  totalMinutes = 0;
  totalMinutesDisplay = "";
  activitySummaries: ToteModels.ITotSummary[] = [];
  categorySummaries: ToteModels.ITotSummary[] = [];
  moduleSummaries: IModuleSummary[] = new Array<IModuleSummary>();
  unitHoursByExpType: IUnitByExpTypes[] = new Array<IUnitByExpTypes>();
}

const CustomizedLabel = (props: any) => {
  const { x, y, width, viewBox, value } = props;
  const middleY = y + viewBox.height / 2;
  if (value > 0) {
    return (
      <g>
        <text
          x={x + width + 5}
          y={middleY}
          fill="#666"
          textAnchor="start"
          dominantBaseline="middle"
          fontSize={15}
        >
          {formatHours(value)}
        </text>
      </g>
    );
  }
  return null;
};

const ToteCourseDetails: React.FC<ReportModels.ICourseProps> = ({ course }) => {
  const [Model, setModel] = React.useState<CompModel | null>(null);
  const { ActivityLabel, ActivitiesLabel, ModuleLabel } = useOverrides();
  const activityLabel = ActivityLabel();
  const moduleLabel = ModuleLabel();
  const activitiesLabel = ActivitiesLabel();
  const [moduleBarReady, setModuleBarReady] = useState(false);
  const [moduleData, setModuleData] = useState<any>([]);
  const maxValue = Math.max(
    ...moduleData.map((x: { tot_hours: any }) => x.tot_hours),
  );

  const activityProfileKey = [
    {
      label: "Assimilative",
      value: "Read, Watch, Listen, Think about, Access, Observe, Review",
    },
    {
      label: "Finding and Handling Info",
      value:
        "List, Analyze, Collate, Plot, Find, Discover, Access, Use, Gather, Order, Classify, Select, Assess, Manipulate",
    },
    {
      label: "Communication",
      value:
        "Communicate, Debate, Discuss, Argue, Share, Report, Collaborate, Present, Describe, Question",
    },
    {
      label: "Productive",
      value:
        "List, Create, Build, Make, Design, Construct, Contribute, Complete, Produce, Write, Draw, Refine, Compose, Synthesize, Remix",
    },
    {
      label: "Experiential",
      value:
        "Practice, Apply, Mimic, Experience, Explore, Investigate, Perform, Engage",
    },
    {
      label: "Interactive/Adaptive",
      value: "Explore, Experiment, Trial, Improve, Model, Simulate",
    },
  ];

  useEffect(() => {
    if (course && Model === null) {
      const model = new CompModel(course);
      setModel(model);
    }
  }, [course, Model]);

  useEffect(() => {
    if (Model !== null && !moduleBarReady) {
      const moduleData = Model?.moduleSummaries.map((item) => {
        return {
          name: item.label,
          tot_minutes: item.tot_minutes,
          tot_minutes_display: item.tot_minutes_display,
          tot_percent: item.tot_percent,
          tot_hours: item.tot_minutes / 60,
          module_num: item.module_code.split(" ")[1],
        };
      });
      setModuleBarReady(true);
      setModuleData(moduleData);
    }
  }, [Model, moduleBarReady]);

  return (
    <CompStyles>
      <div className="mt-3">
        <h3>{activityLabel} Types & Categories</h3>

        <div className="flex-splitrow">
          <div className="splitrow-left">
            <table className="tbl">
              <thead>
                <tr>
                  <th id="type-category">Type / Category</th>
                  <th>Total Time</th>
                  <th>Percent</th>
                </tr>
              </thead>
              <tbody>
                {Model?.totalMinutes !== 0 ? (
                  Model?.categorySummaries.map((category) => {
                    return (
                      <>
                        <tr key={category.category} className="category">
                          <td
                            className="!pl-2.5 whitespace-nowrap"
                            id={category.category}
                          >
                            {category.category}
                          </td>
                          <td>{category.tot_minutes_display}</td>
                          <td>{category.tot_percent}%</td>
                        </tr>
                        {Model.activitySummaries
                          .filter((x) => x.category === category.category)
                          .map((activity) => {
                            return (
                              <tr key={activity.type} className="activity">
                                <td
                                  className="!pl-[20px] whitespace-nowrap"
                                  headers={"type-category " + category.category}
                                >
                                  {activity.type}
                                </td>
                                <td>{activity.tot_minutes_display}</td>
                                <td>{activity.tot_percent}%</td>
                              </tr>
                            );
                          })}
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>No {activitiesLabel} found.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              Total: <b>{Model?.totalMinutesDisplay}</b>
            </div>
          </div>

          {/*Activity Types Chart*/}
          {Model?.totalMinutes !== 0 && (
            <ToteSummaryDetailsVisuals data={Model} />
          )}
        </div>
      </div>

      <div className="mt-6">
        <h3>
          {moduleLabel} Summary - {course.code}
        </h3>
        <div className="flex-splitrow">
          <div className="splitrow-left">
            <table className="tbl striped">
              <thead>
                <tr>
                  <th className="!pl-2.5">{moduleLabel}</th>
                  <th>Total Time</th>
                  <th>Percent</th>
                </tr>
              </thead>
              <tbody>
                {Model?.moduleSummaries.length !== 0 ? (
                  Model?.moduleSummaries.map((item) => {
                    return (
                      <>
                        <tr key={item.module_code}>
                          {/* going to split this here in case module is overriden.  */}
                          <td className="!pl-2.5 whitespace-nowrap">{`${moduleLabel} ${
                            item.module_code.split(" ")[1]
                          }`}</td>
                          <td>{item.tot_minutes_display}</td>
                          <td>{item.tot_percent}%</td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>No {activitiesLabel} found.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              Total: <b>{Model?.totalMinutesDisplay}</b>
            </div>
          </div>

          {/*Module Summary Chart*/}
          {Model?.moduleSummaries.length !== 0 && moduleBarReady && (
            <ResponsiveContainer width="100%" height={575}>
              <BarChart
                width={575}
                height={600}
                data={moduleData}
                layout="vertical"
                margin={{ bottom: 20 }}
                maxBarSize={100}
              >
                <XAxis
                  type="number"
                  domain={[0, Math.floor(maxValue + maxValue / 4)]}
                >
                  <Label value="Hours" position={"bottom"} />
                </XAxis>
                <YAxis type="category" dataKey="module_num">
                  <Label
                    angle={-90}
                    offset={18}
                    value="Modules"
                    position="insideLeft"
                    style={{ textAnchor: "middle" }}
                  />
                </YAxis>
                {/* <ReferenceArea x1={0} x2={9}  stroke="red" strokeOpacity={0.3}  /> */}
                {/* <Tooltip
                  cursor={{ fill: "#f5f5f5" }}
                  content={<ModuleBarTooltip />}
                /> */}
                <Bar
                  dataKey="tot_hours"
                  fill="#7cb9e8"
                  label={<CustomizedLabel />}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>

      <div className="mt-6">
        <h3>
          {activityLabel} Profiles - {course.code}
        </h3>
        <h6 style={{ marginTop: 0, fontSize: "14px" }}>
          **{activityLabel} profiles key displayed below shows definitions for
          these categories.
        </h6>
        <table className="tbl striped">
          <thead>
            <tr>
              <th>{moduleLabel}</th>
              {activityExperienceTypes.map((type) => (
                <th key={type}>{type}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Model?.unitHoursByExpType.length !== 0 ? (
              Model?.unitHoursByExpType.map((item, idx) => {
                return (
                  <>
                    <tr key={idx}>
                      <td>{item.unit}</td>
                      {item.exp_types.map((expType, index) => (
                        <td key={index}>{expType}</td>
                      ))}
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan={activityExperienceTypes.length + 1}>
                  No {activitiesLabel} found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <h6 style={{ marginLeft: "2rem" }}>
          **{activityLabel} Profiles Key/Legend
        </h6>
        <table
          className="tbl striped"
          style={{ marginLeft: "2rem", width: "80%" }}
        >
          <thead>
            <tr>
              <th>Category</th>
              <th>Process Outcomes (learners will..)</th>
            </tr>
          </thead>
          <tbody>
            {activityProfileKey.map((item, idx) => {
              return (
                <>
                  <tr key={idx}>
                    <td>{item.label}</td>
                    <td style={{ textAlign: "left" }}>{item.value}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </CompStyles>
  );
};

export default ToteCourseDetails;

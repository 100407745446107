import React from "react";

import { Reveal } from "@progress/kendo-react-animation";
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";

import CourseModule from "~/components/Reports/CourseModule";
import * as ReportModels from "~/components/Reports/ReportModels";
import { Select } from "~/components/ui/select";
import { useStore } from "~/models/Root";

const CourseModules: React.FC<{
  programData: ReportModels.ProgramData | null;
  forPrint?: boolean;
}> = observer(({ programData, forPrint = false }) => {
  const [expandedCourse, setExpanded] = React.useState("");

  const { getDropdownListReports, setTargetListReports, getTargetListReports } =
    useStore();
  const listItems = getDropdownListReports();
  const targetListVar = getTargetListReports();

  const handleListChange = (listItemName: string, listId: string) => {
    const list =
      programData?.mapped_lists.find(
        (item: ReportModels.List) => item.id === parseInt(listId),
      ) || null;
    if (list) {
      setTargetListReports(list);
    }
  };

  return (
    <div className="p-2">
      <div
        className="align-ddl"
        style={{
          position: "fixed",
          zIndex: "200",
          width: "fit-content",
          minWidth: "300px",
        }}
      >
        {!forPrint && (
          <>
            {!targetListVar && listItems.length > 0 && (
              <div className="mb-2.5">Select a list to view this data.</div>
            )}
            {listItems.length > 0 && (
              <Select
                fieldName="list-loader"
                handleChange={handleListChange}
                options={listItems}
                placeholder="Select a list"
                value={targetListVar?.id.toString() || undefined}
                variant="shadow"
              />
            )}
          </>
        )}
      </div>
      <div className="flex-col w-full">
        {targetListVar && targetListVar.id > 0 && (
          <div
            className={
              forPrint ? "mt-4" : listItems.length === 0 ? "mt-0" : "mt-14"
            }
          >
            {programData?.courses.map((course) => (
              <>
                {forPrint === true ? (
                  <div key={course.code} className="mt-1">
                    <CourseModule
                      course={course}
                      list={targetListVar}
                      expandable={false}
                    />
                  </div>
                ) : (
                  <ExpansionPanel
                    key={course.code}
                    title={course.display_name}
                    expanded={expandedCourse === course.code}
                    tabIndex={0}
                    onAction={(event: ExpansionPanelActionEvent) => {
                      setExpanded(event.expanded ? "" : course.code);
                    }}
                  >
                    <Reveal>
                      {expandedCourse === course.code && (
                        <ExpansionPanelContent>
                          <CourseModule
                            key={course.code}
                            course={course}
                            list={targetListVar}
                            expandable={true}
                          />
                        </ExpansionPanelContent>
                      )}
                    </Reveal>
                  </ExpansionPanel>
                )}
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default CourseModules;

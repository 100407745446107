import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { z } from "zod";

import { API_PATHS, ApiUrls } from "~/api/ApiPaths";
import { AxiosContext } from "~/api/AxiosInstance";
import AlignEditModeButton from "~/components/AlignEditModeButton";
import ListItemTable from "~/components/lists/ListEditor/ListItemTable/ListItemTable";
import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { FormSelect, SelectOption } from "~/components/ui/select";
import { useToast } from "~/components/ui/use-toast";
import {
  ListItemForAPI,
  useListEditorContext,
} from "~/context/ListEditorProvider";
import { useStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";
import { PERMISSION } from "~/models/UserManager";
import { getReadableDate } from "~/utilities/DateFormat";

const formSchema = z.object({
  name: z.string().min(1, { message: "List Name is required" }),
  list_type: z.string().min(1, { message: "List Type is required" }),
  is_active: z.boolean(),
});

type ListPayload = {
  list_id: number;
  name: string;
  list_type: string;
  is_active: boolean;
};

type ListEditorProps = {
  listId: number;
  setSelectedListId: React.Dispatch<React.SetStateAction<number | null>>;
};

const ListEditor = observer(
  ({ listId, setSelectedListId }: ListEditorProps) => {
    const { listManagerInstance } = useStore();
    const { getAuthToken, appUser } = useRootStore();

    const { setListItems, getListItemsForPost } = useListEditorContext();

    const currentList = listManagerInstance().getCurrentSnapshot()[0];

    const axiosInstance = useContext(AxiosContext) ?? axios;

    const { toast } = useToast();

    const queryFn = (): Promise<SelectOption[]> =>
      axiosInstance
        .request({
          method: "GET",
          url: API_PATHS.LIST_TYPES,
          headers: { Authorization: getAuthToken() },
        })
        .then((response) =>
          response.data.map((type: string) => ({ label: type, value: type })),
        );

    const {
      data: listTypesData,
      isFetching: isFetchingListTypes,
      error: errorListTypes,
    } = useQuery({
      queryKey: ["listTypes", API_PATHS.LIST_TYPES],
      queryFn,
    });

    const handleMutationError = (error: Error) => {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong",
        description: error.message ?? "There was a problem with your request.",
      });
    };

    const listMutation = useMutation({
      mutationFn: (list: ListPayload) => {
        return axiosInstance.request({
          method: "POST",
          url: ApiUrls.lists.updateList,
          headers: { Authorization: getAuthToken() },
          data: list,
        });
      },
      onSuccess: (data) => {
        const entity: ListPayload = data.data.entity;
        if (entity) {
          listManagerInstance().updateValue("name", entity.name);
          listManagerInstance().updateValue("list_type", entity.list_type);
          listManagerInstance().updateValue("is_active", entity.is_active);
        }
      },
      onError: handleMutationError,
    });

    const listItemsMutation = useMutation({
      mutationFn: (items: ListItemForAPI[]) => {
        return axiosInstance.request({
          method: "POST",
          url: ApiUrls.lists.updateListItems,
          headers: { Authorization: getAuthToken() },
          data: { list_id: currentList.id, items },
        });
      },
      onError: handleMutationError,
    });

    useEffect(() => {
      if (listMutation.isSuccess && listItemsMutation.isSuccess) {
        toast({
          variant: "success",
          title: "Success",
          description: "List saved successfully.",
        });
        listManagerInstance().setShouldReloadManagerView(true);
      }
    }, [
      listMutation.isSuccess,
      listItemsMutation.isSuccess,
      listManagerInstance,
      toast,
    ]);

    const cancelChanges = () => {
      form.reset();
      setReadOnly(true);
      listManagerInstance().revertChanges();
      setListItems(listManagerInstance().getListItems());
    };

    const saveChanges = () => {
      const list = { list_id: currentList.id, ...form.getValues() };
      const listItemsForPost = getListItemsForPost();
      listMutation.mutate(list);
      listItemsMutation.mutate(listItemsForPost);
    };

    const [readOnly, setReadOnly] = useState(true);

    const form = useForm<z.infer<typeof formSchema>>({
      mode: "onChange",
      resolver: zodResolver(formSchema),
      defaultValues: {
        name: currentList.name,
        list_type: currentList.list_type,
        is_active: currentList.is_active,
      },
    });

    const canEditList = appUser.basic_permissions.includes(
      PERMISSION.MANAGE_GLOBAL_LISTS,
    )
      ? true
      : appUser.basic_permissions.includes(PERMISSION.MANAGE_PARTNER_LISTS) &&
        currentList.is_global_list === false
      ? true
      : false;

    if (errorListTypes)
      return (
        <div>
          <p>Error: Failed to fetch list types.</p>
          <p>{errorListTypes.message}</p>
        </div>
      );

    return isFetchingListTypes ? (
      <div>Loading...</div>
    ) : (
      <div className="flex flex-col gap-4 ">
        <div className="w-full flex flex-row justify-between items-center mt-5">
          <Button
            variant="outline"
            onClick={() => setSelectedListId(null)}
            className="self-start"
          >
            <span
              className="material-icons mr-1 text-[1.2rem] "
              aria-hidden="true"
            >
              keyboard_backspace
            </span>
            View a different list
          </Button>
          {canEditList && (
            <AlignEditModeButton
              variant={"outline"}
              readOnlyMode={readOnly}
              setReadOnlyMode={setReadOnly}
              enterEditMode={() => undefined}
              cancelChanges={cancelChanges}
              saveChanges={saveChanges}
              onClickCallback={() => {
                listManagerInstance().setLastSnapshot();
              }}
            />
          )}
        </div>
        <Form {...form}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base">List Name</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    disabled={readOnly}
                    variant="lg"
                    // rounded="full"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={"list_type"}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base">List Type</FormLabel>
                <FormSelect
                  testid="lists-type"
                  handleChange={field.onChange}
                  value={field.value}
                  options={listTypesData ?? []}
                  disabled={readOnly}
                />
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="grid gap-4 grid-flow-col">
            <FormField
              control={form.control}
              name={"is_active"}
              render={({ field }) => (
                <FormItem className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      disabled={readOnly}
                    />
                  </FormControl>
                  <FormLabel className="text-base">Active</FormLabel>
                </FormItem>
              )}
            />

            <div className="flex gap-2 items-center justify-center">
              <Label className="text-base mb-0 font-bold">Created on:</Label>
              <span className="text-base">
                {getReadableDate(currentList.created_at)}
              </span>
            </div>

            <div className="flex gap-2 items-center justify-center">
              <Label className="text-base mb-0 font-bold">
                Is global list:
              </Label>
              <span className="text-base">
                {currentList.is_global_list ? "Yes" : "No"}
              </span>
            </div>
          </div>
        </Form>
        <ListItemTable isEditing={!readOnly} />
      </div>
    );
  },
);

export default ListEditor;

import { TooltipPortal } from "@radix-ui/react-tooltip";
import React from "react";
import { Badge } from "~/components/ui/badge";
import { Separator } from "~/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";

type TagListProps = {
  tags: string[];
  title: string;
  limit?: number;
  truncate?: boolean;
};

const TagList = ({ tags, title, limit = 99, truncate }: TagListProps) => {
  const visibleLabels = tags.length > limit ? tags.slice(0, limit) : tags;
  const hiddenLabels = tags.length > limit ? tags.slice(limit) : [];

  return (
    <div className="flex gap-2 items-center">
      <span className="whitespace-pre font-bold text-sm italic">{title}:</span>
      {visibleLabels.map((tag, index) => (
        <Badge key={index} variant="filter" className="h-7">
          <span
            className={truncate ? "truncate max-w-[200px]" : ""}
            title={tag}
          >
            {tag}
          </span>
        </Badge>
      ))}
      {hiddenLabels.length > 0 ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Badge variant="filter" className="h-7 rounded-full text-nowrap">
                +{hiddenLabels.length} more
              </Badge>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent side="bottom" className="mr-4">
                <div className="grid gap-2">
                  {hiddenLabels.map((label, index) => (
                    <div key={index}>
                      <span className="max-w-[230px] text-sm font-normal">
                        {label}
                      </span>
                      {index < hiddenLabels.length - 1 && <Separator />}
                    </div>
                  ))}
                </div>
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </TooltipProvider>
      ) : null}
    </div>
  );
};

export default TagList;

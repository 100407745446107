import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { Label } from "~/components/ui/label";
import { SelectMultiple } from "~/components/ui/select-multiple";

export const formSchema = z.object({
  category_partner: z.boolean(),
  category_global: z.boolean(),
  is_active_yes: z.boolean(),
  is_active_no: z.boolean(),
  list_type: z.array(z.string()),
  programs: z.array(z.string()),
});

const listTypes = [
  { value: "Learning Objectives", label: "Learning Objectives" },
  { value: "Accreditation Standard", label: "Accreditation Standard" },
  { value: "Skills Competencies", label: "Skills Competencies" },
  { value: "Core Competencies", label: "Core Competencies" },
  { value: "Advanced Behaviors", label: "Advanced Behaviors" },
  { value: "Work Skills", label: "Work Skills" },
  { value: "Professional Standards", label: "Professional Standards" },
];

type ListFilterProps = {
  setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mappedPrograms: any;
  setFormValues: any;
  formValues: any;
};

const ListFilter = ({
  setFilterOpen,
  mappedPrograms,
  setFormValues,
  formValues,
}: ListFilterProps) => {
  const defaultValues = {
    category_partner: true,
    category_global: true,
    is_active_yes: true,
    is_active_no: true,
    list_type: [],
    programs: [],
  };

  const form = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: formValues ?? defaultValues,
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (!values.category_partner && !values.category_global) {
      values.category_partner = true;
      values.category_global = true;
    }
    if (!values.is_active_yes && !values.is_active_no) {
      values.is_active_yes = true;
      values.is_active_no = true;
    }
    setFormValues(values);
    setFilterOpen(false);
  }

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  return (
    <div className="h-full min-w-[425px] max-w-[425px] bg-white relative ">
      <div className="sticky top-0">
        <div className="bg-button-dark-blue text-white p-5 mb-2 flex items-center justify-between">
          <div className="flex items-center">
            <span
              className="material-icons mr-2 text-[1.7rem]"
              aria-hidden="true"
            >
              manage_search
            </span>
            <h1 className="m-0 text-lg">List Filter</h1>
          </div>
          <Button
            variant="ghost"
            onClick={() => setFilterOpen(false)}
            ref={closeButtonRef}
          >
            <span
              className="material-icons text-[1.7rem] transform scale-x-[-1]"
              aria-hidden="true"
            >
              keyboard_tab
            </span>
          </Button>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col px-8">
              <div>
                <Label className="text-base mb-0 font-bold py-3">
                  Category
                </Label>
                <div className="grid grid-cols-2">
                  <FormField
                    control={form.control}
                    name={"category_partner"}
                    render={({ field }) => (
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            data-testid={"lists-filter-local"}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel className="text-sm !font-normal">
                          Local
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={"category_global"}
                    render={({ field }) => (
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel className="text-sm !font-normal">
                          Global
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div>
                <Label className="text-base mb-0 font-bold mt-3 py-3">
                  Active
                </Label>
                <div className="grid grid-cols-2">
                  <FormField
                    control={form.control}
                    name={"is_active_yes"}
                    render={({ field }) => (
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel className="text-sm !font-normal">
                          Yes
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={"is_active_no"}
                    render={({ field }) => (
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel className="text-sm !font-normal">
                          No
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div>
                <Label className="text-base mb-0 font-bold mt-3 py-3">
                  List Type
                </Label>
                <div className="pb-4">
                  <FormField
                    control={form.control}
                    name={"list_type"}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <SelectMultiple
                            clearable
                            maxHeight="75px"
                            options={listTypes}
                            buttonMaxHeight="75px"
                            testid="lists-filter-type"
                            values={field.value ?? undefined}
                            onValuesChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div>
                <Label className="text-base mb-0 font-bold py-3">
                  Program Selection
                </Label>
                <div className="pb-4">
                  <FormField
                    control={form.control}
                    name={"programs"}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <SelectMultiple
                            clearable
                            onValuesChange={field.onChange}
                            values={field.value ?? undefined}
                            options={mappedPrograms}
                            maxHeight="75px"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4">
                <Button
                  type="submit"
                  disabled={!form.formState.isValid}
                  variant="blue"
                  className="w-full mt-4"
                  testid="lists-apply-filters"
                >
                  Apply Filters
                </Button>
                <Button
                  type="button"
                  className="text-button-blue underline w-full"
                  variant="link"
                  onClick={() => {
                    form.reset(defaultValues);
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default ListFilter;

import * as React from "react";

import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { VariantProps, cva } from "class-variance-authority";

import { cn } from "~/lib/utils";

const variants = {
  primary: cn("border-ocean-70 bg-gradient-one"),
};

const scrollAreaVariants = cva(
  cn("border rounded-lg	p-4 backdrop-blur-md shadow-md", "text-foreground"),
  {
    variants: {
      variant: variants,
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> &
    VariantProps<typeof scrollAreaVariants> & { focusable?: boolean }
>(({ className, children, focusable = false, variant, ...props }, ref) => {
  const [focused, setFocused] = React.useState(false);
  return (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={cn(
        "relative overflow-hidden",
        scrollAreaVariants({ variant }),
        className,
      )}
      type={focused ? "always" : "hover"}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        tabIndex={focusable ? 0 : undefined}
        className={cn(
          "h-full w-full",
          "focus:ring-0 focus:outline-ring focus:outline focus:outline-[3px] focus:outline-offset-4 focus:rounded-sm",
        )}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar variant={variant} />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  );
});
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<
    typeof ScrollAreaPrimitive.ScrollAreaScrollbar
  > &
    VariantProps<typeof scrollAreaVariants>
>(({ className, orientation = "vertical", variant, ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cn(
        "flex touch-none select-none transition-colors bg-none m-4",
        orientation === "vertical" && "w-3 border-l border-l-transparent",
        orientation === "horizontal" &&
          "h-3 flex-col border-t border-t-transparent",
        className,
      )}
      {...props}
    >
      <div
        className={cn(
          "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-md bg-scrollbar-track",
          variant === "primary" ? "w-[3.5px] h-full" : "h-[3.5px] w-full",
          orientation === "vertical" ? "w-[3.5px] h-full" : "h-[3.5px] w-full",
        )}
      />
      <ScrollAreaPrimitive.ScrollAreaThumb
        className={cn("relative flex-1 rounded-full bg-scrollbar-thumb")}
      />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  );
});
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { observer } from "mobx-react-lite";

import { API_PATHS } from "~/api/ApiPaths";
import Divider from "~/components/Divider";
import { Button } from "~/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/components/ui/sheet";
import useAxios from "~/hooks/useAxios";
import defaultProfile from "~/images/iDesign_avatar.png";
import { useStore as useRootStore } from "~/models/Root";

const ProfileSheet: React.FC = observer(() => {
  const { setAuthToken, getAuthToken, resetAppUser, setLoggedIn, getAppUser } =
    useRootStore();
  const { data, error, requestUrl, authToken, fetch } = useAxios({
    method: "POST",
  });
  const navigate = useNavigate();
  const [appUser] = React.useState(getAppUser());
  const fetchLogo = useRef(true);
  const [logoData, setLogoData] = useState<string>("");
  const [logoSuccessful, setLogoSuccessful] = useState(false);
  const [previousPartnerId, setPreviousPartnerId] = useState(
    appUser.selected_partner_id,
  );
  const initiateLogout = useRef(false);

  const logout = () => {
    initiateLogout.current = true;
    authToken.current = getAuthToken();
    requestUrl.current = API_PATHS.LOGOUT;
    fetch();
  };

  useEffect(() => {
    if (
      appUser.selected_partner_id !== 0 &&
      previousPartnerId !== appUser.selected_partner_id
    ) {
      fetchLogo.current = true;
      setPreviousPartnerId(appUser.selected_partner_id);
    }
  }, [appUser.selected_partner_id, previousPartnerId]);

  useEffect(() => {
    if (data?.entity && !fetchLogo.current) {
      if (data.entity.includes("data:image/png;base64,")) {
        setLogoData(data.entity);
        setLogoSuccessful(true);
      } else {
        setLogoSuccessful(false);
      }
    } else if (fetchLogo.current && appUser.selected_partner_id !== 0) {
      fetchLogo.current = false;
      requestUrl.current = `${API_PATHS.GET_PARTNER_LOGO}/${appUser.selected_partner_id}`;
      authToken.current = getAuthToken();
      fetch("GET");
    } else if (error) {
      console.log(error);
    }
  }, [
    authToken,
    data,
    error,
    fetch,
    getAuthToken,
    requestUrl,
    fetchLogo,
    appUser.selected_partner_id,
  ]);

  useEffect(() => {
    if (data || error) {
      if (initiateLogout.current) {
        initiateLogout.current = false;
        setAuthToken("");
        resetAppUser();
        setLoggedIn(false);
        navigate("/login", { replace: true });
      }
    }
  }, [data, error, setAuthToken, resetAppUser, setLoggedIn, navigate]);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant="outline"
          className="rounded-full w-12 h-12 p-1 bg-gray-100 hover:bg-gray-300 outline outline-2 outline-gray-200"
          testid="profile-sheet-trigger"
        >
          <img
            src={logoSuccessful ? logoData : defaultProfile}
            alt="Profile"
            className="object-contain rounded-full"
          />
        </Button>
      </SheetTrigger>
      <SheetContent className="w-1/4 min-w-[400px]">
        <div className="mx-auto w-full max-w-m">
          <SheetHeader className="flex flex-row justify-items-center">
            <div className="flex flex-row justify-start items-center">
              <div className="rounded-full w-10 h-10 p-1 bg-gray-100  outline outline-2 outline-gray-200">
                <img
                  src={logoSuccessful ? logoData : defaultProfile}
                  alt="Profile"
                  className="object-cover rounded-full"
                />
              </div>
              <div>
                <SheetTitle className="p-0 m-0 mx-4 justify-items-center align-text-center">
                  {appUser?.first_name} {appUser?.last_name}
                </SheetTitle>
                <SheetDescription className="p-0 m-0 mx-4 justify-items-center align-text-center">
                  {appUser?.email}
                </SheetDescription>
              </div>
            </div>
          </SheetHeader>
          <Divider margin={20} opacity={0.7} />
          <SheetFooter>
            <Button
              variant="outline"
              className="w-full rounded-md text-left justify-start"
              testid="logout-button"
              onClick={(event) => {
                logout();
              }}
            >
              <span
                className="material-icons mr-2 text-[1.2rem]"
                aria-hidden="true"
              >
                logout
              </span>{" "}
              Logout
            </Button>
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  );
});

export default ProfileSheet;

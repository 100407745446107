import React from "react";

import { Calendar, Clock } from "lucide-react";
import { observer } from "mobx-react-lite";

import { NodeCoverage } from "~/components/explorer/hooks/useNodeCoverage";
import CoverageDetails from "~/components/explorer/node/hover-details/CoverageDetails";
import { IProgramTreeNode, NodeTypes } from "~/models/AlignEditor";

const dateOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
} as const;

type NodeProgramDetailsProps = {
  program: IProgramTreeNode;
  coverage: NodeCoverage;
};

const NodeProgramDetails = observer(
  ({ program, coverage }: NodeProgramDetailsProps) => {
    // const { mutation } = useEditCurriculumItem();
    // const { getProgram, saveProgram } = useStore();

    // const handleEditProgram = (
    //   programUpdates: ProgramEditable,
    //   programOriginal: IProgramTreeNode,
    // ) => {
    //   const program = {
    //     ...programOriginal,
    //     ...programUpdates,
    //   };
    //   saveProgram(program);
    //   mutation.mutate(getProgram()?.getCurriculumNodesForDatabasePost());
    // };

    const dateCreated = new Date(program.created_at);
    const dateUpdated = new Date(program.updated_at);

    return (
      <div className="flex justify-between space-x-4 w-full">
        <div className="space-y-1 w-full">
          <div className="flex justify-between w-full">
            <h4 className="self-center font-bold">{program.display_name}</h4>

            {/* <ProgramDialog
              program={program}
              onSubmit={(data) => handleEditProgram(data, program)}
            >
              <Button size="xs">
                <div className="flex gap-1 items-center">
                  <EditIcon size="15" />
                  Edit
                </div>
              </Button>
            </ProgramDialog> */}
          </div>

          <div className="flex items-center pt-1">
            <Calendar className="mr-2 h-4 w-4 opacity-70" />{" "}
            <span className="text-sm text-muted-foreground">
              Created {dateCreated.toLocaleDateString("en-US", dateOptions)}{" "}
              {dateCreated.toLocaleTimeString("en-US")}
            </span>
          </div>

          <div className="flex items-center pt-1">
            <Clock className="mr-2 h-4 w-4 opacity-70" />{" "}
            <span className="text-sm text-muted-foreground">
              Updated {dateUpdated.toLocaleDateString("en-US", dateOptions)}{" "}
              {dateUpdated.toLocaleTimeString("en-US")}
            </span>
          </div>

          <CoverageDetails
            id={program.id}
            coverage={coverage}
            type={NodeTypes.Program}
          />
        </div>
      </div>
    );
  },
);

export { NodeProgramDetails };

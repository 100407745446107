import React from "react";

import TagList from "~/components/TagList";
import { Badge } from "~/components/ui/badge";
import { ScrollArea, ScrollBar } from "~/components/ui/scroll-area";

type ListFilterTagListProps = {
  filterFormValues?: any;
};

const ListFilterTagList = ({ filterFormValues }: ListFilterTagListProps) => {
  const category_global = filterFormValues?.category_global;
  const category_partner = filterFormValues?.category_partner;
  const categoryTags = [];
  if (!category_global || !category_partner) {
    categoryTags.push(category_global ? "Global" : "Local");
  }

  const is_active_yes = filterFormValues?.is_active_yes;
  const is_active_no = filterFormValues?.is_active_no;
  const activeTags = [];
  if (!is_active_yes || !is_active_no) {
    activeTags.push(is_active_yes ? "Yes" : "No");
  }

  const listTypeTags = filterFormValues?.list_type || [];
  const programTags = filterFormValues?.programs || [];

  console.log(filterFormValues);
  return (
    <>
      {(categoryTags.length > 0 ||
        activeTags.length > 0 ||
        listTypeTags.length > 0 ||
        programTags.length > 0) &&
        filterFormValues !== null && (
          <Badge
            variant="outline"
            className="h-11 bg-white/80 backdrop-blur-[4px] py-2 m-5 mb-0"
          >
            <ScrollArea className="whitespace-nowrap">
              <div className="flex gap-4 items-center my-5 mx-1 ">
                {categoryTags.length > 0 && (
                  <TagList tags={categoryTags} title="Category" />
                )}
                {activeTags.length > 0 && (
                  <TagList tags={activeTags} title="Active" />
                )}
                {}
                {listTypeTags.length > 0 && (
                  <TagList tags={listTypeTags} title="List Type" limit={1} />
                )}
                {programTags.length > 0 && (
                  <TagList
                    tags={programTags}
                    title="Program"
                    limit={1}
                    truncate
                  />
                )}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </Badge>
        )}
    </>
  );
};

export default ListFilterTagList;

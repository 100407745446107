import React from "react";

import { Checkbox } from "@progress/kendo-react-inputs";
import styled from "styled-components";

import AlignmentGridSection from "~/components/Reports/AlignmentGridSection";
import * as ReportModels from "~/components/Reports/ReportModels";
import { colors } from "~/css/shared-styles";

const CompStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  table.sticky-header thead {
    position: sticky;
    top: 0;
  }

  table th {
    vertical-align: bottom;
  }

  table th.course-heading {
    text-align: center;
    vertical-align: bottom;
    padding: 10px;
  }

  table tr.list-name td {
    background-color: ${colors.loginGrey};
    font-size: 1rem;
  }

  table td.indent-1x {
    padding-left: 15px;
    font-weight: bold;
  }

  table td.indent-2x {
    padding-left: 25px;
  }

  table td.data-point {
    text-align: center;
    vertical-align: middle;
    min-width: 50px;
    min-height: 50px;
    padding: 10px;
    font-size: 1.15rem;
  }

  table tr.hidden {
    display: none;
  }

  .close-button {
    cursor: pointer;
    font-weight: normal;
    font-size: 2rem;
    color: ${colors.inactiveGrey};
  }
`;

const AlignmentGrid: React.FC<{
  programData: ReportModels.ProgramData | null;
  targetList: ReportModels.List | null;
  forPrint: boolean | false;
}> = ({ programData, targetList, forPrint }) => {
  const [showNumbers, setShowNumbers] = React.useState(false);
  const checkboxChange = (event: any) => {
    setShowNumbers(event.value);
  };

  return (
    <CompStyles>
      <div className="h-[75vh] p-2">
        <table className="tbl sticky-header" role="treegrid">
          <thead>
            <tr role="row">
              <th role="gridcell">
                <Checkbox
                  label={"Show Numbers"}
                  value={showNumbers}
                  onChange={checkboxChange}
                />
              </th>
              {programData?.courses.map((course, idx) => (
                <th
                  key={idx}
                  className="course-heading"
                  style={{ width: "80px" }}
                  role="gridcell"
                >
                  {course.code}
                </th>
              ))}
            </tr>
          </thead>
          {forPrint && programData && targetList != null ? (
            <AlignmentGridSection
              list={targetList}
              programData={programData}
              forPrint={forPrint}
              showNumbers={showNumbers}
            />
          ) : (
            programData?.mapped_list_ids.map((list_id, idx) => {
              const findListResult = programData.mapped_lists.find(
                (x) => x.id === list_id,
              );
              if (findListResult === undefined) {
                return null;
              }
              return (
                <AlignmentGridSection
                  key={idx}
                  list={findListResult}
                  programData={programData}
                  forPrint={forPrint}
                  showNumbers={showNumbers}
                />
              );
            })
          )}
        </table>
      </div>
    </CompStyles>
  );
};

export default AlignmentGrid;

import React from "react";

import styled from "styled-components";

import * as ReportModels from "~/components/Reports/ReportModels";
import useOverrides from "~/hooks/useOverrides";

const CompStyles = styled.div`
  display: flex;
  flex-direction: column;
`;

const CourseModuleDetail: React.FC<{
  module: ReportModels.Module;
  list_id: number;
  fromAlignmentGrid: boolean;
  activityIDDisplay?: number[];
}> = ({ module, list_id, fromAlignmentGrid, activityIDDisplay }) => {
  const getActivities = (
    module: ReportModels.Module,
    activityIDDisplay?: number[],
  ) => {
    if (activityIDDisplay && activityIDDisplay.length > 0) {
      const activities = module.activities?.filter((x) =>
        activityIDDisplay.includes(x.id),
      );
      return activities;
    } else {
      return module.activities;
    }
  };
  const activities = getActivities(module, activityIDDisplay);

  const totalMinutes = activities.reduce(
    (acc, curr) => acc + curr.tot_minutes,
    0,
  );
  const totalMinutesDisplay = ReportModels.formatMinutes(totalMinutes);
  const moduleMappings =
    module.mappings?.filter((x) => x.list_id === list_id) || [];
  const activityMappings =
    module.activities?.flatMap((a) =>
      a.mappings.filter((x) => x.list_id === list_id),
    ) || [];
  const mappings = moduleMappings.concat(activityMappings.flat());
  const uniqueMappingNames = Array.from(
    new Set(mappings.map((x) => x.listitem_display_name)),
  );
  const { ActivitiesLabel } = useOverrides();
  const activitiesLabel = ActivitiesLabel();

  interface IKvp {
    key: string;
    value: number;
  }

  const mappingNamesCount: IKvp[] = [];

  uniqueMappingNames.forEach((name) => {
    const count = mappings.filter(
      (x) => x.listitem_display_name === name,
    ).length;
    mappingNamesCount.push({ key: name, value: count });
  });

  return (
    <CompStyles>
      {!fromAlignmentGrid && (
        <div style={{ marginBottom: "5px" }}>
          <p>Mappings: {mappings.length}</p>
          <ul>
            {mappingNamesCount.map((kvp) => (
              <li key={kvp.key}>
                <span>{kvp.key}</span>&nbsp;
                <span style={{ paddingLeft: "5px" }}>
                  {kvp.value > 1 ? `(${kvp.value} mappings)` : ""}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div>
        <table className="tbl mb-8">
          <thead>
            <tr>
              <th colSpan={3}>Learning {activitiesLabel}</th>
              <th className="center">
                Total:
                <br />
                {activities.reduce((acc, curr) => acc + curr.grading_points, 0)}
              </th>
              <th className="center">
                Total:
                <br />
                {totalMinutesDisplay}
              </th>
            </tr>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Description</th>
              <th className="center">Points</th>
              <th className="center">Time</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((x) => (
              <tr key={x.id}>
                <td style={{ width: "60px" }}>
                  <b>{`${x.activity_type
                    .charAt(0)
                    .toUpperCase()}${x.activity_type.slice(1)}`}</b>
                </td>
                <td>{x.display_name}</td>
                <td>{x.description}</td>
                <td className="center" style={{ width: "60px" }}>
                  {x.grading_points}
                </td>
                <td className="center" style={{ width: "60px" }}>
                  {ReportModels.formatMinutes(x.tot_minutes)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </CompStyles>
  );
};

export default CourseModuleDetail;

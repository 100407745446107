import React from "react";

import { Calculator } from "lucide-react";
import { observer } from "mobx-react-lite";

import { NodeCoverage } from "~/components/explorer/hooks/useNodeCoverage";
import CoverageDetails from "~/components/explorer/node/hover-details/CoverageDetails";
import { ICourse, NodeTypes } from "~/models/AlignEditor";

type NodeCourseDetailsProps = {
  course: ICourse;
  coverage: NodeCoverage;
};

const NodeCourseDetails = observer(
  ({ course, coverage }: NodeCourseDetailsProps) => {
    // const { mutation } = useEditCurriculumItem();
    // const { getProgram, saveCourseChanges } = useStore();

    // const handleEditCourse = (
    //   courseUpdates: CourseEditable,
    //   courseOriginal: ICourse,
    // ) => {
    //   const course = {
    //     ...courseOriginal,
    //     ...courseUpdates,
    //   };
    //   saveCourseChanges(course);
    //   mutation.mutate(getProgram()?.getCurriculumNodesForDatabasePost());
    // };

    const mappings = [...(course.mappings ?? [])];

    return (
      <div className="flex justify-between space-x-4 w-full">
        <div className="space-y-1 w-full">
          <div className="flex justify-between w-full">
            <h4 className="self-center font-bold">{course.display_name}</h4>

            {/* <CourseDialog
              course={course}
              onSubmit={(data) => handleEditCourse(data, course)}
            >
              <Button size="xs">
                <div className="flex gap-1 items-center">
                  <EditIcon size="15" />
                  Edit
                </div>
              </Button>
            </CourseDialog> */}
          </div>

          <p className="text-sm">{course.description}</p>

          <div className="flex items-center pt-1">
            <Calculator className="mr-2 h-4 w-4 opacity-70" />{" "}
            <span className="text-sm text-muted-foreground">
              {course.credits} Credits
            </span>
          </div>

          <CoverageDetails
            id={course.id}
            coverage={coverage}
            mappings={mappings}
            type={NodeTypes.Course}
          />
        </div>
      </div>
    );
  },
);

export { NodeCourseDetails };
